import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import './Admin.scss';
import { Link } from "react-router-dom";
import AdminChart from '../components/AdminChart/AdminChart';
import AdminSidebar from '../components/AdminSidebar';
import { LinkContainer } from 'react-router-bootstrap';
import User from '../img/user.jpg';
import { Redirect } from "react-router-dom";
import Sticky from 'react-stickynode';
import EditUserModal from '../components/modals/EditUserModal';
import DeleteUserModal from '../components/modals/DeleteUserModal';
import AddUserModal from '../components/modals/AddUserModal';

import FullPageLoader from '../components/FullPageLoader';
import Actions from '../actions/api';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../helpers/link-state');
const parseQueryStringToDictionary = require('../helpers/parse-query');

export default class AdminUsers extends Component {
  state = {
    selectedBar: window.bvUser.admin_bar_id ? window.bvUser.admin_bar_id : window.bvUser && window.bvUser.bar_memberships && window.bvUser.bar_memberships.length ? window.bvUser.bar_memberships[0].bar.id : '',
    showSlugBar: false,
    userNeedsToPay: !window.bvUser || !window.bvUser.super_admin,

    barUsers: null,
    redirect: '',
    error: '',
    bar: null,
    processing: true,
  }

  componentDidMount() {
    const query = parseQueryStringToDictionary(document.location.search);

    if (query.slug) {
      this.getBar(query.slug);
    } else if (this.state.selectedBar) {
      this.getBar();
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedBar !== prevState.selectedBar) {
      window.bvUser.admin_bar_id = this.state.selectedBar;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', this.state.selectedBar);
      }
      this.getBar();
    }
  }

  async getBar(slug) {
    this.setState({
      processing: true,
    });

    let barId = this.state.selectedBar;
    let bar;
    let showSlugBar = false;

    if (window.bvUser.super_admin && window.bvUser.admin_bar_id) {
      showSlugBar = true;
      barId = window.bvUser.admin_bar_id;
    }
    
    if (slug) {
      bar = await Actions.get(`bars/slug/${slug}/`);
      barId = bar.id;
      showSlugBar = true;

      window.bvUser.admin_bar_id = barId;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', barId);
      }
    }

    if (barId) {
      try {
        const bar = await Actions.get(`bars/${barId}/`);
        const barUsers = await Actions.get(`bars/admin/${barId}/users/`, {
          limit: 5000,
        });

        let userNeedsToPay = false;

        if (!window.bvUser.super_admin) {
          const billingInfo = await Actions.get(`bars/admin/bars/${barId}/billing/`);
          userNeedsToPay = !billingInfo || !billingInfo.subscription;
        }

        this.setState({
          bar,
          showSlugBar,
          selectedBar: barId,
          userNeedsToPay,
          barUsers: barUsers && barUsers.results ? barUsers.results : [],
          processing: false,
        });
      } catch (ex) {
        this.setState({
          redirect: '/',
        });
      }
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }

  async doAddUser(full_name, email, owner, handleClose) {
    if (this.state.userNeedsToPay) {
      return;
    }

    const barId = this.state.selectedBar;
    try {
      const newUser = await Actions.post(`bars/admin/${barId}/users/`, {
        full_name,
        email,
        owner,
      });

      if (newUser) {
        const barUsers = this.state.barUsers;
        barUsers.push(newUser);

        this.setState({
          barUsers,
        });
      }
      handleClose();
    } catch (ex) {
      handleClose('Failed to add user. Please try again.');
    }
  }

  async doSaveUser(id, full_name, owner, handleClose) {
    if (this.state.userNeedsToPay) {
      return;
    }

    const barId = this.state.selectedBar;
    try {
      const savedUser = await Actions.patch(`bars/admin/${barId}/users/${id}/`, {
        full_name,
        owner,
      });

      if (savedUser) {
        const barUsers = this.state.barUsers;
        const users = barUsers.filter(u => u.id === id);

        if (users.length) {
          users[0].full_name = savedUser.full_name;
          users[0].owner = savedUser.owner;
        }

        this.setState({
          barUsers,
        });
      }
      handleClose();
    } catch (ex) {
      handleClose('Failed to save user. Please try again.');
    }
  }

  async doDeleteUser(id, handleClose) {
    if (this.state.userNeedsToPay) {
      return;
    }

    const barId = this.state.selectedBar;

    try {
      await Actions.delete(`bars/admin/${barId}/users/${id}/`);
      const barUsers = this.state.barUsers.filter(u => u.id !== id);

      this.setState({
        barUsers,
      });
      handleClose();
    } catch (ex) {
      handleClose('Failed to remove user. Please try again.');
    }
  }

  render () {
    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader/>;
    }

    const authorizedUser = window.bvUser;

    const numberOfBars = authorizedUser.bar_memberships.length;

    const { 
      bar,
      barUsers,
      error,
      userNeedsToPay,
      showSlugBar,
    } = this.state;

    const barOptions = showSlugBar ? (<option key={bar.id} value={bar.id}>{bar.name}</option>) : (numberOfBars > 1 ? authorizedUser.bar_memberships.map(membership => {
      return (
        <option key={membership.bar.id} value={membership.bar.id}>{membership.bar.name}</option>
      );
    }) : null);

    const billingLink = `/admin/billing?slug=${bar.slug}`;
    const payAlert = userNeedsToPay ? (
      <Alert variant="info billing-alert">
        To start your free trial, <a href={billingLink}>click here to enter your billing information</a>. You will be unable to edit venue information until you do.
      </Alert>
    ) : null;

    const barSelection = numberOfBars > 1 || showSlugBar ? (
      <div className="form-group venue-select">
        <div className="bv-select">
          <select
            id="selectedBar"
            name="selectedBar"
            value={this.state.selectedBar}
            onChange={LinkState.bind(this)}
            className="custom-select">
            {barOptions}
          </select>
          <i className="fa fa-caret-down"></i>
        </div>
      </div>
    ) : null;

    let alert = null;

    if (error) {
      alert = (
        <Alert variant="danger">
          {error}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    const ratingScore = bar.rating || 0;
    const rating = (
      <span className="rating">
        <i className={ratingScore > 0 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 1 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 2 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 3 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 4 ? 'fa fa-star' : 'fa fa-star-o'}></i>
      </span>
    );

    let usersTable = null;

    if (barUsers && barUsers.length) {

      const userRows = barUsers.map(u => {
        return (
          <tr key={u.id}>
            <td>
              {u.full_name || 'Anonymous'}
            </td>
            <td className="description">
              <a href={`mailto:${u.email}`}>{u.email}</a>
            </td>
            <td>
              { u.owner ? 'Owner' : 'User'}
            </td>
            <td>
              Active
            </td>
            <td className="text-right">
              <ul className="action-buttons">
                <li>
                  { userNeedsToPay ? null : <EditUserModal user={u} doSaveUser={this.doSaveUser.bind(this)} /> }
                </li>
                <li>
                  { userNeedsToPay ? null : <DeleteUserModal user={u} doDeleteUser={this.doDeleteUser.bind(this)} /> }
                </li>
              </ul>
            </td>
          </tr>
        );
      });

      usersTable = (
        <table className="table bv-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email Address</th>
              <th>User Type</th>
              <th>Status</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {userRows}
          </tbody>
        </table>
      );
    } else {
      usersTable = (
        <div className="col-12 col-md-6">
          <p>No users have liked this bar yet.</p>
        </div>
      );
    }

    return (
      <div className="admin container-fluid">
        {payAlert}
        {alert}
        <div className="admin-sidebar">
          <div className="d-none d-lg-block">
            <Sticky enabled={true} top={100}>
              <AdminSidebar />
            </Sticky>
          </div>
          <div className="d-lg-none">
            <AdminSidebar />
          </div>
        </div>
        <div className="admin-content">
          <div className="row admin-header">
            <div className="col-12 col-md-5">
              <h2>Users</h2>
              <p>
                <span className="venue">{bar.name}</span>
                {rating}
              </p>
            </div>
            <div className="col-12 col-md-7 text-right">
              {barSelection}
              
              <div className="admin-add-button">
                { userNeedsToPay ? null : <AddUserModal doAddUser={this.doAddUser.bind(this)}/> }
              </div>
            </div>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-12">
              {usersTable}
            </div>
          </div>
        </div>
      </div>
    )
  }
}