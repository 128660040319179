import React, { Component } from 'react';
import './Notifications.scss';

import Actions from '../actions/api';
import FullPageLoader from '../components/FullPageLoader';

export default class Notifications extends Component {
  state = {
    notifications: null,
    processing: true,
  }

  componentDidMount() {
    this.getNotifications();
  }

  markNotificationsRead() {
    if (window.bvUser && window.bvUser.unread_count) {
      window.bvUser.unread_count = 0;

      this.setState({
        unreadCount: 0,
      });

      Actions.post('users/me/notifications/read/');
      Actions.post('users/me/notifications/seen/');
    }
  }

  getNotifications() {
    if (!window.bvUser) {
      return;
    }
    Actions.get('users/me/notifications/').then(notifications => {
      this.markNotificationsRead();
      this.setState({
        notifications: notifications.results || [],
        processing: false,
      });
    }).catch(ex => {
      this.setState({
        notifications: [],
        processing: false,
      });
    });
  }

  render () {
    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader/>;
    }

    const notifications = this.state.notifications && this.state.notifications.length ? (this.state.notifications.map(notification => {

      const timestamp = new Date(notification.created);

      let notificationLink = `/venue/${notification.bar.slug}`;

      if (notification.event) {
        notificationLink = `/event/${notification.event.slug}`;
      }

      return (
        <li key={notification.id}>
          <a
          onClick={ () => document.location.href = notificationLink }
          href={notificationLink}>
            <b>{notification.title}</b>
            <p>{notification.notification_text}</p>
            <small>{timestamp.toLocaleDateString()}</small>
          </a>
        </li>
      );

    })) : (
      <li>
        <p>You haven't yet received any notifications.</p>
      </li>
    );

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 notifications">
            <h5>Notifications</h5>
            <ul className="notification-list">
              {notifications}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}