import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ButtonLoader from '../ButtonLoader';
import Alert from 'react-bootstrap/Alert';
import Actions from '../../actions/api';
const LinkState = require('../../helpers/link-state');

let errorTimeout;
const pageSize = 5;
class AcceptVenueRequestModal extends Component {
  state = {
    show: false,

    bars: null,
    pageIndex: 0,
    numberOfPages: 0,
    search: '',
    processing: false,
    loadingBars: true,
  }

  handleClose() {
    this.setState({
      show: false,
      error: '',
      processing: false,
    });
  }

  handleShow() {
    this.setState({
      show: true,
      error: '',
      processing: false,
    });

    this.getBars(0, this.props.user.business_name);
  }

  onSearchKeyDown(evt) {
    // trigger search on enter
    if (evt.key === 'Enter') {
      this.getBars(0);
    }
  }

  async getBars(pageIndex, search) {
    
    const st = {
      loadingBars: true,
      bars: null,
      search: this.state.search,
    };

    if (search) {
      st.search = search;
    }

    this.setState(st);

    const params = { 
      offset: pageIndex * pageSize,
      limit: pageSize,
    };

    if (st.search) {
      params.search = st.search;
    }
    
    Actions.get('bars', params).then(results => {
      const bars = results.results || [];
      const numberOfPages = Math.ceil(results.count / pageSize);
      
      this.setState({
        bars,
        loadingBars: false,
        numberOfPages,
        pageIndex,
      });
    }).catch(ex => {
      this.setState({
        bars: [],
        loadingBars: false,
      });
    });
  }

  async loadPage(pageIndex, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    await this.getBars(pageIndex);
  }

  doAcceptRequest() {
    this.setState({
      processing: true,
    });
    
    this.props.doAcceptRequest(this.props.user, null, error => {
      if (error) {
        this.setState({
          processing: false,
          error,
        });
      } else {
        this.handleClose();
      }
    });
  }

  selectBarToLink(bar) {
     this.setState({
      processing: true,
    });
    
    this.props.doAcceptRequest(this.props.user, bar.id, error => {
      if (error) {
        this.setState({
          processing: false,
          error,
        });
      } else {
        this.handleClose();
      }
    });
  }

  render() {
    
    let alert = null;

    const {
      processing,
      loadingBars,
      bars,
      error,
    } = this.state;

    if (error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }


    const buttonText = processing ? (
      <ButtonLoader />
    ) : (
      <span>Add new bar!</span>
    );

    let barsTable = null;

    if (loadingBars) {
      barsTable = (
      <div className="col-12">
        <ButtonLoader/>
      </div>);
    } else if (bars && bars.length) {

      const rows = bars.map(u => {
        return (
          <tr key={u.id}>
            <td>
              <a target="_blank" href={'/venue/' + u.slug}>{u.name}</a>
            </td>
            <td>
              {u.address ? u.address.street_address : ''}<br/>
              {u.address ? (`${u.address.locality}, ${u.address.region}`) : ''}
            </td>
            <td className="text-right">
              <button 
                type="button"
                className="btn btn-secondary"
                onClick={ () => this.selectBarToLink(u) }>
                Add User To Venue
              </button>
            </td>
          </tr>
        );
      });

      barsTable = (
        <div className="col-12">
          <table className="table bv-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Location</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      );
    } else {
      barsTable = (
        <div className="col-12">
          <div className="no-results">
            <p>No relevant venues found</p>
          </div>
        </div>
      );
    }

    // pagination
    let pagination = null;
    const numberOfPages = this.state.numberOfPages || 1;

    if (numberOfPages > 1) {
        
      const previous = this.state.pageIndex > 0 ? (
        <li className="page-item">
          <a className="page-link" href="#" onClick={(event) => this.loadPage(this.state.pageIndex - 1, event)}>
            <i className="fa fa-chevron-left"></i>
          </a>
        </li>
      ) : null;

      let pages = [];
      var hasSkipped = false;
      const curPageDelta = 2;
      for (let i = 0; i < numberOfPages; i++) {
        const isCurrentPage = i === this.state.pageIndex;
        const isCloseToCurrentPage = i > this.state.pageIndex - curPageDelta && i < this.state.pageIndex + curPageDelta;
        const classes = isCurrentPage ? 'page-link active' : 'page-link';


        // if we have many pages, skip the middle few ...
        if (!isCurrentPage && !isCloseToCurrentPage && numberOfPages > 5 && i > curPageDelta && i < numberOfPages - curPageDelta) {
          if (!hasSkipped) {
            pages.push(
              <li className="page-item gap" key={i}>
                ...
              </li>
            );
          }
          hasSkipped = true;
          continue;
        } else {
          if (this.state.pageIndex === i) {
            // allow another gap to show up
            hasSkipped = false;
          }
          pages.push(
            <li className="page-item" key={i}>
              <a className={classes} href="#" onClick={(event) => this.loadPage(i, event)}>
                {i+1}
              </a>
            </li>
          );
        }
      }

      const next = (this.state.pageIndex + 1) < numberOfPages ? (
        <li className="page-item">
          <a className="page-link" href="#" onClick={(event) => this.loadPage(this.state.pageIndex + 1, event)}>
            <i className="fa fa-chevron-right"></i>
          </a>
        </li>
      ) : null;

      pagination = (
        <div className="row">
          <div className="col-12">
            <nav>
              <ul className="pagination">
                {previous}
                {pages}
                {next}
              </ul>
            </nav>
          </div>
        </div>
      );
    }

    return (
      <>
        <Button className="txt-btn" onClick={this.handleShow.bind(this)}>
          Accept
        </Button>

        <Modal
          show={this.state.show}
          onHide={this.handleClose.bind(this)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Accept Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alert}

            <div className="row">
              <div className="col-12">
                <p>Try to find an existing venue to link this user to.</p>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <input
                  type="search"
                  id="search"
                  name="search"
                  value={this.state.search}
                  onChange={LinkState.bind(this)}
                  onKeyDown={this.onSearchKeyDown.bind(this)}
                  placeholder="Search by Name, Description"
                  className="form-control"
                />
              </div>

              {barsTable}
            </div>

            {pagination}

            <div className="row">
              <div className="col-12">
                <p>If you can't find the venue in our collection, click the button below to add <span className="bold">{this.props.user.business_name}</span> as a new venue.</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="ghost" onClick={this.handleClose.bind(this)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.doAcceptRequest.bind(this)}>
              {buttonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default (AcceptVenueRequestModal);
