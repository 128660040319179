import React, { Component } from 'react'
import Chart from "chart.js";
import classes from "./AdminChart.module.css";

export default class LineGraph extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    const myChartRef = this.chartRef.current.getContext("2d");

    const dataPoints = this.props.dataPoints || [];

    let dates = null;
    let pageViews = null;
    let likes = null;
    let dislikes = null;

    if (dataPoints.length) {
      dates = dataPoints.map(p => p.date);
      pageViews = dataPoints.map(p => p.pageviews || 0);
      likes = dataPoints.map(p => p.likes || 0);
      dislikes = dataPoints.map(p => p.dislikes || 0);
    }

    new Chart(myChartRef, {
      type: "line",
      data: {
        labels: dates,
        datasets: [
          {
            label: "Pageviews",
            borderColor: "#8439dd",
            backgroundColor: 'rgba(132,57,221,0.1)',
            data: pageViews,
          },
          {
            label: "Likes",
            borderColor: "#00bf91",
            backgroundColor: 'rgba(0,191,145,0.1)',
            data: likes,
          },
          {
            label: "Dislikes",
            borderColor: "#f5565b",
            backgroundColor: 'rgba(245,86,91,0.1)',
            data: dislikes,
          },
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            labels: {
                fontColor: "rgba(255,255,255,0.7)",
            }
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: "rgba(255,255,255,0.6)",
              padding: 5,
            },
            gridLines: {
              color: "rgba(255,255,255,0.1)",
              zeroLineColor: 'rgba(255,255,255,0.4)'
            },
          }],
          xAxes: [{
            ticks: {
              fontColor: "rgba(255,255,255,0.6)",
              padding: 10,
            },
            gridLines: {
              color: "rgba(255,255,255,0.01)",
              zeroLineColor: 'rgba(255,255,255,0.4)'
            },
          }]
        },
        tooltips: {
          mode: 'x',
          intersect: false,
          backgroundColor: '#141721',
          displayColors: false
        },
      }
    });
  }

  render() {
    return (
      <div className={classes.graphContainer}>
        <canvas
          id="adminChart"
          ref={this.chartRef}
        />
      </div>
    )
  }
}