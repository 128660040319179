import React, { Component } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import './Venues.scss';
import LocationPage from './LocationPage';
import Card from '../components/Card';
import Actions from '../actions/api';
import { Link } from "react-router-dom";
import FullPageLoader from '../components/FullPageLoader';
import LocationFilterModal from '../components/modals/LocationFilterModal';
import Dropdown from 'react-bootstrap/Dropdown';
const LinkState = require('../helpers/link-state');

let errorTimeout;
const pageSize = 21;
export default class Events extends Component {
  state = {
    events: null,
    processing: true,

    pageIndex: 0,
    numberOfPages: 0,

    startDate: null,
    endDate: null,

    latitude: 0,
    longitude: 0,
    sort: 'date',
    search: '',
    filter_state: '', 
    filter_city: '',
    locationLabel: '',
    AllowLocation: false,
  };

  async getLocations(latitude,longitude){
    let locationData = {};
    let city = "";
    let state = "";
    locationData = await Actions.get('bars/location/', {
      latitude,
      longitude
    });
    state = locationData && locationData.state ? ` ${locationData.state}` : '';
    city = locationData && locationData.city ? ` ${locationData.city}` : '';
    try{
      this.getEvents(0, null, state, city, null, window.bvCoords);
    }catch(error){
      this.getEvents(0, null, null, null, null, window.bvCoords);
    }
  }

  componentDidMount() {
    if (!window.bvCoords) {
      navigator.geolocation.getCurrentPosition(position => {
        if (position && position.coords) {
          this.setState({AllowLocation:true})
          window.bvCoords = position.coords;
        }
          this.getLocations(window.bvCoords.latitude,window.bvCoords.longitude)
      }, error => {
        if(error){
          this.setState({AllowLocation:false})
          this.getLocations(null,null)
        }
      });
    }else{
      this.setState({AllowLocation:true})
        this.getLocations(window.bvCoords.latitude,window.bvCoords.longitude)
    }
  }

  filterChanged(state, city) {
    this.getEvents(0, null, state, city, null);
  }

  onSearchKeyDown(evt) {
    // trigger search on enter
    if (evt.key === 'Enter') {
      this.getEvents(0);
    }
  }

  sortBy(sort) {
    this.getEvents(0, null, null, null, sort);
  }

  async loadPage(pageIndex, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    await this.getEvents(pageIndex);
  }

  async dateChanged({ startDate, endDate }) {
    if (startDate && endDate) {
      this.getEvents(0, null, null, null, null, null, startDate, endDate);
    } else {
      this.setState({ startDate, endDate });
    }
  }

  async getEvents(pageIndex, search, filter_state, filter_city, sort, coords, startDate, endDate) {
    const st = {
      processing: true,
      events: null,
      sort: this.state.sort,
      search: this.state.search,
      filter_state: this.state.filter_state,
      filter_city: this.state.filter_city,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };

    if (search) {
      st.search = search;
    }

    if (filter_state) {
      st.filter_state = filter_state;
    } else {
      st.filter_state = '';
    }

    if (filter_city) {
      st.filter_city = filter_city;
    } else {
      st.filter_city = '';
    }

    if (coords) {
      st.latitude = coords.latitude;
      st.longitude = coords.longitude;
    }

    if (sort) {
      st.sort = sort;
    }

    if (startDate) {
      st.startDate = startDate;
    }

    if (endDate) {
      st.endDate = endDate;
    }

    this.setState(st);

    const params = {
      offset: pageIndex * pageSize,
      limit: pageSize,
    };

    const start_date = (startDate || new Date()).toISOString();
    const end_date = endDate ? endDate.toISOString() : '';

    if (start_date) {
      params.start_time_after = start_date;
    }

    if (end_date) {
      params.start_time_before = end_date;
    }

    if (st.filter_state || st.filter_city) {
      params.filter_state = st.filter_state;
      params.filter_city = st.filter_city;
    } else if ((st.latitude || st.longitude)) {
      params.latitude = st.latitude;
      params.longitude = st.longitude;
    }
    
    if (st.search) {
      params.search = st.search;
    }

    if (st.sort) {
      params.sort = st.sort;
    }

    const locationParams = {};
    let locationData = {};

    if (st.filter_state || st.filter_city) {
      locationParams.state = params.filter_state;
      locationParams.city = params.filter_city;
      locationData.state = params.filter_state;
      locationData.city = params.filter_city;
    } else if(params.latitude) {
      locationParams.latitude = params.latitude;
      locationParams.longitude = params.longitude;
      locationData = await Actions.get('bars/location/', locationParams);
    } else {
      locationData = await Actions.get('bars/location/', null);
    }     

    const state = locationData && locationData.state ? ` ${locationData.state}` : '';
    const city = locationData && locationData.city ? ` ${locationData.city}` : '';
    const locationLabel = ` near ` + (locationData && locationData.city ? ` ${locationData.city}, ${state}` : `${state} State`);

    Actions.get('bars/events/', params).then(results => {
      const events = results.results || [];
      const numberOfPages = Math.ceil(results.count / pageSize);
      this.setState({
        events,
        processing: false,
        locationLabel,
        numberOfPages,
        pageIndex,
      });
    }).catch(ex => {
      this.setState({
        events: [],
        processing: false,
      });
    });
  }

  render () {

    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader/>;
    }

    const events = this.state.events && this.state.events.length ? (this.state.events.map(evt => {
      
      const featured = evt.featured ? (
        <div className="tags">
          <span className="featured" key="0">
            Featured <i className="fa fa-star"></i>
          </span>
        </div>

      ) : null;

      const cover_photo_url = evt.photos.length ? evt.photos[0].url : '';

      let banner = (<img className="img-fluid" src={cover_photo_url} alt={evt.name}/>);

      let startTime = new Date(evt.start_time);

      let eventTimeStr = `${startTime.toLocaleDateString()} -  ${startTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;

      return (
        <div className="col-12 col-md-6 col-lg-4" key={evt.id}>
          <Link to={'/event/' + evt.slug}>
            <Card>
              <div className="upper">
                {banner}
                {featured}
              </div>
              <div className="lower">
                <b>{evt.name}</b>
                <div className="left">
                  {evt.bar.name}
                </div>
                <div className="event-date">
                  {eventTimeStr}
                </div>
              </div>
            </Card>
          </Link>
        </div>
      );
    })) : (<div className="col-12"><div className="no-results"><p>No events found</p></div></div>);

    let selectedSort = 'Date';

    if (this.state.sort === 'alphabetical') {
      selectedSort = 'Alphabetical';      
    } else if (this.state.sort === 'rating') {
      selectedSort = 'Rating';
    } else if (this.state.sort === 'distance') {
      selectedSort = 'Distance';
    } else if (this.state.sort === 'featured') {
      selectedSort = 'Featured';
    }else if (this.state.sort === 'date') {
      selectedSort = 'Date';
    }

    // pagination
    let pagination = null;
    const numberOfPages = this.state.numberOfPages || 1;

    if (numberOfPages > 1) {
        
      const previous = this.state.pageIndex > 0 ? (
        <li className="page-item">
          <a className="page-link" href="#" onClick={(event) => this.loadPage(this.state.pageIndex - 1, event)}>
            <i className="fa fa-chevron-left"></i>
          </a>
        </li>
      ) : null;

      let pages = [];
      var hasSkipped = false;
      const curPageDelta = 2;
      for (let i = 0; i < numberOfPages; i++) {
        const isCurrentPage = i === this.state.pageIndex;
        const isCloseToCurrentPage = i > this.state.pageIndex - curPageDelta && i < this.state.pageIndex + curPageDelta;
        const classes = isCurrentPage ? 'page-link active' : 'page-link';


        // if we have many pages, skip the middle few ...
        if (!isCurrentPage && !isCloseToCurrentPage && numberOfPages > 5 && i > curPageDelta && i < numberOfPages - curPageDelta) {
          if (!hasSkipped) {
            pages.push(
              <li className="page-item gap" key={i}>
                ...
              </li>
            );
          }
          hasSkipped = true;
          continue;
        } else {
          if (this.state.pageIndex === i) {
            // allow another gap to show up
            hasSkipped = false;
          }
          pages.push(
            <li className="page-item" key={i}>
              <a className={classes} href="#" onClick={(event) => this.loadPage(i, event)}>
                {i+1}
              </a>
            </li>
          );
        }
      }

      const next = (this.state.pageIndex + 1) < numberOfPages ? (
        <li className="page-item">
          <a className="page-link" href="#" onClick={(event) => this.loadPage(this.state.pageIndex + 1, event)}>
            <i className="fa fa-chevron-right"></i>
          </a>
        </li>
      ) : null;

      pagination = (
        <div className="row">
          <div className="col-12">
            <nav>
              <ul className="pagination">
                {previous}
                {pages}
                {next}
              </ul>
            </nav>
          </div>
        </div>
      );
    }

    return (
      <div className="container list">
        <div className="row list-header">
          <div className="col-12 col-md-5">
            <h3>All events{this.state.locationLabel}</h3>
            <LocationFilterModal filterChanged={this.filterChanged.bind(this)} />
          </div>
          <div className="col-12 col-md-7 search-events with-dates">
            <input
              type="search"
              id="search"
              name="search"
              value={this.state.search}
              onChange={LinkState.bind(this)}
              onKeyDown={this.onSearchKeyDown.bind(this)}
              placeholder="Search by Name, Description"
              className="form-control"
            />
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                Sort<span className="">: {selectedSort}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => this.sortBy("date")}>
                  Date
                </Dropdown.Item>
              <Dropdown.Item onClick={(e) => this.sortBy("featured")}>
                  Featured
                </Dropdown.Item>
                {/* <Dropdown.Item onClick={(e) => this.sortBy('alphabetical')}>Alphabetical</Dropdown.Item> */}
                {localStorage.getItem("userAccess") && (
                  <Dropdown.Item onClick={(e) => this.sortBy("alphabetical")}>
                    Alphabetical
                  </Dropdown.Item>
                )}
                {this.state.AllowLocation == true && (
                <Dropdown.Item onClick={(e) => this.sortBy("distance")}>
                  Distance
                </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <div className="form-group date-select">
              <label className="form-label sr-only">Venue</label>
              <DateRangePicker
                startDate={this.state.startDate}
                startDateId="your_unique_start_date_id"
                endDate={this.state.endDate}
                endDateId="your_unique_end_date_id"
                onDatesChange={this.dateChanged.bind(this)}
                focusedInput={this.state.focusedInput}
                onFocusChange={focusedInput => this.setState({ focusedInput })}
                showDefaultInputIcon={true}
              />
            </div>
          </div>
        </div>
        <LocationPage
        bars={this.state.events}
        pageIndex={this.state.pageIndex}
        pageSize={pageSize}
        onDataChange={this.handleDataChange}
        title = {"events"}
        locations = {events}
        pagination={pagination}
        />
      </div>
    )
  }
}