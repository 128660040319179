import React, { Component } from 'react';
import './Account.scss';
import Card from '../components/Card';
import { Redirect } from "react-router-dom";
import Actions from '../actions/api';
import Alert from 'react-bootstrap/Alert';
import ButtonLoader from '../components/ButtonLoader';
import AccountOnboardingFields from '../components/AccountOnboardingFields';
const LinkState = require('../helpers/link-state');

let errorTimeout;
export default class AccountEdit extends Component {
  state = {
    processingProfile: false,
    processingChangePW: false,
    processingFavs: false,
    redirect: '',
    error: '',
    alert: '',

    questionnaire: (window.bvUser && window.bvUser.questionnaire) || {},

    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',

    full_name: window.bvUser ? (window.bvUser.full_name || '') : '',
    avatar: '',
    avatarImage: null,
    phone: window.bvUser ? (window.bvUser.phone || '') : '',
    location: window.bvUser ? (window.bvUser.location_str || '') : '',
  }

  doSaveProfile(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      processingProfile: true,
    });

    const { full_name, phone, location } = this.state;

    const _doneSavingFile = profile_image_url => {
      const data = {
        full_name,
        phone: phone.replace(/\D/g,''),
        location_str: location,
      };
      
      if (profile_image_url && profile_image_url.indexOf('?') !== -1) {
        profile_image_url = profile_image_url.split('?')[0];
      }

      if (profile_image_url) {
        data.profile_image_url = profile_image_url;
      }

      Actions.patch('users/me/update/', data).then(response => {
        window.bvUser.full_name = full_name;
        window.bvUser.phone = phone;
        window.bvUser.location_str = location;
        window.bvUser.profile_image_url = profile_image_url;

        this.setState({
          alert: 'Profile Saved!',
          error: '',
          processingProfile: false,
        });
      }).catch(error => {
        this.setState({
          error,
          processingProfile: false,
        });
      });
    };

    if (this.state.avatarImage) {
      const awsUrl = window.bvUser.signed_profile_image_upload_url;
      Actions.saveFile(awsUrl, this.state.avatarImage).then(profile_image_url => {
        _doneSavingFile(awsUrl);
      }).catch(error => {
        this.setState({
          error: 'Failed to save photo. Please try again!',
          processingProfile: false,
        });
      });
    } else {
      _doneSavingFile(null);
    }
  }

  doSavePW(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.state.newPassword) {
      return this.setState({
        error: 'Please enter your password',
      });
    }

    if (this.state.newPassword !== this.state.confirmNewPassword) {
      return this.setState({
        error: 'Please make sure your passwords match',
      });
    }

    const validPassword = this.state.newPassword.length > 6 && this.state.newPassword.match(/[a-z]/) && this.state.newPassword.match(/[A-Z]/) && this.state.newPassword.match(/d/);


    if (!validPassword) {
      return this.setState({
        error: 'Password must be at least 6 characters with at least one uppercase and one number',
      });
    }

    this.setState({
      processingChangePW: true,
    });

    Actions.put('users/me/password/', {
      old_password: this.state.currentPassword,
      password: this.state.newPassword,
    }).then(response => {
      this.setState({
        alert: 'Password Changed!',
        error: '',
        processingChangePW: false,
      });
    }).catch(error => {
      this.setState({
        error,
        processingChangePW: false,
      });
    });

  }

  async doSaveOnboarding(data, onComplete) {
    
    try {
      const questionnaire = await Actions.patch('users/me/questionnaire/', data);
      window.bvUser.questionnaire = questionnaire;
      this.setState({
        error: '',
        alert: 'Profile Saved!',
        questionnaire,
      });

      onComplete();
    } catch (ex) {
      this.setState({
        error: 'Failed to save profile. Please try again.',
      });
      onComplete();
    }

  }

  onSetFile(evt) {
    if (evt.target.files.length) {
      this.setState({
        avatarImage: evt.target.files[0],
        avatar: evt.currentTarget.value,
      });
    } else {
      this.setState({
        avatarImage: null,
        avatar: '',
      });
    }
  }

  render () {
    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    const isSocialUser = window.bvUser.social_login || false;

    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    } else if (this.state.alert) {
      alert = (
        <Alert variant="success">
          {this.state.alert}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          alert: '',
        });
      }, 3e3);
    }

    let profileButtonText = (<span>Save Account Info</span>)

    if (this.state.processingProfile) {
      profileButtonText = (<ButtonLoader />);
    }

    let pwButtonText = (<span>Save Password</span>)

    if (this.state.processingChangePW) {
      pwButtonText = (<ButtonLoader />);
    }

    let favsButtonText = (<span>Save Drink/Bar Info</span>)

    if (this.state.processingFavs) {
      favsButtonText = (<ButtonLoader />);
    }

    const changePWForm = isSocialUser ? null : (
      <Card>
        <h2>Change Password:</h2>
        <form onSubmit={this.doSavePW.bind(this)} method="post">
          <div className="form-group">
            <label>Current Password:</label>
            <input
              id="currentPassword"
              name="currentPassword"
              type="password"
              value={this.state.currentPassword}
              onChange={LinkState.bind(this)}
              className="form-control" />
          </div>
          <div className="form-group">
            <label>New Password:</label>
            <input
              id="newPassword"
              name="newPassword"
              type="password"
              value={this.state.newPassword}
              onChange={LinkState.bind(this)}
              className="form-control" />
          </div>
          <div className="form-group">
            <label>Confirm New Password:</label>
            <input
              id="confirmNewPassword"
              name="confirmNewPassword"
              type="password"
              value={this.state.confirmNewPassword}
              onChange={LinkState.bind(this)}
              className="form-control" />
          </div>
          <br />
          <button 
            type="submit"
            disabled={this.state.processingChangePW}
            className="btn btn-primary">
            {pwButtonText}
          </button>
        </form>
      </Card>
    );

    return (
      <div className="account container">
        {alert}
        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1">
            <Card>
              <h2>Account Info:</h2>
              <form onSubmit={this.doSaveProfile.bind(this)} method="post">
                <div className="form-group">
                  <label>Full Name:</label>
                  <input
                    type="text"
                    id="full_name"
                    name="full_name"
                    value={this.state.full_name}
                    onChange={LinkState.bind(this)}
                    className="form-control" />
                </div>
                <div className="form-group">
                  <label>Profile Image:</label>
                  <input
                    type="file"
                    id="avatar"
                    name="avatar"
                    value={this.state.avatar}
                    onChange={this.onSetFile.bind(this)}
                    className="form-control" />
                </div>
                <div className="form-group">
                  <label>Phone:</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    minlength="10"
                    value={this.state.phone}
                    onChange={LinkState.bind(this)}
                    className="form-control" />
                </div>
                <div className="form-group">
                  <label>Location:</label>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    value={this.state.location}
                    onChange={LinkState.bind(this)}
                    className="form-control" />
                </div>
                <br />
                <button
                  type="submit" 
                  disabled={this.state.processingProfile}
                  className="btn btn-primary">
                  {profileButtonText}
                </button>
              </form>
            </Card>
            {changePWForm}
            <Card>
              <h2>Drink/Bar Info</h2>
              <p>This will only display on your account profile page.</p>
              <AccountOnboardingFields questionnaire={this.state.questionnaire} displayOnly={false} doSaveOnboarding={this.doSaveOnboarding.bind(this)} />
            </Card>
          </div>
        </div>
      </div>
    )
  }
}