import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ButtonLoader from '../ButtonLoader';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../../helpers/link-state');

let errorTimeout;
class DeleteVenueRequestModal extends Component {
  state = {
    show: false,
  }

  handleClose() {
    this.setState({
      show: false,
      error: '',
      processing: false,
    });
  }

  handleShow() {
    this.setState({
      show: true,
      error: '',
      processing: false,
    });
  }

  doRejectRequest() {
    this.setState({
      processing: true,
    });
    
    this.props.doRejectRequest(this.props.user.id, error => {
      if (error) {
        this.setState({
          processing: false,
          error,
        });
      } else {
        this.handleClose();
      }
    });
  }

  render() {
    
    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }


    const buttonText = this.state.processing ? (
      <ButtonLoader />
    ) : (
      <span>Yes, Reject!</span>
    );

    return (
      <>
        <Button className="txt-btn" onClick={this.handleShow.bind(this)}>
          Reject
        </Button>

        <Modal
          show={this.state.show}
          onHide={this.handleClose.bind(this)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Reject Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alert}
            <div className="col-12">
              <p>Are you sure you want to reject <span className="bold">{this.props.user.business_name}</span>?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="ghost" onClick={this.handleClose.bind(this)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.doRejectRequest.bind(this)}>
              {buttonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default (DeleteVenueRequestModal);