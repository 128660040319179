import React, { Component } from 'react';
import './Event.scss';
import Card from '../components/Card';
import Button from 'react-bootstrap/Button';
import User from '../img/user.jpg';
import Event from '../img/event.jpg';
import SidebarContent from '../components/SidebarContent';

import { Redirect } from "react-router-dom";
import Actions from '../actions/api';
import Alert from 'react-bootstrap/Alert';
import Carousel from 'react-bootstrap/Carousel';

import FullPageLoader from '../components/FullPageLoader';
import ButtonLoader from '../components/ButtonLoader';
const LinkState = require('../helpers/link-state');

let errorTimeout;
export default class BarEvent extends Component {
  state = {
    alert: '',
    error: '',

    comment: '',
    comments: [],

    evt: null,
    bar: null,
    processing: true,
    savingComment: false,
  };

  componentDidMount() {
    this.getEvent();
  }

  async getEvent() {
    this.setState({
      processing: true,
    });

    try {
      let eventId = parseInt(this.props.match.params.id) ? this.props.match.params.id : 0;
      let evt;

      if (!eventId) {
        // load with slug
        evt = await Actions.get(`bars/events/slug/${this.props.match.params.id}/`);
      } else {
        evt = await Actions.get(`bars/events/${this.props.match.params.id}/`);
      }
      eventId = evt.id;

      Actions.get(`bars/${evt.bar_id}/`).then(bar => {
        // todo: paging for comments
        Actions.get(`bars/bar_event_comments/`, {
          bar_event: eventId,
          limit: 5000,
        }).then(comments => {
          this.setState({
            comments: comments.results,
            evt,
            bar,
            processing: false,
          });
        }).catch(ex => {
          this.setState({
            comments: [],
            evt,
            bar,
            processing: false,
          });
        });
      }).catch(error => {
        window.bvBarEventMissing = true;
        this.setState({
          redirect: '/venues',
        });
      });
    } catch (ex) {
      window.bvBarEventMissing = true;
      this.setState({
        redirect: '/venues',
      });
    }
  }

  doAddComment(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      savingComment: true,
      error: '',
    });

    Actions.post('bars/bar_event_comments/', {
      bar_event_id: this.state.evt.id,
      text: this.state.comment,
    }).then(res => {
      let comments = this.state.comments;
      comments.unshift(res);
      this.setState({
        savingComment: false,
        error: '',
        comment: '',
        comments,
      });
    }).catch(error => {
      this.setState({
        savingComment: false,
        error,
      });
    });
  }


  doGoing() {
    if (this.state.evt.self_going === true) {
      return;
    }

    Actions.post(`bars/events/${this.state.evt.id}/going/`, {
      going: true,
    }).then(res => {
      const evt = this.state.evt;
      evt.going++;

      if (evt.self_going === false) {
        evt.not_going--;
      }

      evt.self_going = true;

      this.setState({
        evt,
      });
    }).catch(error => {

    });
  }

  doInterested() {
    if (this.state.evt.self_interested === true) {
      return;
    }

    Actions.post(`bars/events/${this.state.evt.id}/interested/`, {
      interested: true,
    }).then(res => {
      const evt = this.state.evt;
      evt.self_interested = true;

      this.setState({
        evt,
      });
    }).catch(error => {

    });
    // toggle this.. ?
    // let interested = !this.state.evt.self_interested;

    // Actions.post(`bars/events/${this.state.evt.id}/interested/`, {
    //   interested,
    // }).then(res => {
    //   const evt = this.state.evt;
    //   evt.self_interested = interested;

    //   this.setState({
    //     evt,
    //   });
    // }).catch(error => {

    // });
  }

  doNotGoing() {
    if (this.state.evt.self_going === false) {
      return;
    }
    Actions.post(`bars/events/${this.state.evt.id}/going/`, {
      going: false,
    }).then(res => {
      const evt = this.state.evt;
      evt.not_going++;

      if (evt.self_going === true) {
        evt.going--;
      }

      evt.self_going = false;

      this.setState({
        evt,
      });
    }).catch(error => {

    });
  }

  render () {
    const loggedIn = !!window.bvUser;

    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader/>;
    }

    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    const going = this.state.evt.going ? `+${this.state.evt.going}` : '0';
    const not_going = this.state.evt.not_going ? `-${this.state.evt.not_going}` : '0';

    const userLiked = this.state.evt.self_going === true;
    const userDisLiked = this.state.evt.self_going === false;
    const userInterested = this.state.evt.self_interested === true;
    // toggle ??
    // const interestedText = userInterested ? "I'm not interested" : "I'm interested";


    const authButtons = loggedIn ? (
      <div className="col-12 col-lg-6 profile-actions">
        <Button type="button" variant="error" onClick={this.doNotGoing.bind(this)} disabled={userDisLiked}>
          <i className="fa fa-thumbs-down"></i>
          <span>Not Going</span>
          <span>{not_going}</span>
        </Button>
        <Button type="button" variant="secondary" onClick={this.doGoing.bind(this)} disabled={userLiked}>
          <i className="fa fa-thumbs-up"></i>
          <span>Going</span>
          <span>{going}</span>
        </Button>
        <Button type="button" variant="primary" onClick={this.doInterested.bind(this)} disabled={userInterested}>
          I'm interested
        </Button>
      </div>
    ) : null;

    let commentCount = this.state.comments.length;
    let comments = null;

    if (this.state.comments && this.state.comments.length) {
      
      let commentsList = this.state.comments.map(comment => {

        const created = new Date(comment.created);

        
        const profileImage = comment.user.profile_image_url ? comment.user.profile_image_url : '/user-image.png';
        let profile = (
          <img className="img-fluid" src={profileImage} alt={comment.user.name}/>
        );

        let commentCreator = 'Anonymous User';
        if (comment.user && comment.user.full_name) {
          commentCreator = comment.user.full_name || '';
        }

        return (
          <div className="card comment" key={comment.id}>
            <div className="left">
              {profile}
            </div>
            <div className="right">
              <p><b>{commentCreator}</b> &bull; {comment.text}</p>
              <span>{created.toLocaleString()}</span>
              {/*<span className="edit-buttons">
                <button className="txt-btn edit">Edit</button>
                <button className="txt-btn delete">Delete</button>
              </span>*/}
            </div>
          </div>
        );
      });

      comments = (
        <div className="comments">
          {commentsList}
        </div>
      );
    }

    let buttonText = (<span>Add Comment</span>)

    if (this.state.savingComment) {
      buttonText = (<ButtonLoader />);
    }

    const commentInput = loggedIn ? (
      <div className="comment-input-box">
        <form onSubmit={this.doAddComment.bind(this)} method="post">
          <textarea
            id="comment"
            name="comment"
            required={true}
            value={this.state.comment}
            onChange={LinkState.bind(this)}
            className="form-control comment-input"
            placeholder="Add a comment...">
          </textarea>
          <button disabled={this.state.savingComment} type="submit" className="btn btn-primary">{buttonText}</button>
        </form>
      </div>
    ) : (
      <p>Log in to add comments!</p>
    );
    // todo: fix the login comment thingy here ^

    let startTime = new Date(this.state.evt.start_time);
    let endTime = new Date(this.state.evt.end_time);

    let eventTimeStr = `${startTime.toLocaleDateString()} ${startTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} - ${endTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;

    const ticketUrl = this.state.evt.purchase_ticket_link ? (
      <p>
        <br />
        <b><a href={this.state.evt.purchase_ticket_link}><i className="fa fa-ticket"></i> Buy Tickets</a></b>
      </p>
    ) : null;

    let carouselItems = [];

    this.state.evt.photos.forEach(p => {
      carouselItems.push(<Carousel.Item key={p.id}><img className="img-fluid" src={p.url} alt={this.state.evt.name}/></Carousel.Item>)
    });

    const carousel = carouselItems.length ? (
      <Carousel 
        indicators={false}
        interval={null}
        controls={carouselItems.length > 1}>
        {carouselItems}
      </Carousel>
    ) : null;

    return (
      <div className="container list events-page">
        <div className="row list-header d-none d-md-flex">
          <div className="col-12 col-lg-6">
            <h3>{this.state.evt.name}</h3>
            <span className="username">
              <span className="location">
                {eventTimeStr}
              </span>
            </span>
          </div>
          {authButtons}
        </div>
        <div className="row profile">
          <div className="col-12 col-lg-8">
            <div className="video-area">
              {carousel}
            </div>
            <div className="row list-header lower-header d-md-none">
              <div className="col-12 col-lg-6">
                <h3>{this.state.evt.name}</h3>
                <span className="username">
                  <span className="location">
                    {eventTimeStr}
                  </span>
                </span>
              </div>
              {authButtons}
            </div>
            <Card>
              <div className="address">
                <h5><i className="fa fa-file"></i> Details</h5>
                <p>
                  <b className="white">Description:</b>
                  <br/>
                  {this.state.evt.description.split('\n').map((item, key) => {
                    return <span key={key}>{item}<br/></span>
                  })}
                  <br />
                  <br />
                  <b className="white">Date:</b> {startTime.toLocaleDateString()}
                  <br />
                  <b className="white">Time:</b> {startTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                  <br /><br />
                  <b className="white">
                  <a href={`/venue/${this.state.bar.slug}`}>{this.state.bar.name}</a></b><br />
                  359 Ganson St<br />
                  Buffalo, NY 14203                 
                </p>
                {ticketUrl}
              </div>
            </Card>
            <div className="d-lg-none">
              <SidebarContent bar={this.state.bar} />
            </div>
            <hr className="white" />
            <h5><i className="fa fa-comments fa-margin-right"></i> Comments ({commentCount})</h5>
            {commentInput}
            {comments}
          </div>
          <div className="d-none d-lg-inline-block col-lg-4">
            <SidebarContent bar={this.state.bar} />
          </div>
        </div>
      </div>
    )
  }
}