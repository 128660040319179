import React, { Component } from 'react';
import ReactLoading from 'react-loading';

export default class FullPageLoader extends Component {
  render () {
    return (
      <div className="site-loader">
        <ReactLoading type="spin" color="#8439dd" height={50} width={50} />
      </div>
    );
  }
}