import React, { Component } from 'react';

export default class HLSPlayer extends Component {

  componentDidMount() {
    const id = this.props.id;
    var video = document.querySelector('#' + id);
    if (window.Hls.isSupported()) {
      var hls = new window.Hls();
      hls.loadSource(this.props.url);
      hls.attachMedia(video);
      if (typeof this.props.autoPlay === 'undefined' || this.props.autoPlay) {
        hls.on(window.Hls.Events.MANIFEST_PARSED,function() {
          video.play(); // this throws if we don't trigger this via a click
        });
      }
    } else {
      // todo; what if they can't see the video ??
    }

    window.plyr.setup(video);
  }

  render () {
    const id = this.props.id;

    if (typeof this.props.autoPlay === 'undefined' || this.props.autoPlay) {
      return (
        <video preload="none" id={id} autoPlay controls crossOrigin="true"></video>
      );
    } else {
      return (
        <video preload="none" id={id} controls crossOrigin="true"></video>
      );
    }
  }
}