import React, { Component } from 'react';
import './VenueRequest.scss';
import { Link } from "react-router-dom";
import Card from '../components/Card';
import Actions from '../actions/api';
import Alert from 'react-bootstrap/Alert';
import ButtonLoader from '../components/ButtonLoader';
import { Redirect } from "react-router-dom";
import Logo from '../img/logo2.png';

const LinkState = require('../helpers/link-state');

let errorTimeout;
export default class VenueRequest extends Component {
  state = {
    email: window.bvUser && window.bvUser.email || '',
    phone: '',
    full_name: window.bvUser && window.bvUser.full_name || '',
    processing: false,
    redirect: '',
    error: '',

    business_name: '',
    business_address: '',
    business_city: '',
    business_postal_code: '',
    business_region: '',
    business_country: '',
  };

  async doRequest(event) {
    event.preventDefault();
    event.stopPropagation();

    const phone_number = this.state.phone.replace(/\D/g,'');

    if (!this.state.email) {
      return this.setState({
        error: 'Please enter your email',
      });
    }

    if (!this.state.business_name) {
      return this.setState({
        error: 'Please enter your business name',
      });
    }

    if (!this.state.business_address) {
      return this.setState({
        error: 'Please enter your business address',
      });
    }

    if (!this.state.business_city) {
      return this.setState({
        error: 'Please enter your business city',
      });
    }

    if (!this.state.business_postal_code) {
      return this.setState({
        error: 'Please enter your business postal code',
      });
    }

    if (!this.state.business_region) {
      return this.setState({
        error: 'Please enter your business state',
      });
    }

    if (!phone_number) {
      return this.setState({
        error: 'Please enter your phone number',
      });
    }

    if (!this.state.full_name) {
      return this.setState({
        error: 'Please enter your name',
      });
    }

    this.setState({
      processing: true,
    });

    const data = {
      business_name: this.state.business_name,
      business_address: this.state.business_address,
      business_city: this.state.business_city,
      business_postal_code: this.state.business_postal_code,
      business_region: this.state.business_region,
      business_country: this.state.business_country || '',
      phone_number,
    };

    if (!window.bvUser) {
      data.email = this.state.email;
      data.full_name = this.state.full_name;
    }

    Actions.post('bars/venue_owner_requests/', data).then(response => {
      window.bvVenueRequested = true;
      this.setState({
        redirect: '/',
      });
    }).catch(error => {
      this.setState({
        error,
      });
    })
    return false;
  }

  render () {
    // todo: does this form need to work without a user already created?

    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    let buttonText = (<span>Submit Request</span>)

    if (this.state.processing) {
      buttonText = (<ButtonLoader />);
    }

    const loggedOutInputs = !window.bvUser ? (
      <div className="col-12">
        <h5 className="mb-3">Account Info:</h5>
        <div className="form-group">
          <input
            className="form-control"
            type="text"
            id="email"
            name="email"
            value={this.state.email}
            onChange={LinkState.bind(this)}
            placeholder="Email Address"
          />
        </div>

        <div className="form-group">
          <input
            className="form-control mb-4"
            type="text"
            id="full_name"
            name="full_name"
            value={this.state.full_name}
            onChange={LinkState.bind(this)}
            placeholder="Full Name"
          />
        </div>
      </div>
    ) : null;

    return (
      <div className="venue-register container">
        {alert}
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <div className="text-center">
              <div className="header__logo remove-pad">
                <Link to="/">
                   <img src={Logo} alt="BarView"/>
                </Link>
              </div>
              <p className="mb-0">Enter your information below to register your venue to the platform!</p>
            </div>
            <Card>
              <form onSubmit={this.doRequest.bind(this)} method="post" className="text-left">
                {loggedOutInputs}

                <div className="col-12">
                  <h5 className="mb-4">Venue Info:</h5>
                  <div className="form-group">
                    <label htmlFor="phone">Business Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="business_name"
                      name="business_name"
                      value={this.state.business_name}
                      onChange={LinkState.bind(this)}
                      placeholder="Business Name"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone">Business Phone Number</label>
                    <input
                      className="form-control"
                      type="text"
                      id="phone"
                      name="phone"
                      minlength="10"
                      value={this.state.phone}
                      onChange={LinkState.bind(this)}
                      placeholder="Business Phone Number"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="Address1">Business Address</label>
                    <input
                      type="text"
                      id="business_address"
                      name="business_address"
                      value={this.state.business_address}
                      onChange={LinkState.bind(this)}
                      className="form-control"
                      placeholder="Address Line 1..." />
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="city">Business City</label>
                        <input 
                          type="text"
                          id="business_city"
                          name="business_city"
                          value={this.state.business_city}
                          onChange={LinkState.bind(this)}
                          className="form-control"
                          placeholder="City..." />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="state">Business State</label>
                        <input 
                          type="text"
                          id="business_region"
                          name="business_region"
                          value={this.state.business_region}
                          onChange={LinkState.bind(this)}
                          className="form-control"
                          placeholder="State..." />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="Zip">Business Zip Code</label>
                    <input
                      type="text"
                      id="business_postal_code"
                      name="business_postal_code"
                      value={this.state.business_postal_code}
                      onChange={LinkState.bind(this)}
                      className="form-control mb-4"
                      placeholder="Zip Code..." />
                  </div>

                  <button
                    type="submit"
                    className="btn btn--primary btn--large mt-3 force-30"
                  >
                    {buttonText}
                  </button>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}