import React, { Component } from 'react';
import './Privacy.scss';
import { Link } from "react-router-dom";
import Card from '../components/Card';

export default class Privacy extends Component {
  state = {
  }


  render () {
    return (
      <div className="privacy container">
        <div className="row">
          <div className="col-12">
            <h2>Privacy Policy</h2>
            <div className="card no-hover white-b">
              <h5>Privacy Policy:</h5>
              <p>BarView, operated through Neural Cloud Solutions LLC (&ldquo;we,&rdquo; or &ldquo;BarView&rdquo;),is an online community created to allow interaction between nightlife patrons (users), advertisers, and bar owners. Members can create profiles and submit listings to the site, join in forum conversations, comment and rate bars, add drinks, and upload images, text, and other types of files. In order to make the site functional and enjoyable to all BarView members, we allow our users to communicate to one another through various mediums without screening their files or comments, but not providing personal information to other users or outside parties. We take the protection of your private personal information very seriously, and our policy is as follows:</p>
              <p><strong>Information Bar View Collects:</strong></p>
              <p>BarView collects personal information such as name, age, geographical location, and e-mail address to provide members with the best experience online with respect to the private information mentioned above. BarView also collects non-personal information including but not limited to things such as IP address, operating system, browser type, and other applicable information. This type of information is used to improve the application and community, ensure security, and maintain the website through tracking user activity. All non-personal information may be used by third parties in providing applicable advertisements and services to our users, but personal information will not be used by third parties and can only be utilized by BarView on the site (and is only collected from members). All information that users provide BarView will be treated with the utmost respect and will only be used to improve the overall online environment for users and members alike, in the sole discretion and opinion of BarView. All information provided by BarView with respect to the listings is gathered by members, inputted by owners, or added by BarView staff. While the information is listed on the site, BarView is not responsible for the validity of the details or opinions on the site, and all this information will not be redistributed to outside parties. All information gathered on the site is for security, maintenance, or improved experiences.</p>
              <p><strong>Cookies:</strong></p>
              <p>BarView uses cookies to store user statistics and to record online activity to ensure a smoothly running site. Browser info, online profiles, IP addresses, and other non-personal information gathered by BarView is used to improve the web page content and preferences based on these facts. For example, when a user starts a session logged on from New York City the site will load regional advertisements and listings that correspond to the area. All cookie use is simply to improve user functionality and their overall experience at BarView.com. Cookies can be disabled by changing settings on your personal computer, but we feel that the use of cookies will ultimately allow for a better experience with our site.</p>
              <p><strong>Links:</strong></p>
              <p>BarView provides links to other sites through various mediums. BarView is not responsible for the content, privacy policy, or practices of sites linked through the BarView site. BarView&rsquo;s privacy policy is only applicable to information collected and used on the BarView site. When logging on to another site, whether through a BarView link or not, users should always read the site&rsquo;s privacy policy and terms of use.</p>
              <p><strong>Forums, Comments, Added Information, reviews:</strong></p>
              <p>When posting information on the BarView website, this information is viewable by the general public, and therefore is subject to unsolicited advertisements, contact, etc. BarView condones all unsolicited business fostered by outside parties on our site, but all information made public is subject to outside contact.</p>
              <p><strong>Modifying Information:</strong></p>
              <p>Users can modify their member profiles, change detailed listings, and comment on BarView that other users have already. BarView also reserves the right to remove information that is slanderous or in any way non-compliant with the TERMS OF USE.</p>
              <p><strong>Third Party Advertising:</strong></p>
              <p>Advertisements that appear on this site will be presented to users through BarView, who may set cookies to regionally classify ads (also non-personal classification (browser, etc). By doing this BarView has the ability to track advertisement effectiveness in several different ways which, when added up, allows Barview.com to provide target marketing efforts to users that will ultimately show ads that should create the most buzz among users. This privacy policy just explains how BarView uses cookies from users and does not include how third-party advertisers use information. BarView is not responsible for how third parties may use your information. You can find third party advertisers handling of information in their privacy policy provided on their sites.</p>
              <p>Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use of the DART cookie enables it to serve ads to our users based on their visit to our sites and other sites on the Internet. Users may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy.</p>
              <p><strong>Security:</strong></p>
              <p>BarView allows users to create usernames and passwords which are protected from any party that cannot validate their identity, except to those who can receive email at the same address used to create membership. Mistakes in software, networking, and general security errors are not the responsibility of BarView. This privacy policy only ensures that BarView protects user information from the public, other members, and anyone else who is not an officer of the company.</p>
              <p><strong>Collection of Information on Minors Security:</strong></p>
              <p>BarView does not knowingly offer membership or services to any person under the age of 18, so therefore we do not collect information on minors either.</p>
              <p><strong>Updated/Changing the Privacy Policy:</strong></p>
              <p>BarView will make updates and changes to our privacy policy periodically. Users are responsible for checking it, and the Terms of Use alike, in order to keep up to date with how we handle the information we collect. By continuing to use the Website, the user acknowledges and accepts such revisions to the terms of the Privacy Policy as it may be updated from time to time. Any &ldquo;major&rdquo; changes to the policy, namely those referring to the collection, use, or distribution of personal information, will be disclosed to users via email.</p>
              <p><strong>Contact Us:</strong></p>
              <p>If you have any problems or questions concerning the Privacy Policy, or Terms of Use, please feel free to contact us at BarView.com</p>
              <p><strong>Terms of Us:</strong></p>
              <p>This Website is operated by BarView, through Neural Cloud Solutions LLC, referred to as "We", and can be reached by using our contact form. BarView is an online community created to connect bar and club patrons with bar, club, restaurant, and pub owners throughout the country. User accounts, forums, and a detailed search engine allows users to search for, comment on, and discuss establishments listed on the BarView.com web site (the "Site"), under certain terms and conditions.</p>
              <p><strong>Acceptance of terms &amp; Conditions through Use:</strong></p>
              <p>Use of the BarView.com website constitutes and signifies the User&rsquo;s (your) agreement to all terms, conditions, and notices contained or referenced herein (the "Terms of Use"). We reserve the right, at our discretion, to revise and update these Terms of Use. Your use of this site is deemed your acceptance of the current Terms of Use, which are periodically updated.</p>
              <p><strong>Agreement to User Conduct Rules:</strong></p>
              <p>You agree to follow the BarView User Conduct Rules, including but not limited to, not using this site any unlawful purpose.</p>
              <p>By posting information in or using any communicative medium available to you on the site, you agree that you will not upload, post, or in any other way distribute any materials, including but not limited to text, images, files, data, or other information that:</p>
              <ul>
              <li>Is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy or publicity rights, tortious, or otherwise violates BarView.com policies.</li>
              <li>Infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any party.</li>
              <li>Constitutes unauthorized or unsolicited advertising, forwarded messages/chain letters, or any other form of unauthorized solicitation, including all forms of gambling.</li>
              <li>Contains software viruses or any other form of computer code, files, or programs that are designed or intended to disrupt, damage, or limit the functioning of any software, hardware, or telecommunications equipment; or to damage or obtain unauthorized access to any data or other information of any third party.</li>
              <li>Contains information, material, or pictures of any person who is not both older than eighteen (18) years old and over the age of majority in such person's state, province, or country of residence.</li>
              </ul>
              <p>You also agree that you will not collect information about the users or members of this site or use such information for the purpose of transmitting or facilitating transmission of unsolicited bulk e-mail or communications. You further agree that you will not knowingly solicit or collect personal information from a child 17 years old or younger. While BarView monitors reviews for listings, forums, message boards, comments, and all other forms of communicating on the site by users and members is the Users' responsibility and does not reflect the opinion of BarView or Neural Cloud Solutions LLC. We do also have the right at our discretion to remove any content that, in our judgement, does not comply with the User Conduct Rules or is otherwise harmful, objectionable, or inaccurate.</p>
              <p><strong>Links and Third-Party Sites:</strong></p>
              <p>This site may produce automated search results or otherwise link you to other sites on the Internet. These sites may contain information or material that some users may find inappropriate or offensive. These other sites are not under BarView control, and you acknowledge that BarView and Neural Cloud Solutions LLC. is not responsible for the accuracy, copyright compliance, legality, decency, or any other aspect of the content of such sites. The inclusion of such a link does not imply endorsement of the site by BarView or Neural Cloud Solutions or any association with its operators.</p>
              <p><strong>Proprietary Rights and Restrictions:</strong></p>
              <p>You acknowledge and agree that all content and materials available on this site are protected by copyrights, trademarks, service marks, patents, or other proprietary rights and by other laws and that their use is restricted by the terms of this Agreement. The BarView.com and Neural Cloud Solutions LLC product and service names are trademarks and/or service marks of BarView.com and Neural Cloud Solutions LLC. You will not display or use the BarView.com, in any manner, without our prior permission. Except as expressly authorized by us, you agree not to sell, resell, exploit for any commercial purposes, license, rent, modify, distribute, copy, reproduce, duplicate, transmit, publicly display, publicly perform, publish, adapt, edit, or create derivative works from any portion of such materials or content. Notwithstanding the above, you may print or download one copy of the materials or content on this site on any single computer for your personal, non-commercial use, provided you keep intact all copyright and other proprietary notices. No further or additional use of such materials or content is permitted. Systematic retrieval of data or other content from this site to create or compile, directly or indirectly, a collection, compilation, database or directory without written permission from us is prohibited. In addition, use of the content or materials for any purpose not expressly permitted in these Terms of Use is prohibited.</p>
              <p><strong>User&rsquo;s Grant of Limited License:</strong></p>
              <p>By posting or submitting content to this site, you:</p>
              <ul>
              <li>Grant BarView and its affiliates and licensees the world-wide, royalty free, perpetual, irrevocable, non-exclusive, and fully sublicensable right and license to use, reproduce, display, perform, adapt, modify, alter, distribute, have distributed, and promote the content in any form, anywhere and for any purpose.</li>
              <li>Warrant and represent that you own or otherwise control all the rights to the content and that public posting and use of your content by barview.com will not infringe or violate the rights of any third party.</li>
              </ul>
              <p><strong>Procedure for Reporting Claims of Copyright Infringement:</strong></p>
              <p>If you believe that your work has been copied and is accessible on this site in a way that constitutes intellectual property infringement, you may notify us at: <a href="mailto:support@barview.com">Support@Barview.com</a></p>
              <p><strong>Procedure for Reporting Violations of Rights of Privacy Or Publicity:</strong></p>
              <p>If you believe that your privacy rights or publicity rights have been violated by information or material that is accessible on this site, you may notify us at: <a href="mailto:support@barview.com">Support@Barview.com</a></p>
              <p><strong>Disclaimer of Warranties</strong></p>
              <p>All materials, information, software, products, and services included in or available through this site (the "content") are provided as is and as available for your use. The content is provided without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or noninfringement. BarView, Neural Cloud Solutions LLC, subsidiaries, and licensors do not warrant that the content is accurate, reliable, or correct; that this site will be available at any particular time or location; that any defects or errors will be corrected; or that the content is free of viruses or other harmful components. Your use of this site is solely at your risk. Any material viewed, downloaded, or otherwise obtained through the use of this site is done solely at your own risk and discretion, and you will be solely responsible for any damage, personal injury or distress, damage to your CPU, or loss of data, that results from the viewing or downloading of any such material. Since some jurisdictions do not permit the exclusion of certain warranties, these exclusions may not apply to you.</p>
              <p><strong>Limitation of Liability:</strong></p>
              <p>Under no circumstance shall BarView, Neural Cloud Solutions LLC, members, or employees be liable for any direct, indirect, punitive, incidental, special, or consequential damages that result from the use of, or inability to use, this site. This limitation applies whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if BarView has been advised of such damage. Because some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, BarView and Neural Cloud Solutions' liability in such jurisdictions shall be limited to the extent permitted by law.</p>
              <p><strong>Indemnification:</strong></p>
              <p>You agree to indemnify and hold BarView, and its parent, Neural Cloud Solutions LLC, and their respective officers, directors, agents, co-branders and other partners, contractors, and employees, harmless from and against any and all claims, expenses, and demands, including attorneys' fees, made by any third party due to, arising out of, or asserted in connection with your use or misuse of this site, including claims relating to content you submit, post to or transmit through this site, your breach of any terms of this Agreement, the determination by a jurisdiction that You or your agents have improperly utilized the site or its services or features to violate the laws and regulations of any applicable jurisdiction, and/or your connection to and use of the site. BarView.com reserves the right to have control of any defense and control of any matter otherwise subject to indemnification by you. You agree to cooperate with BarView and Neural Cloud Solutions LLC in asserting any available defenses.</p>
              <p>You agree to indemnify and hold BarView, and its parent, Neural Cloud Solutions LLC, and their respective officers, directors, agents, co-branders and other partners, contractors, and employees, harmless from and against any and all claims, expenses, and demands, including attorneys' fees, made by any third party due to, arising out of, or asserted in connection with your use or misuse of this site, including claims relating to content you submit, post to or transmit through this site, and your connection to and use of this site. BarView.com reserves the right to have control of any defense and control of any matter otherwise subject to indemnification by you. You agree to cooperate with BarView and Neural Cloud Solutions LLC in asserting any available defenses.</p>
              <p><strong>International Use:</strong></p>
              <p>BarView makes no representation that materials on this Site are appropriate or available for use in any particular locations and accessing them from territories where their contents are illegal is prohibited. Those who choose to access this site from any location do so on their own initiative, at their own risk, and are responsible for compliance with local laws. If you use this site in a jurisdiction that prohibits or restricts such use, your use will be subject to, without limitation, any other provision of the Terms of Use, and BarView or Neural Cloud Solutions LLC, shall not have any liability with respect to such use.</p>
              <p><strong>Choice of Law and Forum:</strong></p>
              <p>These Terms of Use shall be governed by and construed in accordance with the laws of the State of New York, excluding its conflicts of law rules. You expressly agree that the exclusive jurisdiction for any claim or action arising out of or relating to these Terms of Use or your use of this site shall lie only in the state courts and district of New York State federal courts, and you further agree to and submit to the exercise of personal jurisdiction of such courts for the purpose of litigating any such claim or action.</p>
              <p><strong>Severability and Integration Choice of Law and Forum:</strong></p>
              <p>Unless otherwise specified herein, this agreement constitutes the entire agreement between you and barview.com with respect to this site and supersedes all prior or contemporaneous communications and proposals (whether oral, written, or electronic) between you and barview.com with respect to this site. If any part of these Terms of Use is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect.</p>
              <p><strong>Termination:</strong></p>
              <p>BarView reserves the right, in its sole discretion, to terminate or modify your access to all or part of this site, with or without notice, for any reason, including, without limitation, if BarView believes that you have violated or acted inconsistently with the letter or spirit of these Terms of Use. This includes BarView&rsquo;s right to terminate a user's ability to post materials to the site. You acknowledge that BarView shall not be liable to you or any third party for any termination or modification of your access to this site.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}