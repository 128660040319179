import React, { useContext, useEffect } from 'react'
import { MapToggleContext } from './MapButton'
import {useLocation} from 'react-router-dom'

const WorldMapToggleButton = () => {
    const {isOpen, setIsOpen} = useContext(MapToggleContext)
    const location = useLocation()
  return (
   <>
   {location.pathname !== "/" && location.pathname !== "/register" && location.pathname !== "/login" &&  (
     <div>
     <btn
         className="map-toggle-btn"
         onClick={() => {
         setIsOpen(!isOpen)
         }}
     >
         <i
         className={`${isOpen ? "fa fa-th" : "fa fa-globe"}`}
         ></i>
     </btn>
 </div>
   )}
   </>
  )
}

export default WorldMapToggleButton