import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { BrowserRouter as Router } from "react-router-dom";
import Content from './components/Content';
import ScrolltoTop from './components/ScrolltoTop';


function App() {

  return (
    <div className="app">
      <Router>
        <ScrolltoTop/>
        <Content />
      </Router>
    </div>
  );
}

export default App;
