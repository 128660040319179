import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Header from './Header';
import Home from '../views/Home';
import Confirm from '../views/Confirm';
import NewsletterConfirm from '../views/NewsletterConfirm';
import About from '../views/About';
import Account from '../views/Account';
import AccountEdit from '../views/AccountEdit';
import Admin from '../views/Admin';
import AdminVenue from '../views/AdminVenue';
import AdminCamera from '../views/AdminCamera';
import AdminEvents from '../views/AdminEvents';
import AdminSpecials from '../views/AdminSpecials';
import AdminUsers from '../views/AdminUsers';
import AdminBilling from '../views/AdminBilling';
import AdminVenues from '../views/AdminVenues';
import AdminVenueRequests from '../views/AdminVenueRequests';

import PaymentSuccess from '../views/PaymentSuccess';
import PaymentError from '../views/PaymentError';
import BOWSuccess from '../views/BOWSuccess';
import BOWError from '../views/BOWError';

import Venue from '../views/Venue';
import Venues from '../views/Venues';
import Contact from '../views/Contact';
import Covid from '../views/Covid';
import Events from '../views/Events';
import Event from '../views/Event';
import Favorites from '../views/Favorites';
import Forgot from '../views/Forgot';
import Privacy from '../views/Privacy';
import Reset from '../views/Reset';
import Login from '../views/Login';
import Logout from '../views/Logout';
import Notifications from '../views/Notifications';
import Register from '../views/Register';
import VenueRequest from '../views/VenueRequest';
import Specials from '../views/Specials';
import Footer from './Footer';
import { Switch, Route } from "react-router-dom";
import FullPageLoader from './FullPageLoader';
import Actions from '../actions/api';
import './Content.scss';

import ReactGA from 'react-ga'
import createHistory from 'history/createBrowserHistory'
import MapTogalProvider from './MapButton';

const history = createHistory()
history.listen(location => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
})

class Content extends Component {

  state = {
    processing: true,
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

    let userRefresh = localStorage.getItem('userRefresh');
    let userAccess = localStorage.getItem('userAccess');

    if (userAccess) {
      Actions.get('users/me/').then(user => {
        window.bvUser = user;

        if (localStorage && localStorage.getItem('admin_bar_id')) {
          window.bvUser.admin_bar_id = localStorage.getItem('admin_bar_id');
        }

        this.setState({
          processing: false,
        });
      }).catch(ex => {
        // invalid token ... 
        // localStorage.clear();
        this.setState({
          processing: false,
        });
      });
    } else {
      this.setState({
        processing: false,
      });
    }
  }

  render () {

    let processing = this.state.processing;

    let innards = null;

    if (processing) {
      // loading user, validate token ...
      innards = (<FullPageLoader />);
    } else {
      innards = (
        <MapTogalProvider>
          <Header />
          <main className={'main main--' + this.props.location.pathname.replace(/\//g, '')}>
            <Route render={({ location }) => {

              return (
                <Switch location={location}>
                  <Route exact path="/" component={Home}/>
                  <Route path="/confirm/newsletter/:id" component={NewsletterConfirm} />
                  <Route path="/confirm/:id" component={Confirm} />
                  <Route path="/login" component={Login} />
                  <Route path="/logout" component={Logout} />
                  <Route path="/register" component={Register} />
                  <Route path="/register-venue" component={VenueRequest} />
                  <Route path="/about" component={About} />
                  <Route path="/admin/venue" component={AdminVenue} />
                  <Route path="/admin/venue/add" component={AdminVenue} />
                  <Route path="/admin/camera" component={AdminCamera} />
                  <Route path="/admin/events" component={AdminEvents} />
                  <Route path="/admin/specials" component={AdminSpecials} />
                  <Route path="/admin/users" component={AdminUsers} />
                  <Route path="/admin/requests" component={AdminVenueRequests} />
                  <Route path="/admin/billing" component={AdminBilling} />
                  <Route path="/admin/venues" component={AdminVenues} />
                  <Route path="/admin" component={Admin} />
                  <Route path="/account/edit" component={AccountEdit} />
                  <Route path="/account" component={Account} />
                  <Route path="/venue/:id" component={Venue} />
                  <Route path="/venues" component={Venues} />
                  <Route path="/events" component={Events} />
                  <Route path="/event/:id" component={Event} />
                  <Route path="/favorites" component={Favorites} />
                  <Route path="/forgot" component={Forgot} />
                  <Route path="/reset/:id" component={Reset} />
                  <Route path="/notifications" component={Notifications} />
                  <Route path="/specials" component={Specials} />
                  <Route path="/contact" component={Contact} />
                  <Route path="/covid" component={Covid} />
                  <Route path="/privacy" component={Privacy} />

                  <Route path="/payment/success" component={PaymentSuccess} />
                  <Route path="/payment/error" component={PaymentError} />
                  <Route path="/payment/cancel" component={PaymentError} />

                  <Route path="/bar-of-the-week-payment/success" component={BOWSuccess} />
                  <Route path="/bar-of-the-week-payment/error" component={BOWError} />
                  <Route path="/bar-of-the-week-payment/cancel" component={BOWError} />
                </Switch>
              )
            }}/>
          </main>
          <Footer />
        </MapTogalProvider>
      );
    }

    return innards;
  }
}

export default withRouter(Content);