import React, { Component } from 'react';
import './Account.scss';
import { Link } from "react-router-dom";
import Card from '../components/Card';
import { LinkContainer } from 'react-router-bootstrap';
import { Redirect } from "react-router-dom";
import Actions from '../actions/api';
// import User from '../img/user.jpg';
import CloseProfileModal from '../components/modals/CloseProfileModal';
import Alert from 'react-bootstrap/Alert';
import AccountOnboardingFields from '../components/AccountOnboardingFields';
const LinkState = require('../helpers/link-state');

let errorTimeout;
export default class Account extends Component {
  state = {
    redirect: '',
    error: '',
    alert: '',

    full_name: window.bvUser ? window.bvUser.full_name : '',
    avatar: '',
    avatarImage: null,

    questionnaire: (window.bvUser && window.bvUser.questionnaire) || {},
  }

  async doSaveOnboarding(data, onComplete) {

    try {
      const questionnaire = await Actions.patch('users/me/questionnaire/', data);
      window.bvUser.questionnaire = questionnaire;
      this.setState({
        error: '',
        alert: 'Profile Saved!',
        questionnaire,
      });

      onComplete();
    } catch (ex) {
      this.setState({
        error: 'Failed to save profile. Please try again.',
      });
      onComplete();
    }
  }

  onSetFile(evt) {
    if (evt.target.files.length) {
      this.setState({
        avatarImage: evt.target.files[0],
        avatar: evt.currentTarget.value,
      });
    } else {
      this.setState({
        avatarImage: null,
        avatar: '',
      });
    }
  }

  async doCloseUser(handleClose) {
    
    try {
      await Actions.delete(`users/me/close/`);
      handleClose();
      
      setTimeout(() => {
        localStorage.clear();
        window.bvUser = null;
        window.location.href = '/'
      }, 1500);
    } catch (ex) {
      handleClose('Failed to Close the account. Please try again.');
    }
  }


  render () {

    if (!window.bvUser.favorite_drinks) {
      window.bvUser.favorite_drinks = [];
    }

    if (!window.bvUser.favorite_bars) {
      window.bvUser.favorite_bars = [];
    }

    const user = window.bvUser;

    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    if (!user) {
      return (
        <Redirect to="/login" />
      );
    }

    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    } else if (this.state.alert) {
      alert = (
        <Alert variant="success">
          {this.state.alert}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          alert: '',
        });
      }, 3e3);
    }

    const keys = this.state.questionnaire ? Object.keys(this.state.questionnaire) : [];
    const needsToCompleteProfile = !keys.length || keys.filter(k => this.state.questionnaire && this.state.questionnaire[k] && this.state.questionnaire[k].length > 0).length === 0;

    let index = 0;
    const savedFavoriteDrinks = window.bvUser.favorite_drinks.map(drink => {
      return (
        <tr key={index++}>
          <td>{drink.name}</td>
        </tr>
      );
    });

    index = 0;
    const savedFavoriteBars = window.bvUser.favorite_bars.map(bar => {
      return (
        <tr key={index++}>
          <td>{bar.name}</td>
        </tr>
      );
    });


    const profileDisplay = needsToCompleteProfile ? (
      <Card>
        <h2>Let's Complete Your Profile!</h2>
        <p>Filling out your profile will help to provide you with a better user experience, allowing you to stay informed of the latest venue activities and BarView updates.</p>
        <AccountOnboardingFields questionnaire={this.state.questionnaire} displayOnly={false} doSaveOnboarding={this.doSaveOnboarding.bind(this)} />
      </Card>

    ) : (
      <AccountOnboardingFields questionnaire={this.state.questionnaire} displayOnly={true} />
    );
    const location = window.bvUser.location_str || '';

    const dateJoined = new Date(window.bvUser.date_joined);
    const month = dateJoined.toLocaleString('default', { month: 'long' });
    const year = dateJoined.getFullYear();

    const joined = `${month} ${year}`;

    const profile_url = window.bvUser.profile_image_url || '/user-image.png';

    const adminLink = window.bvUser && (window.bvUser.super_admin || (window.bvUser.bar_memberships && window.bvUser.bar_memberships.length)) ? (
      <Link
        to="/admin"
        className="btn btn-link"
      >
        Manage Venues
      </Link>
    ) : null;

    return (

      <div className="account container">
        {alert}
        <div className="row">
          <div className="col-12 col-lg-5 col-xl-4">
            <Card>
              <div className="user_avatar">
                <img className="img-fluid" src={profile_url} alt="logo"/>
                <h4>{window.bvUser.full_name || ''}</h4>
                <p>Joined {joined}</p>
              </div>
              <ul className="profile-info-block">
                <li>
                  <b>Email:</b>
                  <span><a href={'mailto:'+window.bvUser.email}>{window.bvUser.email}</a></span>
                </li>
                <li>
                  <b>Phone:</b>
                  <span>{window.bvUser.phone || 'N/A'}</span>
                </li>
                <li>
                  <b>Location:</b>
                  <span>{location}</span>
                </li>
              </ul>
              <LinkContainer to="/account/edit">
                <button
                  type="button"
                  className="btn btn--primary btn--large mt-4"
                >
                  Edit Info
                </button>
              </LinkContainer>
              <CloseProfileModal doCloseUser={this.doCloseUser.bind(this)} /> 
              {adminLink}
              <Link
                to="/logout"
                className="btn btn-link"
              >
                Log Out
              </Link>
            </Card>
          </div>
          <div className="col-12 col-lg-7 col-xl-8">
            {profileDisplay}
          </div>
        </div>
      </div>
    )
  }
}
