import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tabs'
import TextTruncate from 'react-text-truncate';
import { LinkContainer } from 'react-router-bootstrap';
import Event from '../../img/event.jpg';
import Form from 'react-bootstrap/Form'
const LinkState = require('../../helpers/link-state');

let errorTimeout;
export default class EventModal extends Component {
  
  state = {
    year: new Date().getFullYear(),
  };

  filterEvents() {

  }

  render () {

    const now = new Date();
    const upcomingEvents = this.props.events.filter(e => new Date(e.start_time) > now);
    const pastEvents = this.props.events.filter(e => new Date(e.start_time) <= now && new Date(e.start_time).getFullYear() == this.state.year);


    const upcomingEventsList = upcomingEvents.length ? upcomingEvents.map(ev => {
      let startTime = new Date(ev.start_time);
      let endTime = new Date(ev.end_time);

      let eventTimeStr = `${startTime.toLocaleDateString()} ${startTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} - ${endTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;

      const eventImage = ev.photos && ev.photos.length ? ev.photos[0].url : ''; // todo: placeholder?
      const eventUrl = `/event/${ev.slug}`;

      return (
        <li key={ev.id}>
          <LinkContainer to={eventUrl}>
            <div className="event">
              <div className="left" style={{ backgroundImage: `url(${eventImage})`}}>
                <img className="img-fluid d-md-none" src={Event} alt={ev.name} />
              </div>
              <div className="right">
                <h6>{ev.name}</h6>
                <p className="date-time">{eventTimeStr}</p>
                <div className="description d-none d-md-block">
                  <TextTruncate
                    line={3}
                    truncateText="…"
                    text={ev.description}
                    textTruncateChild={<a href={eventUrl}>View Details</a>}
                  />
                </div>
                <div className="description d-md-none">
                  <TextTruncate
                    line={5}
                    truncateText="…"
                    text={ev.description}
                    textTruncateChild={<a href={eventUrl}>View Details</a>}
                  />
                </div>
                <button 
                  type="button"
                  className="btn btn-secondary">
                  View Details
                </button>
              </div>
            </div>
          </LinkContainer>
        </li>
      );
    }) : null;

    const pastEventsList = pastEvents.length ? pastEvents.map(ev => {
      let startTime = new Date(ev.start_time);
      let endTime = new Date(ev.end_time);

      let eventTimeStr = `${startTime.toLocaleDateString()} ${startTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} - ${endTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;

      const eventImage = ev.photos && ev.photos.length ? ev.photos[0].url : ''; // todo: placeholder?
      const eventUrl = `/event/${ev.slug}`;

      return (
        <li key={ev.id}>
          <LinkContainer to={eventUrl}>
            <div className="event">
              <div className="left" style={{ backgroundImage: `url(${eventImage})`}}>
                <img className="img-fluid d-md-none" src={Event} alt={ev.name} />
              </div>
              <div className="right">
                <h6>{ev.name}</h6>
                <p className="date-time">{eventTimeStr}</p>
                <div className="description d-none d-md-block">
                  <TextTruncate
                    line={3}
                    truncateText="…"
                    text={ev.description}
                    textTruncateChild={<a href={eventUrl}>View Details</a>}
                  />
                </div>
                <div className="description d-md-none">
                  <TextTruncate
                    line={5}
                    truncateText="…"
                    text={ev.description}
                    textTruncateChild={<a href={eventUrl}>View Details</a>}
                  />
                </div>
                <button 
                  type="button"
                  className="btn btn-secondary">
                  View Details
                </button>
              </div>
            </div>
          </LinkContainer>
        </li>
      );
    }) : null;


    const upcoming = upcomingEvents.length ? (
      <ul>
        {upcomingEventsList}
      </ul>
    ) : (
      <div className="no-results"><p>No upcoming events found. Please check back later.</p></div>
    );

    const past = pastEvents.length ? (
      <ul>
        {pastEventsList}
      </ul>
    ) : (
      <div className="no-results"><p>No past events found in this year. Please try a different year.</p></div>
    );

    const yearOptions = [];
    const thisYear = now.getFullYear();

    for (let i = 0; i < 5; i++) {
      yearOptions.push(<option key={thisYear-i} value={thisYear-i}>{thisYear-i}</option>);
    }
  
    return (
      <Modal
        size="lg"
        show={this.props.show}
        onHide={this.props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>All Events</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="upcoming" id="uncontrolled-tab-example">
          <Tab eventKey="upcoming" title="Upcoming Events">
            <div className="events-container">
              {upcoming}
            </div>
          </Tab>
          <Tab eventKey="past" title="Past Events">
            <Form>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  name="year"
                  value={this.state.year}
                  onChange={LinkState.bind(this)}
                  as="select">
                  {yearOptions}
                </Form.Control>
              </Form.Group>
            </Form>
            <div className="events-container">
              {past}
            </div>
          </Tab>
        </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="ghost" onClick={this.props.handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.props.handleClose}>
            Go Back
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
