
const Cookie = require('cookie');
const Qs = require('qs');
const request = require('request-json');
// https://www.npmjs.com/package/request-json

// TODO: GET FROM ENV
const baseUrl = process.env.REACT_APP_API_URL;

const client = request.createClient(baseUrl);
const basicAuthClient = request.createClient(baseUrl);

const JsonFetch = (options, basicAuth) => {
  // const cookies = Cookie.parse(document.cookie);
  let url = options.url;

  if (options.query) {
    url += '?' + Qs.stringify(options.query);
  }

  if (options.basicAuth) {
    basicAuthClient.setBasicAuth(options.data.email, options.data.password);
    delete options.data.email;
    delete options.data.password;
  } else {
    let userRefresh = localStorage.getItem('userRefresh');
    let userAccess = localStorage.getItem('userAccess');

    if (userAccess) {
      client.headers['Authorization'] = `Bearer ${userAccess}`;
    }

    // todo: handle refresh workflow
  }

  const handleResponse = response => {
    // todo: deal with 401/403 to refresh token or kick back to login as needed
    if (response.res.statusCode == 401) {
      localStorage.removeItem('userRefresh');
      localStorage.removeItem('userAccess');
    }

    if (!response) {
      return {};
    } else if (response.body && response.body.error) {
      if (response.body.error.extra) {
        if (response.body.error.extra.detail) {
          throw response.body.error.extra.detail;
        } else {
          throw response.body.error.extra;
        }
      } else {
        throw response.body.error.message;
      }
    } else if (response.body) {
      return response.body;
    } else {
      return {}; //throw 'no response';
    }
  };

  const clientInstance = basicAuth ? basicAuthClient : client;

  if (options.method === 'POST') {
    return clientInstance.post(url, options.data).then(handleResponse);
  } else if (options.method === 'GET') {
    return clientInstance.get(url).then(handleResponse);
  } else if (options.method === 'PUT') {
    return clientInstance.put(url, options.data).then(handleResponse);
  } else if (options.method === 'PATCH') {
    return clientInstance.patch(url, options.data).then(handleResponse);
  } else if (options.method === 'DELETE') {
    return clientInstance.delete(url).then(handleResponse);
  }
  return null;
};

class Actions {

  static async basicAuthPost(url, email, password) {
    const request = {
      method: 'POST',
      url, 
      data: {
        email, 
        password,
      },
      basicAuth: true,
    };

    return await JsonFetch(request, true);
  }

  static async saveFile(url, file) {
    return fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        "Content-type": ""
      }
    });
  }

  static async get(url, query) {
    const request = { method: 'GET', url, query };

    return await JsonFetch(request);
  }

  static async put(url, data) {

    const request = { method: 'PUT', url, data };

    return await JsonFetch(request);
  }

  static async patch(url, data) {

    const request = { method: 'PATCH', url, data };

    return await JsonFetch(request);
  }

  static async post(url, data) {

    const request = { method: 'POST', url, data };

    return await JsonFetch(request);
  }

  static async delete(url, query) {

    const request = { method: 'DELETE', url, query };

    return await JsonFetch(request);
  }
}

export default Actions;
