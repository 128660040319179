import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ButtonLoader from '../ButtonLoader';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../../helpers/link-state');

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

let errorTimeout;
class AddEventModal extends Component {
  state = {
    show: false,
    name: '',
    description: '',
    ticketUrl: '',
    
    month: '',
    day: '',
    year: '',
    startTime: '',
    endTime: '',
    
    error: '',
    files: [],
    file: '',
    processing: false,
  }

  handleClose() {
    this.setState({
      show: false,
      name: '',
      description: '',
      ticketUrl: '',
      
      month: '',
      day: '',
      year: '',
      startTime: '',
      endTime: '',
      
      error: '',
      files: [],
      processing: false,
    });
  }

  handleShow() {
    this.setState({
      show: true,
    });
  }

  doAddEvent() {

    if (!this.state.name) {
      return this.setState({
        error: 'Please set a name',
      });
    }

    if (!this.state.month || !this.state.day || !this.state.year) {
      return this.setState({
        error: 'Please set a start time',
      });
    }
    
    if (!this.state.files.length) {
      return this.setState({
        error: 'Please add at least one photo',
      });
    }

    const selectedMonth = this.state.month;
    const selectedDay = this.state.day;
    const selectedYear = this.state.year;
    const selectedStartTime = this.state.startTime || '00:00';
    const selectedEndTime = this.state.endTime || '00:00';

    const startParts = selectedStartTime.split(':');
    const endParts = selectedEndTime.split(':');

    const startHours = parseInt(startParts[0]);
    const startMinutes = parseInt(startParts[1]);

    const endHours = parseInt(endParts[0]);
    const endMinutes = parseInt(endParts[1]);

    const startDate = new Date(parseInt(selectedYear), parseInt(selectedMonth) - 1, parseInt(selectedDay), startHours, startMinutes, 0, 0);
    
    // if endTime < startTime its the next day ...
    let endTimeIsTomorrow = false;
    if (endHours < startHours) {
      endTimeIsTomorrow = true;
    } else if (endHours === startHours) {
      endTimeIsTomorrow = endMinutes < startMinutes;
    }

    const endDate = new Date(startDate.getTime());
    if (endTimeIsTomorrow) {
      endDate.setDate(endDate.getDate() + 1);
    }
    
    endDate.setHours(endHours);
    endDate.setMinutes(endMinutes);

    this.setState({
      processing: true,
    });
    this.props.doAddEvent(this.state.name, this.state.description, this.state.ticketUrl, startDate, endDate, this.state.files, error => {
      if (error) {
        this.setState({
          processing: false,
          error,
        });
      } else {
        this.handleClose();
      }
    });
  }

  onSetFile(evt) {
    if (evt.target.files.length) {
      this.setState({
        files: evt.target.files,
        file: evt.currentTarget.value,
      });
    } else {
      this.setState({
        files: [],
        file: '',
      });
    }
  }

  render() {

    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }


    const buttonText = this.state.processing ? (
      <ButtonLoader />
    ) : (
      <span>Save</span>
    );

    const monthOptions = (
      <>
        <option value="">Select month...</option>
        <option value="1">{monthNames[0]}</option>
        <option value="2">{monthNames[1]}</option>
        <option value="3">{monthNames[2]}</option>
        <option value="4">{monthNames[3]}</option>
        <option value="5">{monthNames[4]}</option>
        <option value="6">{monthNames[5]}</option>
        <option value="7">{monthNames[6]}</option>
        <option value="8">{monthNames[7]}</option>
        <option value="9">{monthNames[8]}</option>
        <option value="10">{monthNames[9]}</option>
        <option value="11">{monthNames[10]}</option>
        <option value="12">{monthNames[11]}</option>
      </>
    );

    const dayOptions = [ <option key="0" value="">Select day...</option> ];

    for (let i = 1; i < 31; i++) {
      dayOptions.push(<option value={i} key={i}>{i}</option>)
    }

    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;

    const yearOptions = (
      <>
        <option value="">Select year...</option>
        <option value={currentYear}>{currentYear}</option>
        <option value={nextYear}>{nextYear}</option>
      </>
    );

    const timeOptions = (
      <>
        <option value="">Select time...</option>
        <option value="00:00">12:00 am</option>
        <option value="00:30">12:30 am</option>
        <option value="01:00">01:00 am</option>
        <option value="01:30">01:30 am</option>
        <option value="02:00">02:00 am</option>
        <option value="02:30">02:30 am</option>
        <option value="03:00">03:00 am</option>
        <option value="03:30">03:30 am</option>
        <option value="04:00">04:00 am</option>
        <option value="04:30">04:30 am</option>
        <option value="05:00">05:00 am</option>
        <option value="05:30">05:30 am</option>
        <option value="06:00">06:00 am</option>
        <option value="06:30">06:30 am</option>
        <option value="07:00">07:00 am</option>
        <option value="07:30">07:30 am</option>
        <option value="08:00">08:00 am</option>
        <option value="08:30">08:30 am</option>
        <option value="09:00">09:00 am</option>
        <option value="09:30">09:30 am</option>
        <option value="10:00">10:00 am</option>
        <option value="10:30">10:30 am</option>
        <option value="11:00">11:00 am</option>
        <option value="11:30">11:30 am</option>
        <option value="12:00">12:00 pm</option>
        <option value="12:30">12:30 pm</option>
        <option value="13:00">01:00 pm</option>
        <option value="13:30">01:30 pm</option>
        <option value="14:00">02:00 pm</option>
        <option value="14:30">02:30 pm</option>
        <option value="15:00">03:00 pm</option>
        <option value="15:30">03:30 pm</option>
        <option value="16:00">04:00 pm</option>
        <option value="16:30">04:30 pm</option>
        <option value="17:00">05:00 pm</option>
        <option value="17:30">05:30 pm</option>
        <option value="18:00">06:00 pm</option>
        <option value="18:30">06:30 pm</option>
        <option value="19:00">07:00 pm</option>
        <option value="19:30">07:30 pm</option>
        <option value="20:00">08:00 pm</option>
        <option value="20:30">08:30 pm</option>
        <option value="21:00">09:00 pm</option>
        <option value="21:30">09:30 pm</option>
        <option value="22:00">10:00 pm</option>
        <option value="22:30">10:30 pm</option>
        <option value="23:00">11:00 pm</option>
        <option value="23:30">11:30 pm</option>
      </>
    );

    return (
      <>
        <button className="btn btn-primary" onClick={this.handleShow.bind(this)}>
          <i className="fa fa-plus"></i>
          Add New
        </button>

        <Modal
          show={this.state.show}
          onHide={this.handleClose.bind(this)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alert}
            <div className="col-12">
              <div className="form-group">
                <label for="EventName">Event Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={this.state.name}
                  onChange={LinkState.bind(this)}
                  className="form-control"
                  placeholder="Event Name..." />
              </div>

              <div className="form-row">
                <div className="col">
                  <label for="eventNonth">Month:</label>
                  <div className="bv-select inline">
                    <select
                      id="month"
                      name="month"
                      value={this.state.month}
                      onChange={LinkState.bind(this)}
                      className="custom-select">
                      <option disabled>Month...</option>
                      {monthOptions}
                    </select>
                    <i className="fa fa-caret-down"></i>
                  </div>
                </div>
                <div className="col">
                  <label for="eventDay">Day:</label>
                  <div className="bv-select inline">
                    <select
                      id="day"
                      name="day"
                      value={this.state.day}
                      onChange={LinkState.bind(this)}
                      className="custom-select">
                      <option disabled>Day...</option>
                      {dayOptions}
                    </select>
                    <i className="fa fa-caret-down"></i>
                  </div>
                </div>
                <div className="col">
                  <label for="eventYear">Year:</label>
                  <div className="bv-select inline">
                    <select
                      id="year"
                      name="year"
                      value={this.state.year}
                      onChange={LinkState.bind(this)}
                      className="custom-select">
                      <option disabled>Year...</option>
                      {yearOptions}
                    </select>
                    <i className="fa fa-caret-down"></i>
                  </div>
                </div>
              </div>
              <div className="form-row mt-3">
                <div className="col">
                  <label for="startTime">Start Time:</label>
                  <div className="bv-select inline">
                    <select
                      id="startTime"
                      name="startTime"
                      value={this.state.startTime}
                      onChange={LinkState.bind(this)}
                      className="custom-select">
                      {timeOptions}
                    </select>
                    <i className="fa fa-caret-down"></i>
                  </div>
                </div>
                <div className="col">
                  <label for="endTime">End Time:</label>
                  <div className="bv-select inline">
                    <select 
                      id="endTime"
                      name="endTime"
                      value={this.state.endTime}
                      onChange={LinkState.bind(this)}
                      className="custom-select">
                      {timeOptions}
                    </select>
                    <i className="fa fa-caret-down"></i>
                  </div>
                </div>
              </div>
              <div className="form-group mt-3">
                <label for="eventDescription">Event Description:</label>
                <textarea
                  id="description"
                  name="description"
                  value={this.state.description}
                  onChange={LinkState.bind(this)}
                  className="form-control height-250"
                  placeholder="Enter a description..."></textarea>
              </div>
              <hr className="mt-5" />
              
              <h4 className="mb-4">Photos</h4>
              <div className="form-group">
                <input
                  type="file"
                  id="file"
                  name="file"
                  multiple="multiple"
                  value={this.state.file}
                  onChange={this.onSetFile.bind(this)}
                  className="form-control" />
              </div>


              {/*<h4 className="mb-4">Photos</h4>
              <div className="row photo-gallery">
                <div className="col-lg-3 col-md-4 col-6 photo">
                  <img className="img-fluid img-thumbnail mb-4" src="https://source.unsplash.com/pWkk7iiCoDM/400x300" alt="" />
                  <button className="close-button">
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <div className="col-lg-3 col-md-4 col-6 photo">
                  <img className="img-fluid img-thumbnail mb-4" src="https://source.unsplash.com/aob0ukAYfuI/400x300" alt="" />
                  <button className="close-button">
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <div className="col-lg-3 col-md-4 col-6 mb-4 photo">
                  <img className="img-fluid img-thumbnail" src="https://source.unsplash.com/EUfxH-pze7s/400x300" alt="" />
                  <button className="close-button">
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <div className="col-lg-3 col-md-4 col-6 mb-4 photo">
                  <img className="img-fluid img-thumbnail" src="https://source.unsplash.com/M185_qYH8vg/400x300" alt="" />
                  <button className="close-button">
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <div className="col-lg-3 col-md-4 col-6 mb-4 photo">
                  <img className="img-fluid img-thumbnail" src="https://source.unsplash.com/sesveuG_rNo/400x300" alt="" />
                  <button className="close-button">
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <div className="col-lg-3 col-md-4 col-6 mb-4 photo">
                  <img className="img-fluid img-thumbnail" src="https://source.unsplash.com/AvhMzHwiE_0/400x300" alt="" />
                  <button className="close-button">
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <div className="col-lg-3 col-md-4 col-6 mb-4 photo">
                  <img className="img-fluid img-thumbnail" src="https://source.unsplash.com/2gYsZUmockw/400x300" alt="" />
                  <button className="close-button">
                    <i className="fa fa-times"></i>
                  </button>
                </div>
                <div className="col-12">
                  <button className="btn btn-secondary">
                    <i className="fa fa-upload"></i>
                    Upload Photo
                  </button>
                </div>
              </div>*/}
              <hr className="mt-5" />
              <h2 className="mb-4">Purchase Ticket Link (Optional)</h2>
              <small>Enter a URL below where users can purchase tickets for this event.</small>
              <div className="form-group">
                <input
                  type="text"
                  id="ticketUrl"
                  name="ticketUrl"
                  value={this.state.ticketUrl}
                  onChange={LinkState.bind(this)}
                  className="form-control mt-3"
                  placeholder="URL..." />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="ghost" onClick={this.handleClose.bind(this)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.doAddEvent.bind(this)}>
              {buttonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default (AddEventModal);