import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ReactGoogleAutocomplete from "react-google-autocomplete";
import './ZipModal.scss';
const LinkState = require('../../helpers/link-state');


class ZipModal extends Component {
  state = {
    show: false,
    zip: '',
    location: '',
    filter_city: '',
    filter_state: ''
  }

  handleClose() {
    this.setState({
      show: false,
    });
  }

  handleShow() {
    this.setState({
      show: true,
    });
  }

  doSetZip() {
    let city = this.state.filter_city !== "" ? this.state.filter_city : ""
    let State = this.state.filter_state !== "" ? this.state.filter_state : ""
    let zipcode = this.state.zip !== "" ? this.state.zip : ""
    this.handleClose();
    this.props.zipChanged(zipcode,city,State);
    // this.props.cityChanged(this.state.city)
    // this.props.stateChanged(this.state.state)
    localStorage.setItem('zip', this.state.zip);
  }

  compIsType(t, s) {
    for (let z = 0; z < t.length; ++z) if (t[z] == s) return true;

    return false;
  }

  handleZipcode(place){
    let lat,
      lng,
      addrSel,
      placeName,
      placeId = "";
    let country,
      state,
      city = null;
    if (place.geometry !== undefined) {
      const plcGeom = place.geometry;
      if (plcGeom.location !== undefined) {
        lat = plcGeom.location ? plcGeom.location.lat() : "";
        lng = plcGeom.location ? plcGeom.location.lng() : "";
      }
    }

    addrSel =
      place.formatted_address !== undefined ? place.formatted_address : "";
    placeName = place.name !== undefined ? place.name : "";
    placeId = place.place_id !== undefined ? place.place_id : "";

    if (place.address_components !== undefined) {
      let addrComp = place.address_components;
      for (let i = 0; i < addrComp.length; ++i) {
        var typ = addrComp[i].types;
        if (this.compIsType(typ, "administrative_area_level_1"))
          state = addrComp[i].short_name; //store the state
        else if (this.compIsType(typ, "locality")) city = addrComp[i].long_name; //store the city

        // else if(this.compIsType(typ, 'country'))
        //   country = addrComp[i].long_name; //store the country

        //we can break early if we find all three data
        if (state != null && city != null && country != null) break;
      }
    }

    this.setState({
      filter_state: state,
      filter_city: city,
    });
  }

  render() {
    return (
      <>
        <Button variant="link" onClick={this.handleShow.bind(this)}>
          Search Location
        </Button>

        <Modal
          show={this.state.show}
          onHide={this.handleClose.bind(this)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Search Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Enter your location below:</p>
            <ReactGoogleAutocomplete
              id="zip"
              name="zip"
              placeholder="Enter a city or state name here"
              className="form-control"
              place
              // value={this.state.location}
              // onChange={(event)=> this.setState({location: event.target.value})}
              textinputprops={{
                placeholderTextColor: "#32a852",
                returnKeyType: "search",
              }}
              style={{ zIndex: "9999" }}
              options={{
                types: ["(regions)"],
                componentRestrictions: { country: "US" },
              }}
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              onPlaceSelected={(place) => this.handleZipcode(place)}
            />
            {/* <input
              id="zip"
              name="zip"
              value={this.state.zip}
              onChange={LinkState.bind(this)}
              className="form-control"
              type="text" /> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="ghost" onClick={this.handleClose.bind(this)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.doSetZip.bind(this)}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default (ZipModal);