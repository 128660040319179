import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import './Admin.scss';
import { Link } from "react-router-dom";
import AdminChart from '../components/AdminChart/AdminChart';
import AdminSidebar from '../components/AdminSidebar';
import { LinkContainer } from 'react-router-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import User from '../img/user.jpg';
import { Redirect } from "react-router-dom";
import Sticky from 'react-stickynode';

import FullPageLoader from '../components/FullPageLoader';
import Actions from '../actions/api';
import ButtonLoader from '../components/ButtonLoader';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../helpers/link-state');

const pageSize = 21;
export default class AdminVenues extends Component {
  state = {
    redirect: '',
    error: '',
    success: '',
    bars: null,
    processing: true,
    saving: false,

    pageIndex: 0,
    numberOfPages: 0,
    sort: 'featured',
    search: '',
    zip_code: localStorage.getItem('zip') || '',
  }

  componentDidMount() {
    this.getBars(0);

    window.bvUser.admin_bar_id = null;
    if (localStorage) {
      localStorage.removeItem('admin_bar_id');
    }
  }

  zipChanged(zip_code) {
    this.getBars(0, null, zip_code, null);
  }

  onSearchKeyDown(evt) {
    // trigger search on enter
    if (evt.key === 'Enter') {
      this.getBars(0);
    }
  }

  sortBy(sort) {
    this.getBars(0, null, null, sort);
  }

  doAddVenue() {
    document.location.href = '/admin/venue/add';
  }

  async deleteVenue(bar) {
    if (window.confirm(`Are you sure you want to delete "${bar.name}"?`)) {
      try {
        await Actions.delete(`bars/admin/bars/${bar.id}/`, {
          deleted: true,
        });

        const bars = this.state.bars;
        this.setState({
          bars: bars.filter(b => b.id !== bar.id),
          error: '',
          success: 'Venue deleted!',
        });
      } catch (ex) {
        this.setState({
          success: '',
          error: 'Failed to delete. Please try again!',
        });
      }
    }
  }

  async loadPage(pageIndex, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    await this.getBars(pageIndex);
  }

  async getBars(pageIndex, search, zip_code, sort) {
    
    const st = {
      processing: true,
      bars: null,
      sort: this.state.sort,
      search: this.state.search,
      zip_code: this.state.zip_code,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };

    if (search) {
      st.search = search;
    }

    if (zip_code) {
      st.zip_code = zip_code;
    }

    if (sort) {
      st.sort = sort;
    }

    this.setState(st);

    const params = { 
      offset: pageIndex * pageSize,
      limit: pageSize,
      within_distance_mi: 'None' // No distance filter
    };

    if (st.zip_code) {
      params.zip_code = st.zip_code;
    }

    if (st.search) {
      params.search = st.search;
    }

    if (st.sort) {
      params.sort = st.sort;
    }

    
    Actions.get('bars/', params).then(results => {
      const bars = results.results || [];
      const numberOfPages = Math.ceil(results.count / pageSize);
      
      this.setState({
        bars,
        processing: false,
        numberOfPages,
        pageIndex,
      });
    }).catch(ex => {
      this.setState({
        bars: [],
        processing: false,
      });
    });
  }


  render () {

    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let alert = null;

    const {
      processing,
      bars,
      success,
      error,
    } = this.state;

    if (success) {
      alert = (
        <Alert variant="success">
          {success}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          success: '',
        });
      }, 3e3);
    }
    else if (error) {
      alert = (
        <Alert variant="danger">
          {error}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    if (processing) {
      return <FullPageLoader/>;
    }

    let barsTable = null;

    if (bars && bars.length) {

      const rows = bars.map(u => {
        return (
          <tr key={u.id}>
            <td>
              <a target="_blank" href={'/venue/' + u.slug}>{u.name}</a>
            </td>
            <td>
              {u.address ? u.address.street_address : ''}<br/>
              {u.address ? (`${u.address.locality}, ${u.address.region}`) : ''}
            </td>
            <td>
              { u.is_managed ? 'Yes' : 'No' }
            </td>
            <td className="text-right">
              <ul className="action-buttons">
                <li>
                  <button 
                    type="button"
                    className="btn btn-secondary" 
                    onClick={ () => this.deleteVenue(u) }>
                    Delete
                  </button>
                </li>
                <li>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      Edit
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href={'/admin?slug=' + u.slug}>Dashboard</Dropdown.Item>
                      <Dropdown.Item href={'/admin/venue?slug=' + u.slug}>Venue Info</Dropdown.Item>
                      <Dropdown.Item href={'/admin/camera?slug=' + u.slug}>Camera</Dropdown.Item>
                      <Dropdown.Item href={'/admin/events?slug=' + u.slug}>Events</Dropdown.Item>
                      <Dropdown.Item href={'/admin/specials?slug=' + u.slug}>Specials</Dropdown.Item>
                      <Dropdown.Item href={'/admin/users?slug=' + u.slug}>Users</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </td>
          </tr>
        );
      });

      barsTable = (
        <table className="table bv-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Location</th>
              <th>Is Managed</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      );
    } else {
      barsTable = (
        <div className="col-12">
          <div className="no-results">
            <p>No venues found</p>
          </div>
        </div>
      );
    }

    let selectedSort = 'Featured';

    if (this.state.sort === 'alphabetical') {
      selectedSort = 'Alphabetical';      
    } else if (this.state.sort === 'rating') {
      selectedSort = 'Rating';
    } else if (this.state.sort === 'distance') {
      selectedSort = 'Distance';
    }

    // pagination
    let pagination = null;
    const numberOfPages = this.state.numberOfPages || 1;

    if (numberOfPages > 1) {
        
      const previous = this.state.pageIndex > 0 ? (
        <li className="page-item">
          <a className="page-link" href="#" onClick={(event) => this.loadPage(this.state.pageIndex - 1, event)}>
            <i className="fa fa-chevron-left"></i>
          </a>
        </li>
      ) : null;

      let pages = [];
      var hasSkipped = false;
      const curPageDelta = 2;
      for (let i = 0; i < numberOfPages; i++) {
        const isCurrentPage = i === this.state.pageIndex;
        const isCloseToCurrentPage = i > this.state.pageIndex - curPageDelta && i < this.state.pageIndex + curPageDelta;
        const classes = isCurrentPage ? 'page-link active' : 'page-link';


        // if we have many pages, skip the middle few ...
        if (!isCurrentPage && !isCloseToCurrentPage && numberOfPages > 5 && i > curPageDelta && i < numberOfPages - curPageDelta) {
          if (!hasSkipped) {
            pages.push(
              <li className="page-item gap" key={i}>
                ...
              </li>
            );
          }
          hasSkipped = true;
          continue;
        } else {
          if (this.state.pageIndex === i) {
            // allow another gap to show up
            hasSkipped = false;
          }
          pages.push(
            <li className="page-item" key={i}>
              <a className={classes} href="#" onClick={(event) => this.loadPage(i, event)}>
                {i+1}
              </a>
            </li>
          );
        }
      }

      const next = (this.state.pageIndex + 1) < numberOfPages ? (
        <li className="page-item">
          <a className="page-link" href="#" onClick={(event) => this.loadPage(this.state.pageIndex + 1, event)}>
            <i className="fa fa-chevron-right"></i>
          </a>
        </li>
      ) : null;

      pagination = (
        <div className="row">
          <div className="col-12">
            <nav>
              <ul className="pagination">
                {previous}
                {pages}
                {next}
              </ul>
            </nav>
          </div>
        </div>
      );
    }

    return (
      <div className="admin container-fluid">
        {alert}
        <div className="admin-sidebar">
          <div className="d-none d-lg-block">
            <Sticky enabled={true} top={100}>
              <AdminSidebar />
            </Sticky>
          </div>
          <div className="d-lg-none">
            <AdminSidebar />
          </div>
        </div>
        <div className="admin-content">
          <div className="row admin-header">
            <div className="col-12 col-md-5">
              <h2>Venues</h2>             
            </div>

            <div className="col-12 col-md-7 admin-search condensed">
              <input
                type="search"
                id="search"
                name="search"
                value={this.state.search}
                onChange={LinkState.bind(this)}
                onKeyDown={this.onSearchKeyDown.bind(this)}
                placeholder="Search by Name, Description"
                className="form-control"
              />
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  Sort<span className="d-none d-lg-inline">: {selectedSort}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => this.sortBy('featured')}>Featured</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => this.sortBy('alphabetical')}>Alphabetical</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => this.sortBy('rating')}>Rating</Dropdown.Item>
                  <Dropdown.Item onClick={(e) => this.sortBy('distance')}>Distance</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="admin-add-button">
                <button 
                  type="button"
                  className="btn btn-primary" 
                  onClick={this.doAddVenue.bind(this)}>
                  <i className="fa fa-plus"></i>
                  Add New
                </button>
              </div>
            </div>

            <div className="col-12">
              <hr />
            </div>
            <div className="col-12">
              {barsTable}
            </div>
          </div>
          {pagination}
        </div>
      </div>
    )
  }
}