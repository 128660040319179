import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Card from './Card';
import ButtonLoader from './ButtonLoader';
import { LinkContainer } from 'react-router-bootstrap';
import User from '../img/user.jpg';
import {Form, FormControl, FormGroup, ControlLabel, HelpBlock, Checkbox, Radio, Button} from 'react-bootstrap';
const LinkState = require('../helpers/link-state');
const otherTypeText = 'Other (Please Specify)';

const BAR_TYPES = ['Sports Bars', 'Dance Clubs', 'Brew Pubs', 'Live Music Taverns', 'Restaurants', otherTypeText];
const BAR_ACTIVITIES = ['Air Hockey', 'Arcade', 'Archery', 'Arts and Crafts – Painting', 'Bar Olympics', 'Basketball', 'Beat the Bartender', 'Bowling', 'Canjam', 'Cornhole', 'Darts', 'Dice Games', 'Dodgeball', 'Fantasy Leagues', 'Flip Cup', 'Foosball', 'Football', 'Karaoke', 'Kickball', 'Poker', 'Pool', 'Shuffleboard', 'Soccer', 'Social Board Games', 'Softball', 'Throwing Axes', 'Trivia', 'Video Games', otherTypeText];
const MUSIC_TYPES = ['Rock', 'Pop', 'Techno', 'House', 'Latin', 'Jazz', 'Hip-Hop/R&B', 'Blues', 'Country', 'Classical', 'Funk', 'Disco', 'Dubstep', otherTypeText];

const _lookForOtherValue = (options, currentValues) => {
  const valuesNotInOptions = currentValues.filter(val => options.indexOf(val) === -1);
  if (valuesNotInOptions.length) {
    return valuesNotInOptions[0];
  }
  return '';
};

export default class AccountOnboardingFields extends Component {
  state = {
    processing: false,

    favorite_bar_types: this.props.questionnaire.favorite_bar_types && this.props.questionnaire.favorite_bar_types.length ? this.props.questionnaire.favorite_bar_types : [''],
    favorite_drinks: this.props.questionnaire.favorite_drinks && this.props.questionnaire.favorite_drinks.length ? this.props.questionnaire.favorite_drinks : [''],
    favorite_beers: this.props.questionnaire.favorite_beers && this.props.questionnaire.favorite_beers.length ? this.props.questionnaire.favorite_beers : [''],
    favorite_foods: this.props.questionnaire.favorite_foods && this.props.questionnaire.favorite_foods.length ? this.props.questionnaire.favorite_foods : [''],
    favorite_activities_leagues: this.props.questionnaire.favorite_activities_leagues && this.props.questionnaire.favorite_activities_leagues.length ? this.props.questionnaire.favorite_activities_leagues : [''],
    favorite_event_types: this.props.questionnaire.favorite_event_types && this.props.questionnaire.favorite_event_types.length ? this.props.questionnaire.favorite_event_types : [''],
    favorite_music_types: this.props.questionnaire.favorite_music_types && this.props.questionnaire.favorite_music_types.length ? this.props.questionnaire.favorite_music_types : [''],

    barTypeChk_other: _lookForOtherValue(BAR_TYPES, this.props.questionnaire.favorite_bar_types && this.props.questionnaire.favorite_bar_types.length ? this.props.questionnaire.favorite_bar_types : ['']),
    barActivitiesChk_other: _lookForOtherValue(BAR_ACTIVITIES, this.props.questionnaire.favorite_activities_leagues && this.props.questionnaire.favorite_activities_leagues.length ? this.props.questionnaire.favorite_activities_leagues : ['']),
    barTypeMusicChk_other: _lookForOtherValue(MUSIC_TYPES, this.props.questionnaire.favorite_music_types && this.props.questionnaire.favorite_music_types.length ? this.props.questionnaire.favorite_music_types : ['']),
  }

  saveOnboarding(event) {

    this.setState({
      processing: true,
    });

    const {
      favorite_bar_types,
      favorite_drinks,
      favorite_beers,
      favorite_foods,
      favorite_activities_leagues,
      favorite_event_types,
      favorite_music_types,
    } = this.state;


    if (favorite_bar_types.indexOf(otherTypeText) !== -1) {
      favorite_bar_types.push(this.state.barTypeChk_other);
    }

    if (favorite_activities_leagues.indexOf(otherTypeText) !== -1) {
      favorite_activities_leagues.push(this.state.barActivitiesChk_other);
    }

    if (favorite_music_types.indexOf(otherTypeText) !== -1) {
      favorite_music_types.push(this.state.barTypeMusicChk_other);
    }


    this.props.doSaveOnboarding({
      favorite_bar_types: favorite_bar_types.filter(v => !!v.length && v !== otherTypeText),
      favorite_drinks: favorite_drinks.filter(v => !!v.length && v !== otherTypeText),
      favorite_beers: favorite_beers.filter(v => !!v.length && v !== otherTypeText),
      favorite_foods: favorite_foods.filter(v => !!v.length && v !== otherTypeText),
      favorite_activities_leagues: favorite_activities_leagues.filter(v => !!v.length && v !== otherTypeText),
      favorite_event_types: favorite_event_types.filter(v => !!v.length && v !== otherTypeText),
      favorite_music_types: favorite_music_types.filter(v => !!v.length && v !== otherTypeText),
    }, () => {
      this.setState({
        processing: false,
      });
    });
  }

  toggleCheckbox(key, val) {
    let collection = this.state[key];

    const newState = {};

    if (collection.indexOf(val) === -1) {
      collection.push(val);
    } else {
      collection = collection.filter(f => f !== val);

      // check if we should clear the "other" value...
      if (val === otherTypeText) {
        if (key === 'favorite_bar_types') {
          newState.barTypeChk_other = '';
        } else if (key === 'favorite_activities_leagues') {
          newState.barActivitiesChk_other = '';
        } else if (key === 'favorite_music_types') {
          newState.barTypeMusicChk_other = '';
        }
      }
    }

    newState[key] = collection;
    this.setState(newState);
  }

  setTextValue(uniqueId, key, value) {
    let collection = this.state[key];

    const index = parseInt(uniqueId.split('_')[1]);

    collection[index] = value || '';

    const newState = {};
    newState[key] = collection;
    this.setState(newState);
  }

  doRemove(uniqueId, key) {
    let collection = this.state[key];
    const index = parseInt(uniqueId.split('_')[1]);
    collection.splice(index, 1);

    const newState = {};
    newState[key] = collection.length ? collection : [''];
    this.setState(newState);
  }

  doAddAnother(key) {
    let collection = this.state[key];
    collection.push('');

    const newState = {};
    newState[key] = collection;
    this.setState(newState);
  }

  render () {

    const buttonText = this.state.processing ? (
      <ButtonLoader/>
    ) : (
      <span>Save Profile</span>
    );

    if (this.props.displayOnly) {

      let favoriteTypesOfBars = null;
      
      const favorite_bar_types = this.state.favorite_bar_types.filter(v => !!v);
      if (favorite_bar_types.length) {

        let index = 0;
        const favoriteTypes = favorite_bar_types.map(fav => {
          return (
            <tr key={index++}>
              <td>{fav}</td>
            </tr>
          );
        });

        favoriteTypesOfBars = (
          <>
            <h4>Favorite Types of Bars/Clubs:</h4>
            <br />
            <table className="table">
              <tbody>
                {favoriteTypes}
              </tbody>
            </table>
            <hr className="white margin"/>
          </>
        );
      }

      let favoriteDrinks = null;

      const favorite_drinks = this.state.favorite_drinks.filter(v => !!v);
      if (favorite_drinks.length) {
        let index = 0;
        const favoriteTypes = favorite_drinks.map(fav => {
          return (
            <tr key={index++}>
              <td>{fav}</td>
            </tr>
          );
        });

        favoriteDrinks = (
          <>
            <h4>Favorite Mixed Drinks:</h4>
            <br />
            <table className="table">
              <tbody>
                {favoriteTypes}
              </tbody>
            </table>
            <hr className="white margin"/>
          </>
        );
      }

      let favoriteBeers = null;

      const favorite_beers = this.state.favorite_beers.filter(v => !!v);
      
      if (favorite_beers.length) {
        let index = 0;
        const favoriteTypes = favorite_beers.map(fav => {
          return (
            <tr key={index++}>
              <td>{fav}</td>
            </tr>
          );
        });

        favoriteBeers = (
          <>
            <h4>Favorite Beers:</h4>
            <br />
            <table className="table">
              <tbody>
                {favoriteTypes}
              </tbody>
            </table>
            <hr className="white margin"/>
          </>
        );
      }

      let favoriteFoods = null;

      const favorite_foods = this.state.favorite_foods.filter(v => !!v);
      if (favorite_foods.length) {
        let index = 0;
        const favoriteTypes = favorite_foods.map(fav => {
          return (
            <tr key={index++}>
              <td>{fav}</td>
            </tr>
          );
        });

        favoriteFoods = (
          <>
            <h4>Favorite Foods:</h4>
            <br />
            <table className="table">
              <tbody>
                {favoriteTypes}
              </tbody>
            </table>
            <hr className="white margin"/>
          </>
        );
      }

      let favoriteActivities = null;

      const favorite_activities_leagues = this.state.favorite_activities_leagues.filter(v => !!v);
      if (favorite_activities_leagues.length) {

        let index = 0;
        const favoriteTypes = favorite_activities_leagues.map(fav => {
          return (
            <tr key={index++}>
              <td>{fav}</td>
            </tr>
          );
        });

        favoriteActivities = (
          <>
            <h4>Favorite Types of Bar Activities:</h4>
            <br />
            <table className="table">
              <tbody>
                {favoriteTypes}
              </tbody>
            </table>
            <hr className="white margin"/>
          </>
        );
      }

      let favoriteEvents = null;

      const favorite_event_types = this.state.favorite_event_types.filter(v => !!v);
      if (favorite_event_types.length) {

        let index = 0;
        const favoriteTypes = favorite_event_types.map(fav => {
          return (
            <tr key={index++}>
              <td>{fav}</td>
            </tr>
          );
        });

        favoriteEvents = (
          <>
            <h4>Favorite Types of Events:</h4>
            <br />
            <table className="table">
              <tbody>
                {favoriteTypes}
              </tbody>
            </table>
            <hr className="white margin"/>
          </>
        );
      }

      let favoriteTypesOfMusic = null;

      const favorite_music_types = this.state.favorite_music_types.filter(v => !!v);
      if (favorite_music_types.length) {

        let index = 0;
        const favoriteTypes = favorite_music_types.map(fav => {
          return (
            <tr key={index++}>
              <td>{fav}</td>
            </tr>
          );
        });

        favoriteTypesOfMusic = (
          <>
            <h4>Favorite Types of Music:</h4>
            <br />
            <table className="table">
              <tbody>
                {favoriteTypes}
              </tbody>
            </table>
            <hr className="white margin"/>
          </>
        );
      }

      return (
        <Card>
          {favoriteTypesOfBars}
          {favoriteDrinks}
          {favoriteBeers}
          {favoriteFoods}
          {favoriteActivities}
          {favoriteEvents}
          {favoriteTypesOfMusic}
        </Card>
      );
    }

    let index = 0;
    const barTypeOptions = BAR_TYPES.map(label => {
      const uniqueId = `barTypeChk_${index++}`;
      return (
        <Form.Check
          key={uniqueId}
          id={uniqueId}
          name={uniqueId}
          type="checkbox"
          checked={ this.state.favorite_bar_types.indexOf(label) !== -1 || (label === otherTypeText && this.state.barTypeChk_other.length) }
          onChange={ () => this.toggleCheckbox('favorite_bar_types', label) }
          label={label}
        />
      );
    });

    const barTypeOtherTextInput = this.state.favorite_bar_types.indexOf(otherTypeText) !== -1 || this.state.barTypeChk_other.length ? (
      <div className="form-group">
        <br/>
        <input
          type="text"
          id="barTypeChk_other"
          name="barTypeChk_other"
          value={this.state.barTypeChk_other}
          onChange={LinkState.bind(this)}
          className="form-control" />
      </div>
    ) : null;


    index = 0;
    const barActivitiesOptions = BAR_ACTIVITIES.map(label => {
      const uniqueId = `barActivitiesChk_${index++}`;
      return (
        <Form.Check
          key={uniqueId}
          id={uniqueId}
          name={uniqueId}
          type="checkbox"
          checked={ this.state.favorite_activities_leagues.indexOf(label) !== -1 || (label === otherTypeText && this.state.barActivitiesChk_other.length) }
          onChange={ () => this.toggleCheckbox('favorite_activities_leagues', label) }
          label={label}
        />
      );
    });

    const barActivitiesOtherTextInput = this.state.favorite_activities_leagues.indexOf(otherTypeText) !== -1 || this.state.barActivitiesChk_other.length ? (
      <div className="form-group">
        <br/>
        <input
          type="text"
          id="barActivitiesChk_other"
          name="barActivitiesChk_other"
          value={this.state.barActivitiesChk_other}
          onChange={LinkState.bind(this)}
          className="form-control" />
      </div>
    ) : null;


    index = 0;
    const barTypeMusicOptions = MUSIC_TYPES.map(label => {
      const uniqueId = `barTypeMusicChk_${index++}`;
      return (
        <Form.Check
          key={uniqueId}
          id={uniqueId}
          name={uniqueId}
          type="checkbox"
          checked={ this.state.favorite_music_types.indexOf(label) !== -1 || (label === otherTypeText && this.state.barTypeMusicChk_other.length) }
          onChange={ () => this.toggleCheckbox('favorite_music_types', label) }
          label={label}
        />
      );
    });

    const barTypeMusicOtherTextInput = this.state.favorite_music_types.indexOf(otherTypeText) !== -1 || this.state.barTypeMusicChk_other.length ? (
      <div className="form-group">
        <br/>
        <input
          type="text"
          id="barTypeMusicChk_other"
          name="barTypeMusicChk_other"
          value={this.state.barTypeMusicChk_other}
          onChange={LinkState.bind(this)}
          className="form-control" />
      </div>
    ) : null;

    index = 0;
    // const favoriteBarInputs = this.state.favorite_bars.map(b => {
    //   const uniqueId = `barFavTxt_${index++}`;

    //   return (
    //     <div className="input-with-remove" key={uniqueId}>
    //       <input
    //         id={uniqueId}
    //         name={uniqueId}
    //         value={ b }
    //         onChange={ (event) => this.setTextValue(uniqueId, 'favorite_bars', event.currentTarget.value) }
    //         className="form-control" />
    //       <button
    //         type="button"
    //         onClick={(event) => this.doRemove(uniqueId, 'favorite_bars') }
    //         className="btn input-remove">
    //         <i className="fa fa-times"></i>
    //       </button>
    //     </div>
    //   );
    // });

    // favorite_drinks

    index = 0;
    const favoriteDrinksInputs = this.state.favorite_drinks.map(b => {
      const uniqueId = `barFavDrinksTxt_${index++}`;

      return (
        <div className="input-with-remove" key={uniqueId}>
          <input
            id={uniqueId}
            name={uniqueId}
            value={ b }
            onChange={ (event) => this.setTextValue(uniqueId, 'favorite_drinks', event.currentTarget.value) }
            className="form-control" />
          <button
            type="button"
            onClick={(event) => this.doRemove(uniqueId, 'favorite_drinks') }
            className="btn input-remove">
            <i className="fa fa-times"></i>
          </button>
        </div>
      );
    });

    // favorite_beers

    index = 0;
    const favoriteBeersInputs = this.state.favorite_beers.map(b => {
      const uniqueId = `barFavBeersTxt_${index++}`;

      return (
        <div className="input-with-remove" key={uniqueId}>
          <input
            id={uniqueId}
            name={uniqueId}
            value={ b }
            onChange={ (event) => this.setTextValue(uniqueId, 'favorite_beers', event.currentTarget.value) }
            className="form-control" />
          <button
            type="button"
            onClick={(event) => this.doRemove(uniqueId, 'favorite_beers') }
            className="btn input-remove">
            <i className="fa fa-times"></i>
          </button>
        </div>
      );
    });

    // favorite_foods

    index = 0;
    const favoriteFoodsInputs = this.state.favorite_foods.map(b => {
      const uniqueId = `barFavFoodsTxt_${index++}`;

      return (
        <div className="input-with-remove" key={uniqueId}>
          <input
            id={uniqueId}
            name={uniqueId}
            value={ b }
            onChange={ (event) => this.setTextValue(uniqueId, 'favorite_foods', event.currentTarget.value) }
            className="form-control" />
          <button
            type="button"
            onClick={(event) => this.doRemove(uniqueId, 'favorite_foods') }
            className="btn input-remove">
            <i className="fa fa-times"></i>
          </button>
        </div>
      );
    });

    // favorite_event_types

    index = 0;
    const favoriteEventsInputs = this.state.favorite_event_types.map(b => {
      const uniqueId = `barFavEventsTxt_${index++}`;

      return (
        <div className="input-with-remove" key={uniqueId}>
          <input
            id={uniqueId}
            name={uniqueId}
            value={ b }
            onChange={ (event) => this.setTextValue(uniqueId, 'favorite_event_types', event.currentTarget.value) }
            className="form-control" />
          <button
            type="button"
            onClick={(event) => this.doRemove(uniqueId, 'favorite_event_types') }
            className="btn input-remove">
            <i className="fa fa-times"></i>
          </button>
        </div>
      );
    });

    // favorite_musical_artists

    // index = 0;
    // const favoriteMusicInputs = this.state.favorite_musical_artists.map(b => {
    //   const uniqueId = `barFavMusicTxt_${index++}`;

    //   return (
    //     <div className="input-with-remove" key={uniqueId}>
    //       <input
    //         id={uniqueId}
    //         name={uniqueId}
    //         value={ b }
    //         onChange={ (event) => this.setTextValue(uniqueId, 'favorite_musical_artists', event.currentTarget.value) }
    //         className="form-control" />
    //       <button
    //         type="button"
    //         onClick={(event) => this.doRemove(uniqueId, 'favorite_musical_artists') }
    //         className="btn input-remove">
    //         <i className="fa fa-times"></i>
    //       </button>
    //     </div>
    //   );
    // });

    return (
      <>
        <div className="form-group">
          <label className="mb-0">What Types of Bars/Clubs are your favorite to go out to?</label>
          <br />
          <small className="d-block mb-4">Check all that apply</small>
          <Form>
            <div className="mb-3">
              {barTypeOptions}
              {barTypeOtherTextInput}
            </div>
          </Form>
        </div>
        <hr />
        {/*<div className="form-group">
          <label>What are your favorite bars & restaurants?</label>
          {favoriteBarInputs}
          <button
            type="button"
            onClick={() => this.doAddAnother('favorite_bars')}
            className="btn btn-blank">
            <i className="fa fa-plus"></i>
            Add Another
          </button>
        </div>*/}
        <div className="form-group">
          <label>What are your favorite mixed drinks?</label>
          {favoriteDrinksInputs}
          <button
            type="button"
            onClick={() => this.doAddAnother('favorite_drinks')}
            className="btn btn-blank">
            <i className="fa fa-plus"></i>
            Add Another
          </button>
        </div>
        <div className="form-group">
          <label>What are your favorite beers?</label>
          {favoriteBeersInputs}
          <button
            type="button"
            onClick={() => this.doAddAnother('favorite_beers')}
            className="btn btn-blank">
            <i className="fa fa-plus"></i>
            Add Another
          </button>
        </div>
        <div className="form-group">
          <label>What are your favorite foods?</label>
          {favoriteFoodsInputs}
          <button
            type="button"
            onClick={() => this.doAddAnother('favorite_foods')}
            className="btn btn-blank">
            <i className="fa fa-plus"></i>
            Add Another
          </button>
        </div>
        <div className="form-group">
          <label className="mb-0">What Types of Bar Activities or Leagues are you interested in?</label>
          <br />
          <small className="d-block mb-4">Check all that apply</small>
          <Form>
            <div className="mb-3">
              {barActivitiesOptions}
              {barActivitiesOtherTextInput}
            </div>
          </Form>
        </div>
        <hr />
        <div className="form-group">
          <label>What are your favorite types of events?</label>
          {favoriteEventsInputs}
          <button
            type="button"
            onClick={() => this.doAddAnother('favorite_event_types')}
            className="btn btn-blank">
            <i className="fa fa-plus"></i>
            Add Another
          </button>
        </div>
        <hr />
        <div className="form-group">
          <label className="mb-0">Which types of music do you enjoy hearing while attending Bars & Nightclubs? </label>
          <br />
          <small className="d-block mb-4">Check all that apply</small>
          <Form>
            <div className="mb-3">
              {barTypeMusicOptions}
              {barTypeMusicOtherTextInput}
            </div>
          </Form>
        </div>
        <hr />
        {/*<div className="form-group">
          <label>Who are your favorite musical artists?</label>
          {favoriteMusicInputs}
          <button
            type="button"
            onClick={() => this.doAddAnother('favorite_musical_artists')}
            className="btn btn-blank">
            <i className="fa fa-plus"></i>
            Add Another
          </button>
        </div>
        <br />*/}
        <button
          onClick={ this.saveOnboarding.bind(this) }
          type="submit"
          className="btn btn-primary">{buttonText}</button>
      </>
    );
  }
}