import React, { Component } from 'react';
import Actions from '../actions/api';
import { Redirect } from "react-router-dom";

export default class Confirm extends Component {
  state = {
    redirect: '',
  }

  componentDidMount() {
    const token = this.props.match.params.id;

    Actions.post('users/confirm_email/', {
      confirmation_token: token,
    }).then(response => {
      
      localStorage.setItem('userAccess', response.access);
      localStorage.setItem('userRefresh', response.refresh);
      
      Actions.get('users/me/').then(user => {
        window.bvUser = user;
        window.location.href = '/account';
      }).catch(ex => {
        // invalid token ... 
        // localStorage.clear();
        this.setState({
          redirect: '/venues',
          processing: false,
        });
      });
    }).catch(ex => {
      window.bvConfirmFailed = true;
      this.setState({
        redirect: '/',
      });
    });
  }

  render () {
    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }
    
    return (<div/>);
  }
}