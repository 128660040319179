import React, { Component } from 'react';
import './About.scss';
import { Link } from "react-router-dom";
import Card from '../components/Card';
import OldLogo from '../img/old-bv-logo.png';

export default class About extends Component {
  state = {
  }


  render () {
    return (
      <div className="about container">
        <div className="row">
          <div className="col-12 white-b">
            <h2 class="mb-4">About BarView</h2>
            <div className="card no-hover">
              <p><strong>COMPANY MISSION:</strong></p>
              <ul>
              <li>Our Mission is to provide an application platform to enhance the entertainment industry such as bars and restaurants.We bridge the gap between people, technology, and business.BarView&rsquo;s innovative platform will catapult businesses by allowing them to showcase their establishments through virtual feeds, thereby creating customer awareness.Current and prospective customers will be able to gain insight of all the important operations of the business (Events, Specials, Live Music, Activities, and more).</li>
              </ul>
              <p><strong>COMPANY VISION:</strong></p>
              <ul>
              <li>Our vision is to build an organization in which we will become the most dependable brand for supplying the technology to support our clients&rsquo; objectives for the bar and restaurant industry.We will offer excellent service to our clients so that they may achieve their business success.Our application service will become a leading performer in the competitive global marketplace.</li>
              </ul>
              <p><strong>What is BarView:</strong></p>
              <p>BarView is an application platform that connects users to your business directly.&nbsp;</p>
              <p>Each business can manage their operations and digital marketing tools with one click of a button. Venues will have an easier method to update events, menus, specials, music, activities, and more. Keep customers engaged with the latest promotions and business ventures via notifications. In addition, review our platinum plan which allows users to view your business environment and events in real-time using virtual camera feeds. This is another strategic way for businesses to showcase their establishments and market their activities.</p>
              <p><strong>Features:</strong></p>
              <ul>
              <li>Maximize online exposure to new and existing customers</li>
              <li>Manage multiple business accounts</li>
              <li>Upload virtual feeds, photos, events, specials to all social media platforms</li>
              <li>Manage all business operations, marketing, and social media directly through the application</li>
              <li>Gather real-time feedback and information from your customers or users through engagement features such as likes &amp; dislikes, comments, reviews</li>
              <li>Use analytics to determine how to improve hosted events, specials, and activities</li>
              <li>Participate in promotions; add extra incentives for your business</li>
              <li>Push notifications to your users</li>
              </ul>
              <div class="old-logo">
                <img src={OldLogo} alt="BarView"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}