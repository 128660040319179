import React, { Component } from 'react';
import './Login.scss';
import './Newsletter.scss';
import Card from '../components/Card';
import Actions from '../actions/api';
import Alert from 'react-bootstrap/Alert';
import ButtonLoader from '../components/ButtonLoader';


const LinkState = require('../helpers/link-state');

let errorTimeout, successTimeout;

export default class Newsletter extends Component {
  state = {
    email: '',
    processing: false,
    error: '',
    success: ''
  };

  didLogin(response) {
    localStorage.setItem('userAccess', response.access);
    localStorage.setItem('userRefresh', response.refresh);

    Actions.get('users/me/').then(user => {
      window.bvUser = user;

      const keys = user.questionnaire ? Object.keys(user.questionnaire) : [];
      const needsToCompleteProfile = !keys.length || keys.filter(k => user.questionnaire && user.questionnaire[k] && user.questionnaire[k].length > 0).length === 0;

      window.location.href = needsToCompleteProfile ? '/account' : '/venues';
    }).catch(error => {
      this.setState({
        error,
        processing: false,
      });
    });
  }

  async doNewsletterSignup(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.state.email) {
      return this.setState({
        error: 'Please enter your email',
      });
    }

    this.setState({
      processing: true,
    });

    Actions.post('users/newsletter/subscribe/', {
      email: this.state.email
    }).then(response => {
      
      this.setState({
        redirect: '/',
      });
    }).catch(error => {
      this.setState({
        processing: false,
        error: Object.values(error)[0]
      });
    })
    return false;
  }

    render () {

    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    } else if (this.state.success) {
      if (successTimeout) {
        clearTimeout(successTimeout);
      }

      alert = (
        <Alert variant="success">
          Thank you. Your email has been subscribed successfully. 
        </Alert>
      );
      successTimeout = setTimeout(() => {
        this.setState({
          success: '',
          email: ''
        });
      }, 3e3);
    }

    let buttonText = (<span>Sign up</span>)

    if (this.state.processing) {
      buttonText = (<ButtonLoader />);
    }

    return (
      <div className="login newsletter container mt-4">
        {alert}
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <Card>
              <div className="header__logo remove-pad mb-force">
                <b>Subscribe to BarView:</b>
              </div>
              <ul>
                <li>
                    Stay updated with the latest features, trends and news.
                </li>
                <li>
                    Gain exclusive access to special content or resources only available to subscribers.
                </li>
                <li>
                    Learn from experts in the field through our insightful interviews and articles.
                </li>
                <li>
                    Gain incentives and discounts
                </li>
                <li>
                    Community engagement
                </li>
              </ul>
              <p>Don't miss out on the opportunity to subscribe and join our growing community of professionals, enthusiasts, creatives.</p>
              <form onSubmit={this.doNewsletterSignup.bind(this)} method="post">
                <input
                  className="form-control"
                  type="email"
                  id="email"
                  name="email"
                  value={this.state.email}
                  onChange={LinkState.bind(this)}
                  placeholder="Email Address"
                />

                <button
                  type="submit"
                  disabled={this.state.processing}
                  className="btn btn--primary mt-4"
                >
                  {buttonText}
                </button>
              </form>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}