import React, { Component } from 'react';
import './FeaturedBars.scss';
import Card from '../components/Card';
import Bar1 from '../img/bar-1.jpg';
import Bar2 from '../img/bar-2.jpg';
import Bar3 from '../img/bar-3.jpg';
import Bar4 from '../img/bar-4.jpg';
import Bar5 from '../img/bar-5.jpg';
import Bar6 from '../img/bar-6.jpg';
import { Link } from "react-router-dom";
import ZipModal from '../components/modals/ZipModal';
import HLSPlayer from '../components/HLSPlayer';

export default class FeaturedBars extends Component {

  state = {
    showVideoPlayer: 0,
  };

  onMouseEnter(bar, that) {
    // todo: make this work better in bars and featuredbars.js
    // if (bar.active_live_stream_url) {
    //   // start video
    //   that.setState({
    //     showVideoPlayer: bar.id,
    //   });
    // }
  }

  render () {

    const locationLabel = this.props.locationLabel || '';

    const bars = this.props.bars && this.props.bars.length ? (this.props.bars.map(bar => {
      let tags = [];

      if (bar.bar_of_the_week) {
        tags.push((
          <span className="featured" key="0">
            Bar of the Week <i className="fa fa-star"></i>
          </span>
        ));
      } else if (bar.featured) {
        tags.push((
          <span className="featured" key="0">
            Featured <i className="fa fa-star"></i>
          </span>
        ));
      }

      if (bar.online_ordering_url) {
        tags.push((
          <span className="featured" key="1">
            Online Ordering Available <i className="fa fa-burger-soda"></i>
          </span>
        ));
      }
      
      let banner = (<img className="img-fluid" src={bar.cover_photo_url} alt={bar.name}/>);

      let bottomRightIndicator = null;
      if (bar.active_live_stream_url) {
        
        const feedNote = bar.active_live_streams.length === 1 ? '1 Live View' : `${bar.active_live_streams.length} Live Views`;
        bottomRightIndicator = (
          <div className="right">
            {feedNote}! <i className="fa fa-circle"></i>
          </div>
        );

        if (this.state.showVideoPlayer === bar.id) {
          // add the video player instead of the photo
          banner = (
            <HLSPlayer url={bar.active_live_stream_url} />
          );
        }
      }

      const ratingScore = bar.rating || 0;
      const rating = (
        <span className="rating">
          <i className={ratingScore > 0 ? 'fa fa-star' : 'fa fa-star-o'}></i>
          <i className={ratingScore > 1 ? 'fa fa-star' : 'fa fa-star-o'}></i>
          <i className={ratingScore > 2 ? 'fa fa-star' : 'fa fa-star-o'}></i>
          <i className={ratingScore > 3 ? 'fa fa-star' : 'fa fa-star-o'}></i>
          <i className={ratingScore > 4 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        </span>
      );

      return (
        <div className="col-12 col-md-6 col-lg-4" key={bar.id}>
          <Link 
            to={'/venue/' + bar.slug}>
            <Card>
              <div 
                className="upper"
                onMouseEnter={() => this.onMouseEnter(bar, this)}>
                {banner}
                <div className="tags">
                  {tags}
                </div>
              </div>
              <div className="lower">
                <b>{bar.name}</b>
                {rating}
                {bottomRightIndicator}
              </div>
            </Card>
          </Link>
        </div>
      );
    })) : (<div className="no-results"><p>No bars found</p></div>);

    return (
      <div className="container">
        <div className="row featured-header">
          <div className="col-12 text-center">
            <h3>Featured venues{locationLabel}</h3>
            <ZipModal zipChanged={this.props.zipChanged.bind(this)} />
          </div>
        </div>
        <div className="row">
          {bars}
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <Link
              to="venues"
              className="btn btn--primary btn--large view-all-button"
            >
              View More
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
