import React, { Component } from 'react';
import './Login.scss';
import { Link } from "react-router-dom";
import Card from '../components/Card';
import { LinkContainer } from 'react-router-bootstrap';
import { Redirect } from "react-router-dom";
import Actions from '../actions/api';
import Alert from 'react-bootstrap/Alert';
import ButtonLoader from '../components/ButtonLoader';
import GoogleSocialButton from '../components/GoogleSocialButton'
import FBSocialButton from '../components/FBSocialButton'
import Logo from '../img/logo2.png';
const LinkState = require('../helpers/link-state');

let errorTimeout;
export default class Login extends Component {
  state = {
    email: '',
    password: '',
    processing: false,
    redirect: '',
    error: '',
    showPassword: false
  };

  didLogin(response) {
    localStorage.setItem('userAccess', response.access);
    localStorage.setItem('userRefresh', response.refresh);

    Actions.get('users/me/').then(user => {
      window.bvUser = user;

      const keys = user.questionnaire ? Object.keys(user.questionnaire) : [];
      const needsToCompleteProfile = !keys.length || keys.filter(k => user.questionnaire && user.questionnaire[k] && user.questionnaire[k].length > 0).length === 0;

      window.location.href = needsToCompleteProfile ? '/account' : '/venues';
    }).catch(error => {
      this.setState({
        error,
        processing: false,
      });
    });
  }

  async doLogin(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      processing: true,
    });

    Actions.basicAuthPost('users/token/', this.state.email, this.state.password).then(response => {
      this.didLogin(response);
    }).catch(error => {
      this.setState({
        error,
        processing: false,
      });
    })
    return false;
  }

  handleFBLogin(args) {
    if (args && args.token && args.token.accessToken) {
      this.setState({
        processing: true,
      });
      Actions.post('users/facebook_login/', {
        access_token: args.token.accessToken,
      }).then(res => {
        this.didLogin(res);
      }).catch(ex => {
        this.setState({
          processing: false,
          error: 'Failed to login. Please try again!',
        });
      });
    }
  }

  handleFBLoginFailure(ex) {
    console.log('handleFBLoginFailure', ex); 
  }

  handleGoogleLogin(args) {
    if (args && args.token && args.token.accessToken) {
      this.setState({
        processing: true,
      });
      Actions.post('users/google_login/', {
        id_token: args.token.idToken,
      }).then(res => {
        this.didLogin(res);
      }).catch(ex => {
        this.setState({
          processing: false,
          error: 'Failed to login. Please try again!',
        });
      });
    }
  }

  handleGoogleLoginFailure(ex) {
    console.log('handleGoogleLoginFailure', ex); 
  }

  toggleShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }


  render () {

    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    let buttonText = (<span>Login</span>)

    if (this.state.processing) {
      buttonText = (<ButtonLoader />);
    }

    return (
      <div className="login container">
        {alert}
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <Card>
              <div className="header__logo remove-pad mb-force">
                <Link to="/">
                  <img src={Logo} alt="BarView"/>
                </Link>
              </div>
              <form onSubmit={this.doLogin.bind(this)} method="post">
                <input
                  className="form-control"
                  type="text"
                  id="email"
                  name="email"
                  value={this.state.email}
                  onChange={LinkState.bind(this)}
                  placeholder="Email Address"
                />
                <div className="input-wrapper">
                <input
                  className="form-control"
                  type={this.state.showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={this.state.password}
                  onChange={LinkState.bind(this)}
                  placeholder="Password"
                />
                  <i className={`fa ${this.state.showPassword ? 'fa-eye-slash' : 'fa-eye'} text-white password-icon`} onClick={this.toggleShowPassword.bind(this)}></i>
                </div>

                <button
                  type="submit"
                  disabled={this.state.processing}
                  className="btn btn--primary btn--large mt-4"
                >
                  {buttonText}
                </button>
              </form>

              <p class="subtitle fancy"><span>OR</span></p>

              <div class="social-login-button">

                <FBSocialButton
                  provider='facebook'
                  appId='564660720693544'
                  onLoginSuccess={ this.handleFBLogin.bind(this) }
                  onLoginFailure={ this.handleFBLoginFailure.bind(this) }
                >
                  <span className="social-btn fb">Login with Facebook</span>
                </FBSocialButton>

                <GoogleSocialButton
                  provider='google'
                  appId='217984470737-08nicpmpjvof20e7neu8tt9g59n5m51o.apps.googleusercontent.com'
                  onLoginSuccess={ this.handleGoogleLogin.bind(this) }
                  onLoginFailure={ this.handleGoogleLoginFailure.bind(this) }
                >
                  <span className="social-btn google">Login with Google</span>
                </GoogleSocialButton>

              </div>

              <Link
                to="/forgot"
                className="btn btn-link"
              >
                Forgot Password?
              </Link>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}