import React, { Component } from "react";
import "./Venues.scss";
import Card from "../components/Card";
import { Link } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import LocationFilterModal from "../components/modals/LocationFilterModal";
import Dropdown from "react-bootstrap/Dropdown";
import Actions from "../actions/api";
import FullPageLoader from "../components/FullPageLoader";
import HLSPlayer from "../components/HLSPlayer";
import GoogleMap from "../components/GoogleMap";
import { Button } from "react-bootstrap";
import LocationPage from "./LocationPage";
const LinkState = require("../helpers/link-state");

const pageSize = 21;
export default class Venues extends Component {
  state = {
    bars: null,
    processing: true,
    showVideoPlayer: 0,

    pageIndex: 0,
    numberOfPages: 0,

    latitude: 0,
    longitude: 0,
    sort: "featured",
    search: "",
    filter_state: "",
    filter_city: "",
    locationLabel: "",
    mapVisible: false,
    AllowLocation: false,
  };

  componentDidMount() {
    // this.getBars(0, null, null, null, null, window.bvCoords);

    if (!window.bvCoords) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position && position.coords) {
            this.setState({AllowLocation:true})
            window.bvCoords = position.coords;
          }
          this.getBars(0, null, null, null, null, window.bvCoords);
        },
        (error) => {
          if(error){
            this.setState({AllowLocation:false})
          }
          this.getBars(0);
        }
      );
    }else{
      this.getBars(0);
      this.setState({AllowLocation:true})
    }
  }

  onMouseEnter(bar, that) {
    // todo: make this work better in bars and featuredbars.js
    // if (bar.active_live_stream_url) {
    //   // start video
    //   that.setState({
    //     showVideoPlayer: bar.id,
    //   });
    // }
  }

  filterChanged(state, city, lat, lng) {
    const locationLabel = ` near ` + (city
      ? ` ${city}, ${state}`
      : `${state} State`); 
    this.setState({
      locationLabel
    })
    this.getBars(0, null, state, city, null, { latitude: lat, longitude: lng });
  }

  onSearchKeyDown(evt) {
    // trigger search on enter
    if (evt.key === "Enter") {
      this.getBars(0,this.state.search,this.state.filter_state,this.state.filter_city);
    }
  }

  sortBy(sort) {
    this.getBars(0, null, null, null, sort);
  }

  async loadPage(pageIndex, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    await this.getBars(
      pageIndex,
      null,
      this.state.filter_state,
      this.state.filter_city
    );
  }

  async getBars(pageIndex, search, filter_state, filter_city, sort, coords) {
    const st = {
      processing: this.state.processing,
      bars: null,
      sort: this.state.sort,
      search: this.state.search,
      filter_state: this.state.filter_state,
      filter_city: this.state.filter_city,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };

    if (search) {
      st.search = search;
    }

    if (filter_state) {
      st.filter_state = filter_state;
    }
    else {
      st.filter_state = "";
    }

    if (filter_city) {
      st.filter_city = filter_city;
    }
    else {
      st.filter_city = "";
    }

    if (coords) {
      st.latitude = coords.latitude;
      st.longitude = coords.longitude;
    }

    if (sort) {
      st.sort = sort;
    }

    this.setState(st);

    const params = {
      offset: pageIndex * pageSize,
      limit: pageSize,
    };

    if (st.filter_state || st.filter_city) {
      params.filter_state = st.filter_state;
      params.filter_city = st.filter_city;
    }

    if (st.search) {
      params.search = st.search;
    }

    if (st.sort) {
      params.sort = st.sort;
    }

    const locationParams = {};
    let locationData = {};

    if (st.filter_state || st.filter_city) {
      locationParams.state = params.filter_state;
      locationParams.city = params.filter_city;
      locationData.state = params.filter_state;
      locationData.city = params.filter_city;
    } else if (params.latitude) {
      locationParams.latitude = params.latitude;
      locationParams.longitude = params.longitude;
      locationData = await Actions.get("bars/location/", locationParams);
    } else {
      locationData = await Actions.get("bars/location/", null);
    }

    const state =
      locationData && locationData.state ? ` ${locationData.state}` : "";
    const locationLabel =
      ` near ` +
      (locationData && locationData.city
        ? ` ${locationData.city}, ${state}`
        : `${state} State`);

        this.setState({filter_city: locationData.city ?? "", filter_state: locationData.state ?? ""})

    if (locationData.city || locationData.state) {
      params.filter_state = locationData.state;
      params.filter_city = locationData.city;
    }else{
      params.latitude = st.latitude;
      params.longitude = st.longitude;
    }
    Actions.get("bars/", params)
      .then((results) => {
        const bars = results.results || [];
        const numberOfPages = Math.ceil(results.count / pageSize);
        const filter_state = bars[0].address.region;
        const filter_city = bars[0].address.locality;

        this.setState({
          bars,
          processing: false,
          locationLabel,
          numberOfPages,
          pageIndex,
        });
      })
      .catch((ex) => {
        this.setState({
          bars: [],
          processing: false,
          locationLabel,
        });
      });
  }

  render() {
    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader />;
    }

    let alert = null;

    if (window.bvBarMissing) {
      window.bvBarMissing = false;
      alert = <Alert variant="danger">Bar not found. Please try again!</Alert>;
    } else if (window.bvBarEventMissing) {
      window.bvBarEventMissing = false;
      alert = (
        <Alert variant="danger">Event not found. Please try again!</Alert>
      );
    }

    const bars =
      this.state.bars && this.state.bars.length ? (
        this.state.bars.map((bar) => {
          let tags = [];

          if (bar.bar_of_the_week) {
            tags.push(
              <span className="featured" key="0">
                Bar of the Week <i className="fa fa-star"></i>
              </span>
            );
          } else if (bar.featured) {
            tags.push(
              <span className="featured" key="0">
                Featured <i className="fa fa-star"></i>
              </span>
            );
          }

          if (bar.online_ordering_url) {
            tags.push(
              <span className="featured" key="1">
                Online Ordering Available <i className="fa fa-burger-soda"></i>
              </span>
            );
          }

          let banner = (
            <img
              className="img-fluid"
              src={bar.cover_photo_url}
              alt={bar.name}
            />
          );

          // todo: need live views here .. ?
          let bottomRightIndicator = null;
          if (bar.active_live_stream_url) {
            const feedNote =
              bar.active_live_streams.length === 1
                ? "1 Live View"
                : `${bar.active_live_streams.length} Live Views`;
            bottomRightIndicator = (
              <div className="right">
                {feedNote}! <i className="fa fa-circle"></i>
              </div>
            );

            if (this.state.showVideoPlayer === bar.id) {
              // add the video player instead of the photo
              banner = (
                <HLSPlayer
                  url={bar.active_live_stream_url}
                  hideControls={true}
                />
              );
            }
          }

          const ratingScore = bar.rating || 0;
          const rating = (
            <span className="rating">
              <i
                className={ratingScore > 0 ? "fa fa-star" : "fa fa-star-o"}
              ></i>
              <i
                className={ratingScore > 1 ? "fa fa-star" : "fa fa-star-o"}
              ></i>
              <i
                className={ratingScore > 2 ? "fa fa-star" : "fa fa-star-o"}
              ></i>
              <i
                className={ratingScore > 3 ? "fa fa-star" : "fa fa-star-o"}
              ></i>
              <i
                className={ratingScore > 4 ? "fa fa-star" : "fa fa-star-o"}
              ></i>
            </span>
          );

          return (
            <div className="col-12 col-md-6 col-lg-4" key={bar.id}>
              <Link to={"/venue/" + bar.slug}>
                <Card>
                  <div
                    className="upper"
                    onMouseEnter={() => this.onMouseEnter(bar, this)}
                  >
                    {banner}
                    <div className="tags">{tags}</div>
                  </div>
                  <div className="lower">
                    <b>{bar.name}</b>
                    {rating}
                    {bottomRightIndicator}
                  </div>
                </Card>
              </Link>
            </div>
          );
        })
      ) : (
        <div className="col-12">
          <div className="no-results">
            <p>No venues found</p>
          </div>
        </div>
      );

    let selectedSort = "Featured";

    if (this.state.sort === "alphabetical") {
      selectedSort = "Alphabetical";
    } else if (this.state.sort === "rating") {
      selectedSort = "Rating";
    } else if (this.state.sort === "distance") {
      selectedSort = "Distance";
    }else if (this.state.sort === 'featured') {
      selectedSort = 'Featured';
    }

    // pagination
    let pagination = null;
    const numberOfPages = this.state.numberOfPages || 1;

    if (numberOfPages > 1) {
      const previous =
        this.state.pageIndex > 0 ? (
          <li className="page-item">
            <a
              className="page-link"
              href="#"
              onClick={(event) =>
                this.loadPage(this.state.pageIndex - 1, event)
              }
            >
              <i className="fa fa-chevron-left"></i>
            </a>
          </li>
        ) : null;

      let pages = [];
      var hasSkipped = false;
      const curPageDelta = 2;
      for (let i = 0; i < numberOfPages; i++) {
        const isCurrentPage = i === this.state.pageIndex;
        const isCloseToCurrentPage =
          i > this.state.pageIndex - curPageDelta &&
          i < this.state.pageIndex + curPageDelta;
        const classes = isCurrentPage ? "page-link active" : "page-link";

        // if we have many pages, skip the middle few ...
        if (
          !isCurrentPage &&
          !isCloseToCurrentPage &&
          numberOfPages > 5 &&
          i > curPageDelta &&
          i < numberOfPages - curPageDelta
        ) {
          if (!hasSkipped) {
            pages.push(
              <li className="page-item gap" key={i}>
                ...
              </li>
            );
          }
          hasSkipped = true;
          continue;
        } else {
          if (this.state.pageIndex === i) {
            // allow another gap to show up
            hasSkipped = false;
          }
          pages.push(
            <li className="page-item" key={i}>
              <a
                className={classes}
                href="#"
                onClick={(event) => this.loadPage(i, event)}
              >
                {i + 1}
              </a>
            </li>
          );
        }
      }

      const next =
        this.state.pageIndex + 1 < numberOfPages ? (
          <li className="page-item">
            <a
              className="page-link"
              href="#"
              onClick={(event) =>
                this.loadPage(this.state.pageIndex + 1, event)
              }
            >
              <i className="fa fa-chevron-right"></i>
            </a>
          </li>
        ) : null;

      pagination = (
        <div className="row">
          <div className="col-12">
            <nav>
              <ul className="pagination">
                {previous}
                {pages}
                {next}
              </ul>
            </nav>
          </div>
        </div>
      );
    }

    return (
      <div className="container list">
        {alert}
        <div className="row list-header">
          <div className="col-12 col-md-5">
            <h3>All venues{this.state.locationLabel}</h3>
            <LocationFilterModal
              filterChanged={this.filterChanged.bind(this)}
            />
          </div>

          <div className="col-12 col-md-6 search">
            <input
              type="search"
              id="search"
              name="search"
              value={this.state.search}
              onChange={LinkState.bind(this)}
              onKeyDown={this.onSearchKeyDown.bind(this)}
              placeholder="Search by Name, Description"
              className="form-control"
            />
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                Sort<span className="d-none d-lg-inline">: {selectedSort}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => this.sortBy("featured")}>
                  Featured
                </Dropdown.Item>
                {localStorage.getItem("userAccess") && (
                  <Dropdown.Item onClick={(e) => this.sortBy("alphabetical")}>
                    Alphabetical
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={(e) => this.sortBy("rating")}>
                  Rating
                </Dropdown.Item>
                {this.state.AllowLocation == true && (
                <Dropdown.Item onClick={(e) => this.sortBy("distance")}>
                  Distance
                </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <LocationPage
        bars={this.state.bars}
        pageIndex={this.state.pageIndex}
        search={this.state.search}
        filter_state={this.state.filter_state}
        filter_city={this.state.filter_city}
        sort={this.state.sort}
        coords={this.state.coords}
        pageSize={pageSize}
        latitude={this.state.latitude}
        longitude={this.state.longitude}
        onDataChange={this.handleDataChange}
        title={"venues"}
        locations = {bars}
        pagination={pagination}
        />
      </div>
    );
  }
}
