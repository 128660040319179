import React from 'react'
import SocialLogin from 'react-social-login'
import { FacebookLoginButton } from "react-social-login-buttons";

 
class FBSocialButton extends React.Component {
 
    render() {
        return (
            <FacebookLoginButton
              type="button"
              className="social-login"
              onClick={this.props.triggerLogin}
              {...this.props}>
              { this.props.children }
            </FacebookLoginButton>
        );
    }
}
 
export default SocialLogin(FBSocialButton);