import React, { Component } from 'react';
import './Venue.scss';
import { LinkContainer } from 'react-router-bootstrap';
import Card from '../components/Card';
import Button from 'react-bootstrap/Button';

import User from '../img/user.jpg';
import Event from '../img/event.jpg';

import SidebarContent from '../components/SidebarContent';
import TextTruncate from 'react-text-truncate';
import { Redirect } from "react-router-dom";
import Actions from '../actions/api';
import Alert from 'react-bootstrap/Alert';
import Carousel from 'react-bootstrap/Carousel';
import FullPageLoader from '../components/FullPageLoader';
import ButtonLoader from '../components/ButtonLoader';
import HLSPlayer from '../components/HLSPlayer';
import EventModal from '../components/modals/EventModal';
import '../components/modals/EventModal.scss';
const LinkState = require('../helpers/link-state');

let errorTimeout;
export default class Venue extends Component {
  state = {
    comment: '',
    comments: [],
    specials: [],
    events: [],

    error: '',
    bar: null,
    showEventsModal: false,
    
    processing: true,
    savingComment: false,
  };

  componentDidMount() {
    this.getBar();
  }

  async getBar() {
    this.setState({
      processing: true,
    });

    try {
      let barId = parseInt(this.props.match.params.id) ? this.props.match.params.id : 0;
      let bar;

      if (!barId) {
        // load with slug
        bar = await Actions.get(`bars/slug/${this.props.match.params.id}/`);
      } else {
        bar = await Actions.get(`bars/${this.props.match.params.id}/`);
      }
      barId = bar.id;

      const _gotEvents = (bar, comments, events) => {
        // todo: paging for specials
        Actions.get(`bars/specials/`, {
          bar: barId,
          limit: 5000,
        }).then(specials => {
          this.setState({
            events: events,
            comments,
            specials: specials.results,
            bar,
            processing: false,
          });
        }).catch(ex => {
          this.setState({
            events: events,
            comments,
            specials: [],
            bar,
            processing: false,
          });
        });
      };

      const _gotComments = (bar, comments) => {
        Actions.get(`bars/events/`, {
          bar: barId,
          limit: 5000,
        }).then(events => {
          _gotEvents(bar, comments, events.results);
        }).catch(ex => {
          _gotEvents(bar, comments, []);
        });
      };

      // todo: paging for comments
      Actions.get(`bars/comments/`, {
        bar: barId,
        limit: 5000,
      }).then(comments => {
        _gotComments(bar, comments.results);
      }).catch(ex => {
        _gotComments(bar, []);
      });
    } catch(ex) {
      window.bvBarMissing = true;
      this.setState({
        redirect: '/venues',
      });
    }
  }


  doLike() {
    if (this.state.bar.i_liked === true) {
      return;
    }

    Actions.post(`bars/${this.state.bar.id}/rate/`, {
      like: true,
    }).then(res => {
      const bar = this.state.bar;
      bar.likes++;

      if (bar.i_liked === false) {
        bar.dislikes--;
      }

      bar.i_liked = true;

      this.setState({
        bar,
      });
    }).catch(error => {

    });
  }

  doDislike() {
    if (this.state.bar.i_liked === false) {
      return;
    }
    Actions.post(`bars/${this.state.bar.id}/rate/`, {
      like: false,
    }).then(res => {
      const bar = this.state.bar;
      bar.dislikes++;

      if (bar.i_liked === true) {
        bar.likes--;
      }

      bar.i_liked = false;

      this.setState({
        bar,
      });
    }).catch(error => {

    });
  }

  doLikeSpecial(special) {
    if (special.i_liked === true) {
      return;
    }

    Actions.post(`bars/specials/${special.id}/rate/`, {
      like: true,
    }).then(res => {
      const specials = this.state.specials;
      const index = specials.findIndex(i => i.id === special.id);

      if (!specials[index].likes) {
        specials[index].likes = 0;
      }
      if (!specials[index].dislikes) {
        specials[index].dislikes = 0;
      }

      specials[index].likes++;

      if (specials[index].i_liked === false) {
        specials[index].dislikes--;
      }

      specials[index].i_liked = true;

      this.setState({
        specials,
      });
    }).catch(error => {

    });
  }

  doDislikeSpecial(special) {
    if (special.i_liked === false) {
      return;
    }
    Actions.post(`bars/specials/${special.id}/rate/`, {
      like: false,
    }).then(res => {
      const specials = this.state.specials;
      const index = specials.findIndex(i => i.id === special.id);

      if (!specials[index].likes) {
        specials[index].likes = 0;
      }
      if (!specials[index].dislikes) {
        specials[index].dislikes = 0;
      }

      specials[index].dislikes++;

      if (specials[index].i_liked === true) {
        specials[index].likes--;
      }

      specials[index].i_liked = false;

      this.setState({
        specials,
      });
    }).catch(error => {

    });
  }

  toggleFavorite() {

    if (this.state.bar.is_favorite) {
      Actions.post(`bars/favorites/remove/`, {
        bar_id: this.state.bar.id,
      }).then(res => {
        const bar = this.state.bar;
        bar.is_favorite = false;
        this.setState({
          bar,
        });
      }).catch(error => {
        this.setState({
          error,
        });
      });
    } else {
      Actions.post(`bars/favorites/`, {
        bar_id: this.state.bar.id,
      }).then(res => {
        const bar = this.state.bar;
        bar.is_favorite = true;
        this.setState({
          bar,
        });
      }).catch(error => {
        this.setState({
          error,
        });
      });
    }
  }

  doAddComment(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      savingComment: true,
      error: '',
    });

    Actions.post('bars/comments/', {
      bar_id: this.state.bar.id,
      text: this.state.comment,
    }).then(res => {
      let comments = this.state.comments;
      comments.unshift(res);
      this.setState({
        savingComment: false,
        error: '',
        comment: '',
        comments,
      });
    }).catch(error => {
      this.setState({
        savingComment: false,
        error,
      });
    });
  }

  showEventsModal() {
    this.setState({
      showEventsModal: true,
    });
  }

  doHideEventsModal() {
    this.setState({
      showEventsModal: false,
    });
  }

  render () {

    const now = new Date();
    const loggedIn = !!window.bvUser;

    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader/>;
    }

    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    const ratingScore = this.state.bar.rating || 0;
    const rating = (
      <span className="rating">
        <i className={ratingScore > 0 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 1 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 2 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 3 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 4 ? 'fa fa-star' : 'fa fa-star-o'}></i>
      </span>
    );

    const toggleFavoriteText = !this.state.bar.is_favorite ? (
      <span><i className="fa fa-plus"></i> Add to Favorites</span>
    ) : (
      <span><i className="fa fa-minus"></i> Remove from Favorites</span>
    );

    const likes = this.state.bar.likes ? `+${this.state.bar.likes}` : '0';
    const dislikes = this.state.bar.dislikes ? `-${this.state.bar.dislikes}` : '0';

    const userLiked = this.state.bar.i_liked === true;
    const userDisLiked = this.state.bar.i_liked === false;
    
    const location = this.state.bar && this.state.bar.address ? `${this.state.bar.address.locality}, ${this.state.bar.address.region}` : '';


    let specials = null;
    let menu = null;
    let events = null;

    let commentCount = this.state.comments.length;
    let comments = null;
    const showMoreEvents = this.state.events.length >= 5;

    if (this.state.events && this.state.events.length) {
      const eventsList = [];

      this.state.events.filter(e => new Date(e.end_time) >= now).forEach(ev => {

        if (eventsList.length >= 5) {
          return;
        }

        let startTime = new Date(ev.start_time);
        let endTime = new Date(ev.end_time);

        let eventTimeStr = `${startTime.toLocaleDateString()} ${startTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})} - ${endTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;

        const eventImage = ev.photos && ev.photos.length ? ev.photos[0].url : ''; // todo: placeholder?
        const eventUrl = `/event/${ev.slug}`;
        eventsList.push(
          <li key={ev.id}>
            <LinkContainer to={eventUrl}>
              <div className="event">
                <div className="left" style={{ backgroundImage: `url(${eventImage})`}}>
                  <img className="img-fluid d-md-none" src={Event} alt={ev.name} />
                </div>
                <div className="right">
                  <h6>{ev.name}</h6>
                  <p className="date-time">{eventTimeStr}</p>
                  <div className="description d-none d-md-block">
                    <TextTruncate
                      line={3}
                      truncateText="…"
                      text={ev.description}
                      textTruncateChild={<a href={eventUrl}>View Details</a>}
                    />
                  </div>
                  <div className="description d-md-none">
                    <TextTruncate
                      line={5}
                      truncateText="…"
                      text={ev.description}
                      textTruncateChild={<a href={eventUrl}>View Details</a>}
                    />
                  </div>
                  <button 
                    type="button"
                    className="btn btn-secondary">
                    View Details
                  </button>
                </div>
              </div>
            </LinkContainer>
          </li>
        );
      });

      const eventModalLI = showMoreEvents ? (
        <li>
          <Button
            type="button"
            variant="link" bsPrefix="all-event-btn" 
            onClick={this.showEventsModal.bind(this)}>
            View All {this.state.events.length} Events
          </Button>
        </li>
      ) : null;

      events = (
        <Card>
          <div className="address events-container">
            <h5><i className="fa fa-calendar"></i> Upcoming Events</h5>
            <ul>
              {eventsList}
              {eventModalLI}
            </ul>
          </div>
        </Card>
      );
    }

    if (this.state.specials && this.state.specials.length) {

      const specialList = this.state.specials.map(sp => {
        // todo: generate 125k from 125000
        const specialLikes = sp.likes ? sp.likes : '0';
        const specialDislikes = sp.dislikes ? sp.dislikes : '0';

        const userLikedSpecial = sp.i_liked === true;
        const userDisLikedSpecial = sp.i_liked === false;

        return (
          <li key={sp.id}>
            <h6>{sp.name}</h6>
            <p>
              {sp.description.split('\n').map((item, key) => {
                return <span key={key}>{item}<br/></span>
              })}
            </p>

            <Button className="txt-btn dislike" type="button" variant="error" onClick={() => this.doDislikeSpecial(sp)} disabled={userDisLikedSpecial}>
              <i className="fa fa-thumbs-down"></i>
              <span>{specialDislikes}</span>
            </Button>
            <Button className="txt-btn like" type="button" variant="secondary" onClick={() => this.doLikeSpecial(sp)} disabled={userLikedSpecial}>
              <i className="fa fa-thumbs-up"></i>
              <span>{specialLikes}</span>
            </Button>
          </li>
        );
      });

      specials = (
        <Card>
          <div className="address">
            <h5><i className="fa fa-glass"></i> Drink Specials</h5>
            <ul className="drink-specials">
              {specialList}
            </ul>
          </div>
        </Card>
      );
    }

    if (this.state.bar.menus && this.state.bar.menus.length) {

      let index = 0;
      let menuItems = this.state.bar.menus.map(m => {
        return (
          <li key={index++}>
            <span>
              <a href={m.url} target="_blank">{m.name}</a>
            </span>
            <span>
              <a href={m.url} target="_blank"><i className="fa fa-download"></i> Download</a>
            </span>
          </li>
        );
      });

      menu = (
        <Card>
          <div className="address">
            <h5><i className="fa fa-book"></i> Menus</h5>
            <ul className="leaders">
              {menuItems}
            </ul>
            <p><b>Order Online: </b><a target="_blank" href={ this.state.bar.online_ordering_url }>{ this.state.bar.online_ordering_url }</a></p>
          </div>
        </Card>
      );
    }

    if (this.state.comments && this.state.comments.length) {
      
      let commentsList = this.state.comments.map(comment => {

        const created = new Date(comment.created);

        const profileImage = comment.user.profile_image_url ? comment.user.profile_image_url : '/user-image.png';
        let profile = (
          <img className="img-fluid" src={profileImage} alt={comment.user.full_name}/>
        );

        let commentCreator = 'Anonymous User';
        if (comment.user && comment.user.full_name) {
          commentCreator = comment.user.full_name || '';
        }

        return (
          <div className="card comment" key={comment.id}>
            <div className="left">
              {profile}             
            </div>
            <div className="right">
              <p><b>{commentCreator}</b> &bull; {comment.text}</p>
              <span>{created.toLocaleString()}</span>
              {/*<span className="edit-buttons">
                <button className="txt-btn edit">Edit</button>
                <button className="txt-btn delete">Delete</button>
              </span>*/}
            </div>
          </div>
        );
      });

      comments = (
        <div className="comments">
          {commentsList}
        </div>
      );
    }

    let buttonText = (<span>Add Comment</span>)

    if (this.state.savingComment) {
      buttonText = (<ButtonLoader />);
    }

    const commentInput = loggedIn ? (
      <div className="comment-input-box">
        <form onSubmit={this.doAddComment.bind(this)} method="post">
          <textarea
            id="comment"
            name="comment"
            required={true}
            value={this.state.comment}
            onChange={LinkState.bind(this)}
            className="form-control comment-input"
            placeholder="Add a comment...">
          </textarea>
          <button disabled={this.state.savingComment} type="submit" className="btn btn-primary">{buttonText}</button>
        </form>
      </div>
    ) : null;

    const authButtons = loggedIn ? (
      <div className="col-12 col-lg-6 profile-actions">
        <Button type="button" variant="error" onClick={this.doDislike.bind(this)} disabled={userDisLiked}>
          <i className="fa fa-thumbs-down"></i>
          <span>{dislikes}</span>
        </Button>
        <Button type="button" variant="secondary" onClick={this.doLike.bind(this)} disabled={userLiked}>
          <i className="fa fa-thumbs-up"></i>
          <span>{likes}</span>
        </Button>
        <Button type="button" variant="primary" onClick={this.toggleFavorite.bind(this)}>
          {toggleFavoriteText}
        </Button>
      </div>
    ) : null;

    let carouselItems = [];    
    let count = 0;

    if (this.state.bar.active_live_streams && this.state.bar.active_live_streams.length) {
      this.state.bar.active_live_streams.forEach(stream => {
        carouselItems.push(<Carousel.Item key={count++}><HLSPlayer id={`video${count}`} autoPlay={false} url={stream.url} /></Carousel.Item>)
      });
    }

    this.state.bar.photos.forEach(p => {
      carouselItems.push(<Carousel.Item key={count++}><img className="img-fluid" src={p.url} alt={this.state.bar.name}/></Carousel.Item>)
    });

    const carousel = carouselItems.length ? (
      <Carousel 
        indicators={false}
        interval={null}
        controls={carouselItems.length > 1}>
        {carouselItems}
      </Carousel>
    ) : null;

    const description = this.state.bar.description ? (
      <Card>
        <div className="address">
          <h5><i className="fa fa-file"></i> Description</h5>
          <p>
            {this.state.bar.description.split('\n').map((item, key) => {
              return <span key={key}>{item}<br/></span>
            })}
          </p>
        </div>
      </Card>
    ) : null;

    return (
      <div className="container list">
        {alert}
        <div className="row list-header d-none d-md-flex">
          <div className="col-12 col-lg-6">
            <h3>{this.state.bar.name}</h3>
            <span className="username">
              <span className="location">
                {location}
              </span>
              {rating}
            </span>
          </div>
          {authButtons}
        </div>
        <div className="row profile">
          <div className="col-12 col-lg-8">
            <div className="video-area">
              {carousel}
            </div>
            <div className="row list-header lower-header d-md-none">
              <div className="col-12 col-md-6">
                <h3>{this.state.bar.name}</h3>
                <span className="username">
                  <span className="location">
                    {location}
                  </span>
                  {rating}
                </span>
              </div>
              <div className="col-12 col-md-6 profile-actions">
                <Button type="button" variant="error" onClick={this.doDislike.bind(this)}>
                  <i className="fa fa-thumbs-down"></i>
                  <span>{dislikes}</span>
                </Button>
                <Button type="button" variant="secondary" onClick={this.doLike.bind(this)}>
                  <i className="fa fa-thumbs-up"></i>
                  <span>{likes}</span>
                </Button>
                <Button type="button" variant="primary" onClick={this.toggleFavorite.bind(this)}>
                  {toggleFavoriteText}
                </Button>
              </div>
            </div>

            {description}
            
            {events}
            {specials}
            {menu}

            <div className="d-lg-none">
              <SidebarContent bar={this.state.bar} />
            </div>
            <hr className="white" />
            <h5><i className="fa fa-comments fa-margin-right"></i> Comments ({commentCount})</h5>
            {commentInput}
            {comments}
          </div>
          <div className="d-none d-lg-inline-block col-lg-4">
            <SidebarContent bar={this.state.bar} />
          </div>
        </div>
        <EventModal 
          show={this.state.showEventsModal} 
          events={this.state.events}
          handleClose={this.doHideEventsModal.bind(this)}/>
      </div>
    )
  }
}
