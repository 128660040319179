import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import './Admin.scss';
import { Link } from "react-router-dom";
import AdminChart from '../components/AdminChart/AdminChart';
import AdminSidebar from '../components/AdminSidebar';
import { LinkContainer } from 'react-router-bootstrap';
import User from '../img/user.jpg';
import { Redirect } from "react-router-dom";
import Sticky from 'react-stickynode';

import FullPageLoader from '../components/FullPageLoader';
import Actions from '../actions/api';
import ButtonLoader from '../components/ButtonLoader';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../helpers/link-state');
const parseQueryStringToDictionary = require('../helpers/parse-query');
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

export default class AdminBilling extends Component {
  state = {
    selectedBar: window.bvUser.admin_bar_id ? window.bvUser.admin_bar_id : window.bvUser && window.bvUser.bar_memberships && window.bvUser.bar_memberships.length ? window.bvUser.bar_memberships[0].bar.id : '',
    showSlugBar: false,
    userNeedsToPay: false,
    barOfWeekAvbl: false,
    billingProducts: null,
    selectedPlan: '',
    signingUp: false,
    canceling: false,
    bowingUp: false,
    subscription: null,

    redirect: '',
    error: '',
    success: '',
    processing: true,
  }

  componentDidMount() {
    const query = parseQueryStringToDictionary(document.location.search);

    if (query.slug) {
      this.getBar(query.slug);
    } else if (this.state.selectedBar) {
      this.getBar();
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedBar !== prevState.selectedBar) {
      window.bvUser.admin_bar_id = this.state.selectedBar;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', this.state.selectedBar);
      }
      this.getBar();
    }
  }

  async getBar(slug) {
    this.setState({
      processing: true,
    });

    let barId = this.state.selectedBar;
    let bar;
    let showSlugBar = false;

    if (window.bvUser.super_admin && window.bvUser.admin_bar_id) {
      showSlugBar = true;
      barId = window.bvUser.admin_bar_id;
    }
    
    if (slug) {
      bar = await Actions.get(`bars/slug/${slug}/`);
      barId = bar.id;
      showSlugBar = true;

      window.bvUser.admin_bar_id = barId;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', barId);
      }
    }

    if (barId) {
      try {
        if (!bar) {
          bar = await Actions.get(`bars/${barId}/`);
        }

        if (!bar.address) {
          bar.address = {};
        }

        if (!bar.hours_of_operation) {
          bar.hours_of_operation = {};
        }

        if (!bar.photos) {
          bar.photos = [];
        }

        let billingProducts = null;
        let userNeedsToPay = false;
        let barOfWeekAvbl = false;
        let subscription = null;

        const billingInfo = await Actions.get(`bars/admin/bars/${barId}/billing/`);
        const barOfWeekInfo = await Actions.get(`billing/bar_of_the_week_available/`, {
          bar_id: barId,
        });

        if (!window.bvUser.super_admin) {
          subscription = billingInfo.subscription || null;
          userNeedsToPay = !subscription;
          barOfWeekAvbl = (!bar.bar_of_the_week && barOfWeekInfo.bar_of_the_week_available) || false;

          billingProducts = await Actions.get('billing/products/', { limit: 100 });
        }

        this.setState({
          bar,
          showSlugBar,
          selectedBar: barId,
          processing: false,
          billingProducts: billingProducts ? billingProducts.results : [],
          userNeedsToPay,
          subscription,
          barOfWeekAvbl,
        });
      } catch (ex) {
        this.setState({
          redirect: '/',
        });
      }
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }


  async doStripeSub() {
    const selectedPlan = this.state.selectedPlan;

    if (selectedPlan && !this.state.signingUp) {
      this.setState({
        signingUp: true,
      });

      const checkoutResponse = await Actions.post('billing/checkout/', {
        bar_id: this.state.bar.id,
        plan_id: selectedPlan,
      }).catch(error => {
        this.setState({
          error,
          signingUp: false,
        });
      });

      if (checkoutResponse && checkoutResponse.session_id) {
        const stripe = window.Stripe(STRIPE_KEY);
        stripe.redirectToCheckout({
          sessionId: checkoutResponse.session_id,
        }).then(function (result) {
          if (result && result.error) {
            this.setState({
              error: result.error.message,
            });
          } else {
            console.log('signed up??');
          }
        });
      }
    }
  }

  async doChangeStripeSub(event) {
    event.preventDefault();
    event.stopPropagation();

    const selectedPlan = this.state.selectedPlan;

    if (selectedPlan && !this.state.signingUp) {
      this.setState({
        signingUp: true,
      });

      const checkoutResponse = await Actions.post('billing/subscription/modify/', {
        stripe_subscription_id: this.state.subscription.id,
        bar_id: this.state.bar.id,
        new_stripe_plan_id: selectedPlan,
      }).catch(error => {
        this.setState({
          error,
          signingUp: false,
        });
      });

      setTimeout(() => window.document.location.reload(), 1000);
    }
  }

  async doCancelStripeSub(event) {
    event.preventDefault();
    event.stopPropagation();    

    if (!this.state.canceling) {
      if (window.confirm(`Are you sure you want to cancel your subscription?`)) {
        this.setState({
          canceling: true,
        });

        const checkoutResponse = await Actions.post('billing/subscription/cancel/', {
          stripe_subscription_id: this.state.subscription.id,
          bar_id: this.state.bar.id,
        }).catch(error => {
          this.setState({
            error,
            canceling: false,
          });
        });

        setTimeout(() => window.document.location.reload(), 1000);
      }
    }
  }

  async doBOW() {
    if (!this.state.bowingUp) {

      this.setState({
        bowingUp: true,
      });

      const checkoutResponse = await Actions.post('billing/bar_of_the_week/', {
        bar_id: this.state.bar.id,
      }).catch(error => {
        this.setState({
          error,
          bowingUp: false,
        });
      });

      if (checkoutResponse && checkoutResponse.session_id) {
        const stripe = window.Stripe(STRIPE_KEY);
        stripe.redirectToCheckout({
          sessionId: checkoutResponse.session_id,
        }).then(function (result) {
          if (result && result.error) {
            this.setState({
              error: result.error.message,
            });
          } else {
            console.log('signed up??');
          }
        });
      }
    }
  }

  render () {

    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader/>;
    }

    const authorizedUser = window.bvUser;

    const numberOfBars = authorizedUser.bar_memberships.length;   

    const { 
      bar, 
      error, 
      showSlugBar,
      userNeedsToPay,
      barOfWeekAvbl,
      billingProducts,
      subscription,
    } = this.state;

    const barOptions = showSlugBar ? (<option key={bar.id} value={bar.id}>{bar.name}</option>) : (numberOfBars > 1 ? authorizedUser.bar_memberships.map(membership => {
      return (
        <option key={membership.bar.id} value={membership.bar.id}>{membership.bar.name}</option>
      );
    }) : null);


    const formatCurrency = value => {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    }

    let paymentOptions = [];
    (billingProducts || []).forEach(product => {
      let productName = product.name;
      // let productID = product.id;

      product.plans.forEach(plan => {
        const planID = plan.id;
        const formattedPrice = formatCurrency(plan.amount / 100);
        const label = `${productName} - ${formattedPrice}${plan.interval === 'month' ? '/Month' : '/Year'}`;
        paymentOptions.push(
          <option key={planID} value={planID}>{label}</option>
        );
      });
    });

    const paymentSelection = (
      <div className="form-group venue-select force-margin-right-2">
        <div className="bv-select">
          <select
            id="selectedPlan"
            name="selectedPlan"
            value={this.state.selectedPlan}
            disabled={this.state.signingUp}
            onChange={LinkState.bind(this)}
            className="custom-select">
            <option value="">Select Plan</option>
            {paymentOptions}
          </select>
          <i className="fa fa-caret-down"></i>
        </div>
      </div>
    );

    const signUpText = this.state.signingUp ? (
      <ButtonLoader />
    ) : (
      <span>Start Trial!</span>
    );

    const changePlanText = this.state.signingUp ? (
      <ButtonLoader />
    ) : (
      <span>Change Plan</span>
    );

    const cancelPlanText = this.state.canceling ? (
      <ButtonLoader />
    ) : (
      <span>Cancel Plan!</span>
    );

    const bowText = this.state.bowingUp ? (
      <ButtonLoader />
    ) : (
      <span>Sign Up!</span>
    );

    const hasActiveSub = subscription && subscription.status === 'active';

    let currentSubPrice = '';
    if (subscription && subscription.plan) {
      const period = subscription.plan.interval === 'year' ? 'Year' : 'Month';
      currentSubPrice = `${formatCurrency(subscription.plan.amount/100)}/${period}`;
    }

    const subscribeCTA = userNeedsToPay ? (
      <div className="row venue-content">
        <div className="col-12">
          <h4>Your Subscription</h4>
          <b>Active Subscription: none</b>
          <br /><br />
          <p className="limit-me">In order to start your 30 day free trial, select a plan below to enter your billing information. When your trial ends, you will be billed at the rate you have selected going forward.</p>
          {paymentSelection}
          <button className="btn btn-primary by-input" type="button" onClick={this.doStripeSub.bind(this)}>
            {signUpText}
          </button>
        </div>
      </div>
    ) : (hasActiveSub ? 
      (
        <div className="row venue-content">
          <div className="col-12">
            <h4>Your Subscription</h4>
            <b>Active Subscription: {subscription.product.name} - {currentSubPrice}</b>
            <br /><br />
            <p>To change your plan, select a new plan from the dropdown below </p>
            {paymentSelection}
            <button className="btn btn-primary by-input" type="button" onClick={this.doChangeStripeSub.bind(this)}>
              {changePlanText}
            </button>
          </div>
          <div className="col-12">
            <p className="cancel-plan">If you need to cancel your plan, <button class="btn txt-btn" onClick={this.doCancelStripeSub.bind(this)}>click here</button> to cancel.</p>
          </div>
        </div>
      ) : (
        <div className="venue-content">
          <div className="col-12">
            <b>No active subscription!</b>
          </div>
        </div>
      )
    );

    const barOfWeekForm = !userNeedsToPay && barOfWeekAvbl ? (
      <div className="row venue-content">
        <div className="col-12">
          <h4>Bar of the Week</h4>
          <p className="limit-me">Want to get even more traction to your Barview page? Sign up for <b>bar of the week</b> and for seven days your page will be featured ahead of all other bars in your area!</p>
          <button className="btn btn-primary" type="button" onClick={this.doBOW.bind(this)}>
            {bowText}
          </button>
        </div>
      </div>
    ) : (bar.bar_of_the_week ? (
      <div className="venue-content">
        <div className="col-12">
          <b>Bar of the Week is active!</b>
        </div>
      </div>
    ) : null);

    const barSelection = numberOfBars > 1 || showSlugBar ? (
      <div className="form-group venue-select">
        <div className="bv-select">
          <select
            id="selectedBar"
            name="selectedBar"
            value={this.state.selectedBar}
            onChange={LinkState.bind(this)}
            className="custom-select">
            {barOptions}
          </select>
          <i className="fa fa-caret-down"></i>
        </div>
      </div>
    ) : null;

    let alert = null;

    if (error) {
      alert = (
        <Alert variant="danger">
          {error}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    const ratingScore = bar.rating || 0;
    const rating = (
      <span className="rating">
        <i className={ratingScore > 0 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 1 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 2 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 3 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 4 ? 'fa fa-star' : 'fa fa-star-o'}></i>
      </span>
    );

    return (
      <div className="admin container-fluid">
        {alert}
        <div className="admin-sidebar">
          <div className="d-none d-lg-block">
            <Sticky enabled={true} top={100}>
              <AdminSidebar />
            </Sticky>
          </div>
          <div className="d-lg-none">
            <AdminSidebar />
          </div>
        </div>
        <div className="admin-content">
          <div className="row admin-header">
            <div className="col-12 col-md-5">
              <h2>Billing</h2>
              <p>
                <span className="venue">{bar.name}</span>
                {rating}
              </p>
            </div>
            <div className="col-12 col-md-7 text-right">
              {barSelection}
            </div>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-12">
              {subscribeCTA}
              <hr/>
              {barOfWeekForm}
            </div>
          </div>
        </div>
      </div>
    );
  }
}