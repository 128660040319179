import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import './Admin.scss';
import { Form, Checkbox } from 'react-bootstrap';
import { Link } from "react-router-dom";
import AdminChart from '../components/AdminChart/AdminChart';
import AdminSidebar from '../components/AdminSidebar';
import { LinkContainer } from 'react-router-bootstrap';
import User from '../img/user.jpg';
import { Redirect } from "react-router-dom";
import Sticky from 'react-stickynode';

import FullPageLoader from '../components/FullPageLoader';
import Actions from '../actions/api';
import ButtonLoader from '../components/ButtonLoader';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../helpers/link-state');
const parseQueryStringToDictionary = require('../helpers/parse-query');

const validateUrl = (value) => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
}


const cleanTimestamp = timestamp => {
  if (!timestamp) {
    return '';
  }

  const parts = timestamp.split(':');
  if (!parts.length) {
    return '';
  } else if (parts.length === 2) {
    return timestamp;
  } else if (parts.length === 1) {
    return `${timestamp}:00`;
  } else {
    return `${parts[0]}:${parts[1]}`;
  }
  return '';
};


export default class AdminVenue extends Component {
  state = {
    selectedBar: window.bvUser.admin_bar_id ? window.bvUser.admin_bar_id : window.bvUser && window.bvUser.bar_memberships && window.bvUser.bar_memberships.length ? window.bvUser.bar_memberships[0].bar.id : '',
    showSlugBar: false,
    userNeedsToPay: !window.bvUser || !window.bvUser.super_admin,

    redirect: '',
    error: '',
    success: '',
    bar: null,
    processing: true,
    saving: false,

    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip_code: '',

    website: '',
    online_ordering_url: '',

    featured: false,
    bar_of_the_week: false,

    email: '',
    phone: '',
    photos: [],
    deletedFiles: [],

    error: '',
    file: '',
    files: [],

    schedule: {
      sunday_open: '00:00',
      sunday_close: '00:00',
      monday_open: '00:00',
      monday_close: '00:00',
      tuesday_open: '00:00',
      tuesday_close: '00:00',
      wednesday_open: '00:00',
      wednesday_close: '00:00',
      thursday_open: '00:00',
      thursday_close: '00:00',
      friday_open: '00:00',
      friday_close: '00:00',
      saturday_open: '00:00',
      saturday_close: '00:00',
    },

    description: '',
    features: [],
    menus: [],

    newMode: document.location.pathname === '/admin/venue/add',

  }

  componentDidMount() {

    if (this.state.newMode) {

      this.setState({
        processing: false,
        bar: {
          id: '',
          photos: [],
        },
      });


      return;
    }

    const query = parseQueryStringToDictionary(document.location.search);

    if (query.slug) {
      this.getBar(query.slug);
    } else if (this.state.selectedBar) {
      this.getBar();
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedBar !== prevState.selectedBar) {
  window.bvUser.admin_bar_id = this.state.selectedBar;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', this.state.selectedBar);
      }
      this.getBar();
    }
  }

  async getBar(slug) {
    this.setState({
      processing: true,
    });

    let barId = this.state.selectedBar;
    let bar;
    let showSlugBar = false;

    if (window.bvUser.super_admin && window.bvUser.admin_bar_id) {
      showSlugBar = true;
      barId = window.bvUser.admin_bar_id;
    }
    
    if (slug) {
      bar = await Actions.get(`bars/slug/${slug}/`);
      barId = bar.id;
      showSlugBar = true;

      window.bvUser.admin_bar_id = barId;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', barId);
      }
    }

    if (barId) {
      try {
        if (!bar) {
          bar = await Actions.get(`bars/${barId}/`);
        }

        if (!bar.address) {
          bar.address = {};
        }

        if (!bar.hours_of_operation) {
          bar.hours_of_operation = {};
        }

        if (!bar.photos) {
          bar.photos = [];
        }

        let userNeedsToPay = false;

        if (!window.bvUser.super_admin) {
          const billingInfo = await Actions.get(`bars/admin/bars/${barId}/billing/`);
          userNeedsToPay = !billingInfo || !billingInfo.subscription;
        }



        const name = bar.name || '';
        const address1 = bar.address.street_address || '';
        const address2 = bar.address.street_address_2 || '';
        const city = bar.address.locality || '';
        const state = bar.address.region || '';
        const zip_code = bar.address.postal_code || '';
        const email = bar.email_address || '';
        const phone = bar.phone_number || '';
        const photos = bar.photos || []; // id,url,cover_photo bit

        const website = bar.website || '';
        const online_ordering_url = bar.online_ordering_url || '';

        const sunday_open = bar.hours_of_operation.sunday_open || '';
        const sunday_close = bar.hours_of_operation.sunday_close || '';
        const monday_open = bar.hours_of_operation.monday_open || '';
        const monday_close = bar.hours_of_operation.monday_close || '';
        const tuesday_open = bar.hours_of_operation.tuesday_open || '';
        const tuesday_close = bar.hours_of_operation.tuesday_close || '';
        const wednesday_open = bar.hours_of_operation.wednesday_open || '';
        const wednesday_close = bar.hours_of_operation.wednesday_close || '';
        const thursday_open = bar.hours_of_operation.thursday_open || '';
        const thursday_close = bar.hours_of_operation.thursday_close || '';
        const friday_open = bar.hours_of_operation.friday_open || '';
        const friday_close = bar.hours_of_operation.friday_close || '';
        const saturday_open = bar.hours_of_operation.saturday_open || '';
        const saturday_close = bar.hours_of_operation.saturday_close || '';

        const description = bar.description || '';
        const features = bar.venue_features || []; // id,name
        const menus = bar.menus || []; // id,url,name

        const featured = bar.featured || false;
        const bar_of_the_week = bar.bar_of_the_week || false;

        this.setState({
          bar,
          processing: false,

          name,
          address1,
          address2,
          city,
          state,
          zip_code,
          email,
          phone,
          photos,

          featured,
          bar_of_the_week,

          website,
          online_ordering_url,

          schedule: {
            sunday_open: cleanTimestamp(sunday_open),
            sunday_close: cleanTimestamp(sunday_close),
            monday_open: cleanTimestamp(monday_open),
            monday_close: cleanTimestamp(monday_close),
            tuesday_open: cleanTimestamp(tuesday_open),
            tuesday_close: cleanTimestamp(tuesday_close),
            wednesday_open: cleanTimestamp(wednesday_open),
            wednesday_close: cleanTimestamp(wednesday_close),
            thursday_open: cleanTimestamp(thursday_open),
            thursday_close: cleanTimestamp(thursday_close),
            friday_open: cleanTimestamp(friday_open),
            friday_close: cleanTimestamp(friday_close),
            saturday_open: cleanTimestamp(saturday_open),
            saturday_close: cleanTimestamp(saturday_close),
          },

          showSlugBar,
          selectedBar: barId,
          description,
          features,
          menus,
          userNeedsToPay,
        });
      } catch (ex) {
        this.setState({
          redirect: '/',
        });
      }
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }

  async doSaveBar() {

    if (this.state.userNeedsToPay) {
      return;
    }

    if (!this.state.name) {
      return this.setState({
        error: 'Please set a name',
      });
    }

    let cover_photo = '';

    const foundCoverPhoto = this.state.photos.filter(p => p.cover_photo);

    if (foundCoverPhoto.length) {
      cover_photo = foundCoverPhoto[0].id;
    } else if (this.state.photos.length === 1) {
      cover_photo = this.state.photos[0].id;
    }

    // allow a super admin to avoid validation rules
    if (!window.bvUser.super_admin) {

      // if (!this.state.description) {
      //   return this.setState({
      //     error: 'Please set a description',
      //   });
      // }     

      // if (!this.state.phone) {
      //   return this.setState({
      //     error: 'Please set a phone number',
      //   });
      // }

      const menusWithoutFiles = this.state.menus.filter(m => {
        return !m.deleted && (''+m.id).indexOf('new_') !== -1 && (!m.fileBlobs || !m.fileBlobs.length);
      })

      if (menusWithoutFiles.length) {
        return this.setState({
          error: 'Please add a menu file for each menu.',
        });
      }

      if (!cover_photo && !this.state.files.length) {
        return this.setState({
          error: 'Please upload and select a cover photo',
        });
      }
    }

    if (this.state.online_ordering_url && !validateUrl(this.state.online_ordering_url)) {
      return this.setState({
        error: 'Online Ordering URL must begin with http:// or https://',
      });
    }

    if (this.state.website && !validateUrl(this.state.website)) {
      return this.setState({
        error: 'Website must begin with http:// or https://',
      });
    }

    let barId = this.state.selectedBar;
    let barSlug = '';

    try {
      const data = {
        name: this.state.name,
        email_address: this.state.email,
        phone_number: this.state.phone,
        description: this.state.description,
        street_address: this.state.address1,
        street_address_line_2: this.state.address2,
        locality: this.state.city,
        state: this.state.state,
        postal_code: this.state.zip_code,

        website: this.state.website,
        online_ordering_url: this.state.online_ordering_url,
      };

      if (cover_photo) {
        data.cover_photo = cover_photo;
      }

      if (window.bvUser.super_admin) {
        data.featured = this.state.featured;
        data.bar_of_the_week = this.state.bar_of_the_week;
      }


      const hoursData = this.state.schedule;

      this.setState({
        saving: true,
      });

      let failed = false;
      if (this.state.newMode) {
        // save new bar
        try {
          const newBar = await Actions.post(`bars/admin/bars/`, data);
          barId = newBar.id;
          barSlug = newBar.slug;
        } catch (ex) {
          failed = true;
          let error = 'Failed to add venue. Please try again.';

          if (ex) {
            error = '';

            Object.keys(ex).forEach(key => {
              error += `${key} - ${ex[key][0]}`;
            });
          }
          this.setState({
            error,
            saving: false,
          });
        }
      } else {
        if (this.state.deletedFiles && this.state.deletedFiles.length) {
          
          // delete the photos before we move on
          for (let i = 0; i < this.state.deletedFiles.length; i++) {
            await Actions.delete(`bars/${barId}/photos/${this.state.deletedFiles[i]}/`);
          }
        }
      }

      if (this.state.files && this.state.files.length) {
        data.add_photos = []; // todo: this is not in API yet ...

        const signedS3Meta = await Actions.get(`bars/${barId}/signed_upload_urls/`, {
          amount: this.state.files.length,
        });
        for (let i = 0; i < this.state.files.length; i++) {
          const newFile = await Actions.saveFile(signedS3Meta[i].signed_url, this.state.files[i]);

          data.add_photos.push({
            s3_bucket: signedS3Meta[i].s3_bucket,
            s3_path: signedS3Meta[i].s3_path,
            cover_photo: this.state.newMode && i === 0,
          });
        }
      }

      try {
        if (window.bvUser.super_admin) {
          await Actions.patch(`bars/admin/bars/${barId}/`, data);
        } else {
          await Actions.put(`bars/${barId}/update/`, data);
        }
      } catch (ex) {
        failed = true;
        let error = 'Failed to save venue. Please try again.';

        if (ex) {
          error = '';

          Object.keys(ex).forEach(key => {
            error += `${key} - ${ex[key][0]}`;
          });
        }
        this.setState({
          error,
          saving: false,
        });
      }

      if (failed) {
        return;
      }

      Object.keys(hoursData).forEach(key => {
        hoursData[key] = hoursData[key] || null;
      });

      await Actions.put(`bars/${barId}/update_hours/`, hoursData);

      for (let i = 0; i < this.state.menus.length; i++ ) {
        const menuItem = this.state.menus[i];
        const isNew = (''+menuItem.id).indexOf('new_') !== -1;

        if (menuItem.deleted && !isNew) {
          // delete it
          await Actions.delete(`bars/${barId}/menus/${menuItem.id}/`);
        } else if (isNew) {
          // add new menu item w/ file
          if (menuItem.fileBlobs && menuItem.fileBlobs.length) {
            const signedS3Meta = await Actions.get(`bars/${barId}/signed_upload_urls/`, {
              amount: 1,
            });

            await Actions.saveFile(signedS3Meta[0].signed_url, menuItem.fileBlobs[i]);

            const newMenuItem = await Actions.post(`bars/${barId}/menus/`, {
              name: menuItem.name,
              s3_bucket: signedS3Meta[0].s3_bucket,
              s3_path: signedS3Meta[0].s3_path,
            });

            if (newMenuItem) {
              menuItem.id = newMenuItem.id;
              menuItem.url = newMenuItem.url;
            }            
          }
        } else {
          // there is only delete and start over, no update
        }
      }

      const savedFeatures = await Actions.put(`bars/${barId}/venue_features/`, {
        venue_features: this.state.features.map(f => ({"name": f.name}))
      });

      this.setState({
        saving: false,
        success: 'Bar Saved!',
        error: '',
        file: '',
        files: [],
        menus: this.state.menus.filter(m => !m.deleted),
      });
      setTimeout(() => {
        window.location.reload();
      }, 300);
    } catch (ex) {
      this.setState({
        error: 'Failed to save event. Please try again.',
        saving: false,
      });
    }

    if (this.state.newMode) {
      // done saving, redirect to edit mode for this bar
      window.location.href = '/admin/venue?slug=' + barSlug;
    }
  }

  onSetFile(evt) {
    if (this.state.userNeedsToPay) {
      return;
    }

    if (evt.target.files.length) {
      this.setState({
        files: evt.target.files,
        file: evt.currentTarget.value,
      });
    } else {
      this.setState({
        files: [],
        file: '',
      });
    }
  }

  onSetMenuFile(evt, menuId) {
    if (this.state.userNeedsToPay) {
      return;
    }

    const menus = this.state.menus;
    const menuObject = menus.filter(m => m.id === menuId);
    if (menuObject.length) {
      if (evt.target.files.length) {
        menuObject[0].file = evt.currentTarget.value;
        menuObject[0].fileBlobs = evt.currentTarget.files;
      } else {
        menuObject[0].file = '';
        menuObject[0].fileBlobs = [];
      }

      this.setState({
        menus,
      });
    }
  }

  doDeletePhoto(photoId, evt) {
    if (this.state.userNeedsToPay) {
      return;
    }

    const deletedFiles = this.state.deletedFiles;
    deletedFiles.push(photoId);

    this.setState({
      deletedFiles,
    })
  }

  doSetCoverImage(photoId, evt) {
    
    const bar = this.state.bar;

    // only allow one cover image
    bar.photos.forEach(photo => {
      photo.cover_photo = photo.id === photoId;
    });


    this.setState({
      bar,
    })
  }

  handleFeatureChanged(feature, event) {
    const features = this.state.features;
    const found = features.filter(f => f.id === feature.id);

    if (found.length) {
      found[0].name = event.currentTarget.value;
    }
    this.setState({
      features,
    });
  }

  handleFeatureRemoved(feature, event) {
    const features = this.state.features.filter(f => f.id !== feature.id);

    this.setState({
      features,
    });
  }

  addNewFeature() {
    const features = this.state.features;
    features.push({
      id: `new_${new Date().getTime()}`,
      name: '',
    });

    this.setState({
      features,
    });
  }

  handleMenuNameChanged(menu, event) {
    const menus = this.state.menus;
    const found = menus.filter(m => m.id === menu.id);

    if (found.length) {
      found[0].name = event.currentTarget.value;
    }
    this.setState({
      menus,
    });
  }


  handleMenuRemoved(menu, event) {
    const menus = this.state.menus;
    const found = menus.filter(m => m.id === menu.id);

    if (found.length) {
      found[0].deleted = true;
    }
    this.setState({
      menus,
    });
  }

  addNewMenuItem() {
    const menus = this.state.menus;
    menus.push({
      id: `new_${new Date().getTime()}`,
      name: '',
      url: '',
    });

    this.setState({
      menus,
    });
  }

  render () {

    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader/>;
    }

    const authorizedUser = window.bvUser;

    if (!authorizedUser) {
      return (
        <Redirect to="/" />
      );
    }

    const numberOfBars = authorizedUser.bar_memberships.length;

    const { 
      bar, 
      error,
      success,
      showSlugBar,
      newMode,
      userNeedsToPay,
    } = this.state;

    const barOptions = showSlugBar ? (<option key={bar.id} value={bar.id}>{bar.name}</option>) : (numberOfBars > 1 ? authorizedUser.bar_memberships.map(membership => {
      return (
        <option key={membership.bar.id} value={membership.bar.id}>{membership.bar.name}</option>
      );
    }) : null);

    const billingLink = `/admin/billing?slug=${bar.slug}`;
    const payAlert = userNeedsToPay ? (
      <Alert variant="info billing-alert">
        To start your free trial, <a href={billingLink}>click here to enter your billing information</a>. You will be unable to edit venue information until you do.
      </Alert>
    ) : null;

    const barSelection = numberOfBars > 1 || showSlugBar ? (
      <div className="form-group venue-select">
        <div className="bv-select">
          <select
            id="selectedBar"
            name="selectedBar"
            value={this.state.selectedBar}
            onChange={LinkState.bind(this)}
            className="custom-select">
            {barOptions}
          </select>
          <i className="fa fa-caret-down"></i>
        </div>
      </div>
    ) : null;

    let alert = null;

    if (success) {
      alert = (
        <Alert variant="success">
          {success}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          success: '',
        });
      }, 3e3);
    }
    else if (error) {
      alert = (
        <Alert variant="danger">
          {error}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    const ratingScore = bar.rating || 0;
    const rating = (
      <span className="rating">
        <i className={ratingScore > 0 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 1 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 2 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 3 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 4 ? 'fa fa-star' : 'fa fa-star-o'}></i>
      </span>
    );

    const remainingPhotos = bar.photos.filter(p => this.state.deletedFiles.indexOf(p.id) === -1);
    const photoGallery = remainingPhotos.length ? remainingPhotos.map(photo => {
      
      const setCoverButton = photo.cover_photo ? null : (
        <button
          style={ {marginRight: 35 } }
          type="button"
          onClick={ (event) => this.doSetCoverImage(photo.id, event) }
          className="close-button">
          Set Cover Photo
        </button>
      );

      return (
        <div className="col-lg-3 col-md-4 col-6 photo" key={photo.id}>
          <img className="img-fluid img-thumbnail mb-4" src={photo.url} alt="" />
          {setCoverButton}

          <button
            type="button"
            onClick={ (event) => this.doDeletePhoto(photo.id, event) }
            className="close-button">
            <i className="fa fa-times"></i>
          </button>
        </div>
      );
    }) : (
      <div className="col-lg-3 col-md-4 col-6">
        <p>No existing photos</p>
      </div>
    );

    const optionsList = (
      <>
        <option value="">Closed</option>
        <option value="00:00">12:00 am</option>
        <option value="00:30">12:30 am</option>
        <option value="01:00">01:00 am</option>
        <option value="01:30">01:30 am</option>
        <option value="02:00">02:00 am</option>
        <option value="02:30">02:30 am</option>
        <option value="03:00">03:00 am</option>
        <option value="03:30">03:30 am</option>
        <option value="04:00">04:00 am</option>
        <option value="04:30">04:30 am</option>
        <option value="05:00">05:00 am</option>
        <option value="05:30">05:30 am</option>
        <option value="06:00">06:00 am</option>
        <option value="06:30">06:30 am</option>
        <option value="07:00">07:00 am</option>
        <option value="07:30">07:30 am</option>
        <option value="08:00">08:00 am</option>
        <option value="08:30">08:30 am</option>
        <option value="09:00">09:00 am</option>
        <option value="09:30">09:30 am</option>
        <option value="10:00">10:00 am</option>
        <option value="10:30">10:30 am</option>
        <option value="11:00">11:00 am</option>
        <option value="11:30">11:30 am</option>
        <option value="12:00">12:00 pm</option>
        <option value="12:30">12:30 pm</option>
        <option value="13:00">01:00 pm</option>
        <option value="13:30">01:30 pm</option>
        <option value="14:00">02:00 pm</option>
        <option value="14:30">02:30 pm</option>
        <option value="15:00">03:00 pm</option>
        <option value="15:30">03:30 pm</option>
        <option value="16:00">04:00 pm</option>
        <option value="16:30">04:30 pm</option>
        <option value="17:00">05:00 pm</option>
        <option value="17:30">05:30 pm</option>
        <option value="18:00">06:00 pm</option>
        <option value="18:30">06:30 pm</option>
        <option value="19:00">07:00 pm</option>
        <option value="19:30">07:30 pm</option>
        <option value="20:00">08:00 pm</option>
        <option value="20:30">08:30 pm</option>
        <option value="21:00">09:00 pm</option>
        <option value="21:30">09:30 pm</option>
        <option value="22:00">10:00 pm</option>
        <option value="22:30">10:30 pm</option>
        <option value="23:00">11:00 pm</option>
        <option value="23:30">11:30 pm</option>
      </>
    );

    const schedule = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => {

      const dayKey = day.toLowerCase();
      const onKey = `${dayKey}_open`;
      const offKey = `${dayKey}_close`;

      const onTime = this.state.schedule[onKey] || '';
      const offTime = this.state.schedule[offKey] || '';

      return (
        <div className="form-group row" key={day}>
          <label className="col-sm-2 col-form-label">{day}:</label>
          <div className="col-sm-10">
            <div className="form-row">
              <div className="col">
                <div className="bv-select inline">
                  <label className="form-label sr-only">Opening Time</label>
                  <select
                    id={onKey}
                    name={`schedule.${onKey}`}
                    value={this.state.schedule[onKey]}
                    onChange={LinkState.bind(this)}
                    className="custom-select">
                    {optionsList}
                  </select>
                  <i className="fa fa-caret-down"></i>
                </div>
              </div>
              <div className="col">
                <div className="bv-select inline">
                  <select 
                    id={offKey}
                    name={`schedule.${offKey}`}
                    value={this.state.schedule[offKey]}
                    onChange={LinkState.bind(this)}
                    className="custom-select">
                    {optionsList}
                  </select>
                  <i className="fa fa-caret-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    const features = this.state.features.length ? this.state.features.map(f => {

      return (
        <div className="input-with-close" key={f.id}>
          <input
            value={f.name}
            onChange={(event) => this.handleFeatureChanged(f, event)}
            type="text"
            className="form-control mb-2"
            placeholder="Feature..." />
          <button
            onClick={(event) => this.handleFeatureRemoved(f, event)}
            className="close-button">
            <i className="fa fa-times"></i>
          </button>
        </div>
      );
    }) : (
      <div className="col-lg-3 col-md-4 col-6">
        <p>No existing features</p>
      </div>
    );


    const remainingMenus = this.state.menus.filter(m => !m.deleted);

    const menus = remainingMenus.length ? remainingMenus.map(m => {

      const isNewMenu = (''+m.id).indexOf('new_') !== -1

      const menuNameInput = !isNewMenu ? (
        <p>{m.name}</p>
      ) : (
        <input
          value={m.name}
          onChange={(event) => this.handleMenuNameChanged(m, event)}
          type="text"
          className="form-control"
          placeholder="Menu Title" />
      );

      const menuPicker = isNewMenu ? (
        <input
          type="file"
          value={m.file}
          onChange={(event) => this.onSetMenuFile(event, m.id)}
          className="form-control" />
      ) : (
        <div>
          <a className="" href={m.url} target="_blank">View Menu</a>
        </div>
      );

      return (
        <div className="menu-block" key={m.id}>
          <div className="left">
            <div className="form-row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Menu Title</label>
                  {menuNameInput}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Menu File</label>
                  {menuPicker}
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={(event) => this.handleMenuRemoved(m, event)}
            className="close-button">
            <i className="fa fa-times"></i>
          </button>
        </div>
      );
    }) : (
      <div className="col-lg-3 col-md-4 col-6">
        <p>No existing menus</p>
      </div>
    );


    const buttonTitle = newMode ? 'Add Venue' : 'Save Info';
    const buttonText = this.state.saving ? (
      <ButtonLoader />
    ) : (
      <span>{buttonTitle}</span>
    );

    const venueInfo = newMode ? null : (
      <p>
        <span className="venue">{bar.name}</span>
        {rating}
      </p>
    );

    const adminFields = window.bvUser.super_admin ? (
      <div className="form-group">
        <label htmlFor="VenueFlags">Venue Flags</label>
        <Form.Check
          key="featured"
          id="featured"
          name="featured"
          type="checkbox"
          checked={ this.state.featured }
          onChange={ () => this.setState({ featured : !this.state.featured }) }
          label="Featured Venue"
        />

        <Form.Check
          key="bar_of_the_week"
          id="bar_of_the_week"
          name="bar_of_the_week"
          type="checkbox"
          checked={ this.state.bar_of_the_week }
          onChange={ () => this.setState({ bar_of_the_week : !this.state.bar_of_the_week }) }
          label="Bar of the Week"
        />
      </div>
    ) : null;

    const sidebar = userNeedsToPay ? null : (
      <div className="venue-sidebar">
        <div className="d-none d-lg-block">
          <Sticky enabled={true} top={100}>
            <div
              className="venue-save">
              <button
                type="button"
                onClick={this.doSaveBar.bind(this)}
                className="btn btn-primary">{buttonText}</button>
            </div>
          </Sticky>
        </div>
        <div className="d-lg-none">
          <div
            className="venue-save">
            <button
              type="button"
              onClick={this.doSaveBar.bind(this)}
              className="btn btn-primary">{buttonText}</button>
          </div>
        </div>
      </div>
    );


    const venueContent = (
      <>
        <div className="venue-content">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="VenueName">Venue Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={this.state.name}
                onChange={LinkState.bind(this)}
                className="form-control"
                placeholder="Venue Name..." />
            </div>
            {adminFields}
            <div className="form-group">
              <label htmlFor="Address1">Address Line 1</label>
              <input
                type="text"
                id="address1"
                name="address1"
                value={this.state.address1}
                onChange={LinkState.bind(this)}
                className="form-control"
                placeholder="Address Line 1..." />
            </div>
            <div className="form-group">
              <label htmlFor="Address2">Address Line 2</label>
              <input
                type="text"
                id="address2"
                name="address2"
                value={this.state.address2}
                onChange={LinkState.bind(this)}
                className="form-control"
                placeholder="Address Line 2..." />
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <input 
                    type="text"
                    id="city"
                    name="city"
                    value={this.state.city}
                    onChange={LinkState.bind(this)}
                    className="form-control"
                    placeholder="City..." />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="state">State</label>
                  <input 
                    type="text"
                    id="state"
                    name="state"
                    value={this.state.state}
                    onChange={LinkState.bind(this)}
                    className="form-control"
                    placeholder="State..." />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="Zip">Zip Code</label>
              <input
                type="text"
                id="zip_code"
                name="zip_code"
                value={this.state.zip_code}
                onChange={LinkState.bind(this)}
                className="form-control"
                placeholder="Zip Code..." />
            </div>
            <hr className="mt-5" />
            <h2 className="mb-4">Contact Info</h2>
            <div className="form-group">
              <label htmlFor="venueEmail">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                value={this.state.email}
                onChange={LinkState.bind(this)}
                className="form-control"
                placeholder="Email Address" />
            </div>
            <div className="form-group">
              <label htmlFor="venuePhone">Phone Number</label>
              <input
                type="text"
                id="phone"
                name="phone"
                minlength="10"
                value={this.state.phone}
                onChange={LinkState.bind(this)}
                className="form-control"
                placeholder="Phone Number" />
            </div>
            <div className="form-group">
              <label htmlFor="venuePhone">Web Address</label>
              <input
                type="text"
                id="website"
                name="website"
                value={this.state.website}
                onChange={LinkState.bind(this)}
                className="form-control"
                placeholder="http://www.bar.com" />
            </div>
            <div className="form-group">
              <label htmlFor="venuePhone">Online Ordering Full URL</label>
              <input
                type="text"
                id="online_ordering_url"
                name="online_ordering_url"
                value={this.state.online_ordering_url}
                onChange={LinkState.bind(this)}
                className="form-control"
                placeholder="http://www.bar.com" />
            </div>
            <hr className="mt-5" />
            <h4 className="mb-4">Add Photos</h4>
            <div className="form-group">
              <input
                type="file"
                id="file"
                name="file"
                multiple="multiple"
                value={this.state.file}
                onChange={this.onSetFile.bind(this)}
                className="form-control" />
            </div>
            <h2 className="mb-4">Photos</h2>
            <div className="row photo-gallery">
              {photoGallery}
            </div>
            <hr className="mt-5" />
            <h2 className="mb-4">Hours</h2>

            {schedule}
            
            <div className="mt-5">
            <h2 className="mb-4">Venue Description</h2>
              <div className="form-group">
                <textarea
                  id="description"
                  name="description"
                  value={this.state.description}
                  onChange={LinkState.bind(this)}
                  className="form-control height-250"
                  placeholder="Enter a description..."></textarea>
              </div>
            </div>
            <hr className="mt-5" />
            <h2 className="mb-4">Venue Features</h2>
            <small className="d-block mb-4">List out the features that make your venue unique (Pool, Darts, Arcade Games, etc...)</small>
            <div className="form-group feature-input-list">
              
              {features}
              
              { userNeedsToPay ? null : <button
                type="button"
                onClick={this.addNewFeature.bind(this)}
                className="btn btn-secondary mt-4">
                <i className="fa fa-plus icon-right"></i>
                Add Another Feature
              </button> }
            </div>
            <hr className="mt-5" />
            <h2 className="mb-4">Menus</h2>
            <small className="d-block mb-4">Upload PDFs of your Venu's Menus.</small>
            
            {menus}
            
            { userNeedsToPay ? null : <button
              type="button"
              onClick={this.addNewMenuItem.bind(this)}
              className="btn btn-secondary mt-4">
              <i className="fa fa-plus icon-right"></i>
              Add Another Menu
            </button> }
          </div>
        </div>
        {sidebar}
      </>
    );

    return (
      <div className="admin container-fluid">
        {payAlert}
        {alert}
        <div className="admin-sidebar">
          <div className="d-none d-lg-block">
            <Sticky enabled={true} top={100}>
              <AdminSidebar />
            </Sticky>
          </div>
          <div className="d-lg-none">
            <AdminSidebar />
          </div>
        </div>
        <div className="admin-content">
          <div className="row admin-header">
            <div className="col-12 col-md-5">
              {alert}
              <h2>Venue Info</h2>
              {venueInfo}
            </div>
            <div className="col-12 col-md-7 text-right">
              {barSelection}
            </div>
            <div className="col-12">
              <hr />
            </div>
            {venueContent}
          </div>
        </div>
      </div>
    )
  }
}