import React, { Component } from 'react';
import './Favorites.scss';
import Card from '../components/Card';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import GoogleMap from "../components/GoogleMap";
import { Redirect } from "react-router-dom";
import Actions from '../actions/api';
import FullPageLoader from '../components/FullPageLoader';
import LocationPage from "./LocationPage";
import Button from 'react-bootstrap/Button';
const LinkState = require("../helpers/link-state");

const pageSize = 21;
export default class Favorites extends Component {

  state = {
    bars: null,
    processing: false,

    pageIndex: 0,
    numberOfPages: 0,
    mapVisible: false,
    sort: "featured",
    search: "",
    AllowLocation: false,
  };

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position && position.coords) {
          this.setState({AllowLocation:true})
        }
      },
      (error) => {
        if(error){
          this.setState({AllowLocation:false})
        }
      }
    );
    this.getFavorites(0);
  }

  onSearchKeyDown(evt) {
    // trigger search on enter
    if (evt.key === "Enter") {
      this.getFavorites(0);
    }
  }

  async loadPage(pageIndex, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    await this.getFavorites(pageIndex);
  }

  sortBy(sort) {
    this.getFavorites(0, null, sort);
  }

  async getFavorites(pageIndex, search, sort) {
    const st = {
      processing: true,
      bars: null,
      sort: this.state.sort,
      search: this.state.search,
    };

    if (search) {
      st.search = search;
    }

    if (sort) {
      st.sort = sort;
    }
    
    this.setState(st);

    const params = {
      favorites: 1,
      offset: pageIndex * pageSize,
      limit: pageSize,
    };

    if (st.search) {
      params.search = st.search;
    }

    if (st.sort) {
      params.sort = st.sort;
    }

    Actions.get('bars/',params)
    .then(results => {
      const bars = results.results || [];
      const numberOfPages = Math.ceil(results.count / pageSize);
      this.setState({
        bars,
        processing: false,
        numberOfPages,
        pageIndex,
      });
    }).catch(ex => {
      this.setState({
        bars: [],
        processing: false,
        pageIndex,
      });
    });
  }

  render () {
    const loggedIn = !!window.bvUser;

    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader/>;
    }

    if (!this.state.bars || !this.state.bars.length) {
      return (
        <div className="container list">
          {alert}
          <div className="row">
            <div className="col-12">
              <div className="no-results">
                <p>You haven't favorited any locations yet. When you do, they'll appear here.</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let selectedSort = "Featured";

      if (this.state.sort === "alphabetical") {
        selectedSort = "Alphabetical";
      } else if (this.state.sort === "rating") {
        selectedSort = "Rating";
      } else if (this.state.sort === "distance") {
        selectedSort = "Distance";
      }else if (this.state.sort === 'featured') {
        selectedSort = 'Featured';
      }

    const bars = this.state.bars.map(bar => {
      let tags = [];

      if (bar.bar_of_the_week) {
        tags.push((
          <span className="featured" key="0">
            Bar of the Week <i className="fa fa-star"></i>
          </span>
        ));
      } else if (bar.featured) {
        tags.push((
          <span className="featured" key="0">
            Featured <i className="fa fa-star"></i>
          </span>
        ));
      }

      if (bar.online_ordering_url) {
        tags.push((
          <span className="featured" key="1">
            Online Ordering Available <i className="fa fa-burger-soda"></i>
          </span>
        ));
      }

      let bottomRightIndicator = null;
      if (bar.active_live_stream_url) {
        const feedNote = bar.active_live_streams.length === 1 ? '1 Live View' : `${bar.active_live_streams.length} Live Views`;
        bottomRightIndicator = (
          <div className="right">
            {feedNote}! <i className="fa fa-circle"></i>
          </div>
        );
      }

      const ratingScore = bar.rating || 0;
      const rating = (
        <span className="rating">
          <i className={ratingScore > 0 ? 'fa fa-star' : 'fa fa-star-o'}></i>
          <i className={ratingScore > 1 ? 'fa fa-star' : 'fa fa-star-o'}></i>
          <i className={ratingScore > 2 ? 'fa fa-star' : 'fa fa-star-o'}></i>
          <i className={ratingScore > 3 ? 'fa fa-star' : 'fa fa-star-o'}></i>
          <i className={ratingScore > 4 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        </span>
      );

      return (
        <div className="col-12 col-md-6 col-lg-4" key={bar.id}>
          <Link to={'/venue/' + bar.slug}>
            <Card>
              <div className="upper">
                <img className="img-fluid" src={bar.cover_photo_url} alt={bar.name}/>
                <div className="tags">
                  {tags}
                </div>
              </div>
              <div className="lower">
                <b>{bar.name}</b>
                {rating}
                {bottomRightIndicator}
              </div>
            </Card>
          </Link>
        </div>
      );
    });

    // pagination
    let pagination = null;
    const numberOfPages = this.state.numberOfPages || 1;

    if (numberOfPages > 1) {
        
      const previous = this.state.pageIndex > 0 ? (
        <li className="page-item">
          <a className="page-link" href="#" onClick={(event) => this.loadPage(this.state.pageIndex - 1, event)}>
            <i className="fa fa-chevron-left"></i>
          </a>
        </li>
      ) : null;

      let pages = [];
      var hasSkipped = false;
      const curPageDelta = 2;
      for (let i = 0; i < numberOfPages; i++) {
        const isCurrentPage = i === this.state.pageIndex;
        const isCloseToCurrentPage = i > this.state.pageIndex - curPageDelta && i < this.state.pageIndex + curPageDelta;
        const classes = isCurrentPage ? 'page-link active' : 'page-link';


        // if we have many pages, skip the middle few ...
        if (!isCurrentPage && !isCloseToCurrentPage && numberOfPages > 5 && i > curPageDelta && i < numberOfPages - curPageDelta) {
          if (!hasSkipped) {
            pages.push(
              <li className="page-item gap" key={i}>
                ...
              </li>
            );
          }
          hasSkipped = true;
          continue;
        } else {
          if (this.state.pageIndex === i) {
            // allow another gap to show up
            hasSkipped = false;
          }
          pages.push(
            <li className="page-item" key={i}>
              <a className={classes} href="#" onClick={(event) => this.loadPage(i, event)}>
                {i+1}
              </a>
            </li>
          );
        }
      }

      const next = (this.state.pageIndex + 1) < numberOfPages ? (
        <li className="page-item">
          <a className="page-link" href="#" onClick={(event) => this.loadPage(this.state.pageIndex + 1, event)}>
            <i className="fa fa-chevron-right"></i>
          </a>
        </li>
      ) : null;

      pagination = (
        <div className="row">
          <div className="col-12">
            <nav>
              <ul className="pagination">
                {previous}
                {pages}
                {next}
              </ul>
            </nav>
          </div>
        </div>
      );
    }

    return (
      <div className="container list">
        <div className="row list-header">
          <div className="col-12 col-md-6">
            <h3>My Favorites</h3>
            <span className="username">
              <Link to="/account">
                {window.bvUser.full_name || ''}
              </Link>
            </span>
          </div>
          <div className="col-12 col-md-6 search">
            <input
              type="search"
              id="search"
              name="search"
              value={this.state.search}
              onChange={LinkState.bind(this)}
              onKeyDown={this.onSearchKeyDown.bind(this)}
              placeholder="Search..."
              className="form-control"
            />
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                Sort<span className="d-none d-lg-inline">: 
                {selectedSort}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => this.sortBy("featured")}>
                  Featured
                </Dropdown.Item>
                {localStorage.getItem("userAccess") && (
                  <Dropdown.Item onClick={(e) => this.sortBy("alphabetical")}>
                    Alphabetical
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={(e) => this.sortBy("rating")}>
                  Rating
                </Dropdown.Item>
                {this.state.AllowLocation == true && (
                <Dropdown.Item onClick={(e) => this.sortBy("distance")}>
                  Distance
                </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <LocationPage
        bars={this.state.bars}
        pageIndex={this.state.pageIndex}
        pageSize={pageSize}
        onDataChange={this.handleDataChange}
        title = {"favorites"}
        locations = {bars}
        pagination={pagination}
        />
      </div>
    )
  }
}