import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import './Admin.scss';
import { Link } from "react-router-dom";
import AdminChart from '../components/AdminChart/AdminChart';
import AdminSidebar from '../components/AdminSidebar';
import { LinkContainer } from 'react-router-bootstrap';
import User from '../img/user.jpg';
import { Redirect } from "react-router-dom";
import Sticky from 'react-stickynode';
import EditEventModal from '../components/modals/EditEventModal';
import DeleteEventModal from '../components/modals/DeleteEventModal';
import AddEventModal from '../components/modals/AddEventModal';
import UserListModal from '../components/modals/UserListModal';

import FullPageLoader from '../components/FullPageLoader';
import Actions from '../actions/api';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../helpers/link-state');
const parseQueryStringToDictionary = require('../helpers/parse-query');

let errorTimeout;
export default class AdminEvents extends Component {
  state = {
    selectedBar: window.bvUser.admin_bar_id ? window.bvUser.admin_bar_id : window.bvUser && window.bvUser.bar_memberships && window.bvUser.bar_memberships.length ? window.bvUser.bar_memberships[0].bar.id : '',
    showSlugBar: false,
    userNeedsToPay: !window.bvUser || !window.bvUser.super_admin,

    redirect: '',
    error: '',
    bar: null,
    processing: true,

    events: null,
    showUpcoming: true,

    userLikesPageIndex: 0,
    usersWhoLiked: 0,
    userLikesNumberOfPages: null,

    userDisLikesPageIndex: 0,
    usersWhoDisLiked: 0,
    userDisLikesNumberOfPages: null,

    userInterestPageIndex: 0,
    usersWhoHaveInterest: 0,
    userInterestNumberOfPages: null,
  }

  componentDidMount() {
    const query = parseQueryStringToDictionary(document.location.search);

    if (query.slug) {
      this.getBar(query.slug);
    } else if (this.state.selectedBar) {
      this.getBar();
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedBar !== prevState.selectedBar) {
      window.bvUser.admin_bar_id = this.state.selectedBar;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', this.state.selectedBar);
      }
      this.getBar();
    }
  }

  async goToLikesPage(id, pageIndex, event) {
    const barId = this.state.selectedBar;
    const pageSizeForUsersTable = 10;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const usersResponse = await Actions.get(`bars/admin/events/${id}/going/`, {
      going: 1,
      offset: pageIndex * pageSizeForUsersTable,
      limit: pageSizeForUsersTable,
    });

    
    const usersWhoLiked = usersResponse && usersResponse.results && usersResponse.results.length ? usersResponse.results : [];
    const userLikesNumberOfPages = Math.ceil(usersResponse.count / pageSizeForUsersTable);

    this.setState({
      userLikesPageIndex: pageIndex,
      usersWhoLiked,
      userLikesNumberOfPages,
    });
  }

  async goToDislikesPage(id, pageIndex, event) {
    const barId = this.state.selectedBar;
    const pageSizeForUsersTable = 10;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const usersResponse = await Actions.get(`bars/admin/events/${id}/going/`, {
      going: 0,
      offset: pageIndex * pageSizeForUsersTable,
      limit: pageSizeForUsersTable,
    });

    const usersWhoDisLiked = usersResponse && usersResponse.results && usersResponse.results.length ? usersResponse.results : [];
    const userDisLikesNumberOfPages = Math.ceil(usersResponse.count / pageSizeForUsersTable);

    this.setState({
      userDisLikesPageIndex: pageIndex,
      usersWhoDisLiked,
      userDisLikesNumberOfPages,
    });
  }

  async goToInterestsPage(id, pageIndex, event) {
    const barId = this.state.selectedBar;
    const pageSizeForUsersTable = 10;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const usersResponse = await Actions.get(`bars/admin/events/${id}/interested/`, {
      offset: pageIndex * pageSizeForUsersTable,
      limit: pageSizeForUsersTable,
    });

    const usersWhoHaveInterest = usersResponse && usersResponse.results && usersResponse.results.length ? usersResponse.results : [];
    const userInterestNumberOfPages = Math.ceil(usersResponse.count / pageSizeForUsersTable);

    this.setState({
      userInterestPageIndex: pageIndex,
      usersWhoHaveInterest,
      userInterestNumberOfPages,
    });
  }

  async getBar(slug) {
    this.setState({
      processing: true,
    });

    let barId = this.state.selectedBar;
    let bar;
    let showSlugBar = false;

    if (window.bvUser.super_admin && window.bvUser.admin_bar_id) {
      showSlugBar = true;
      barId = window.bvUser.admin_bar_id;
    }
    
    if (slug) {
      bar = await Actions.get(`bars/slug/${slug}/`);
      barId = bar.id;
      showSlugBar = true;

      window.bvUser.admin_bar_id = barId;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', barId);
      }
    }

    if (barId) {
      try {
        const bar = await Actions.get(`bars/${barId}/`);
        const events = await Actions.get('bars/events', {
          limit: 5000,
          bar: barId,
        });

        let userNeedsToPay = false;

        if (!window.bvUser.super_admin) {
          const billingInfo = await Actions.get(`bars/admin/bars/${barId}/billing/`);
          userNeedsToPay = !billingInfo || !billingInfo.subscription;
        }

        this.setState({
          bar,
          showSlugBar,
          selectedBar: barId,
          userNeedsToPay,
          events: events.results || [],
          processing: false,
        });
      } catch (ex) {
        this.setState({
          redirect: '/',
        });
      }
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }

  async doAddEvent(name, description, ticketUrl, start_time, end_time, fileBlobs, handleClose) {
    if (this.state.userNeedsToPay) {
      return;
    }

    const barId = this.state.selectedBar;
    try {

      const data = {
        name,
        description,
        purchase_ticket_link: ticketUrl,
        start_time,
        end_time,
        bar_id: barId,
      };

      if (fileBlobs && fileBlobs.length) {
        data.photos = [];

        const signedS3Meta = await Actions.get(`bars/${barId}/signed_upload_urls/`, {
          amount: fileBlobs.length,
        });
        for (let i = 0; i < fileBlobs.length; i++) {
          await Actions.saveFile(signedS3Meta[i].signed_url, fileBlobs[i]);

          data.photos.push({
            s3_bucket: signedS3Meta[i].s3_bucket,
            s3_path: signedS3Meta[i].s3_path,
          });
        }
      }

      const newEvent = await Actions.post(`bars/admin/events/`, data);

      if (newEvent) {
        // reload events b/c images are not properly showing here after saves
        this.getBar();
        // const events = this.state.events;
        // events.push(newEvent);

        // this.setState({
        //   events,
        // });
      }
      handleClose();
    } catch (ex) {
      console.log(ex);
      handleClose('Failed to add event. Please try again.');
    }
  }

  async doSaveEvent(id, name, description, ticketUrl, start_time, end_time, fileBlobs, deletedFiles, handleClose) {
    if (this.state.userNeedsToPay) {
      return;
    }

    const barId = this.state.selectedBar;
    try {
      const data = {
        name,
        description,
        purchase_ticket_link: ticketUrl,
        start_time,
        end_time,
        bar_id: barId,
      };

      if (deletedFiles && deletedFiles.length) {
        
        // delete the photos before we move on
        for (let i = 0; i < deletedFiles.length; i++) {
          await Actions.delete(`bars/admin/events/${id}/photos/${deletedFiles[i]}/`);
        }
      }

      if (fileBlobs && fileBlobs.length) {
        data.add_photos = [];

        const signedS3Meta = await Actions.get(`bars/${barId}/signed_upload_urls/`, {
          amount: fileBlobs.length,
        });
        for (let i = 0; i < fileBlobs.length; i++) {
          await Actions.saveFile(signedS3Meta[i].signed_url, fileBlobs[i]);

          data.add_photos.push({
            s3_bucket: signedS3Meta[i].s3_bucket,
            s3_path: signedS3Meta[i].s3_path,
          });
        }
      }

      const savedEvent = await Actions.patch(`bars/admin/events/${id}/`, data);

      if (savedEvent) {
        // reload events b/c images are not properly showing here after saves
        this.getBar();
        // const events = this.state.events;
        // const foundEvents = events.filter(u => u.id === id);

        // if (foundEvents.length) {
        //   foundEvents[0].name = savedEvent.name;
        //   foundEvents[0].description = savedEvent.description;
        //   foundEvents[0].url = savedEvent.url;
        // }

        // this.setState({
        //   events,
        // });
      }
      handleClose();
    } catch (ex) {
      handleClose('Failed to save event. Please try again.');
    }
  }

  async doDeleteEvent(id, handleClose) {
    if (this.state.userNeedsToPay) {
      return;
    }

    try {
      await Actions.delete(`bars/admin/events/${id}/`);
      const events = this.state.events.filter(u => u.id !== id);

      this.setState({
        events,
      });
      handleClose();
    } catch (ex) {
      handleClose('Failed to remove event. Please try again.');
    }
  }

  render () {

    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader/>;
    }

    const authorizedUser = window.bvUser;

    const numberOfBars = authorizedUser.bar_memberships.length;

    const { 
      bar,
      events,
      error,
      userNeedsToPay,

      userLikesPageIndex,
      usersWhoLiked,
      userLikesNumberOfPages,

      userDisLikesPageIndex,
      usersWhoDisLiked,
      userDisLikesNumberOfPages,

      userInterestPageIndex,
      usersWhoHaveInterest,
      userInterestNumberOfPages,

      showSlugBar,
    } = this.state;

    const barOptions = showSlugBar ? (<option key={bar.id} value={bar.id}>{bar.name}</option>) : (numberOfBars > 1 ? authorizedUser.bar_memberships.map(membership => {
      return (
        <option key={membership.bar.id} value={membership.bar.id}>{membership.bar.name}</option>
      );
    }) : null);

    const billingLink = `/admin/billing?slug=${bar.slug}`;
    const payAlert = userNeedsToPay ? (
      <Alert variant="info billing-alert">
        To start your free trial, <a href={billingLink}>click here to enter your billing information</a>. You will be unable to edit venue information until you do.
      </Alert>
    ) : null;

    const now = new Date();
    const year = now.getFullYear();

    let filteredEvents = null;

    if (this.state.showUpcoming) {
      filteredEvents = events.filter(e => new Date(e.start_time) > now);
    } else {
      filteredEvents = events.filter(e => new Date(e.start_time) <= now && new Date(e.start_time).getFullYear() == year);
    }

    const barSelection = numberOfBars > 1 || showSlugBar ? (
      <div className="form-group venue-select">
        <div className="bv-select">
          <select
            id="selectedBar"
            name="selectedBar"
            value={this.state.selectedBar}
            onChange={LinkState.bind(this)}
            className="custom-select">
            {barOptions}
          </select>
          <i className="fa fa-caret-down"></i>
        </div>
      </div>
    ) : null;

    let alert = null;

    if (error) {
      alert = (
        <Alert variant="danger">
          {error}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    const ratingScore = bar.rating || 0;
    const rating = (
      <span className="rating">
        <i className={ratingScore > 0 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 1 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 2 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 3 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 4 ? 'fa fa-star' : 'fa fa-star-o'}></i>
      </span>
    );

    let eventsTable = null;

    if (filteredEvents && filteredEvents.length) {

      const eventRows = filteredEvents.map(evt => {

        let startTime = new Date(evt.start_time);
        let eventTimeStr = `${startTime.toLocaleDateString()} -  ${startTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;

        return (
          <tr key={evt.id}>
            <td>
              {evt.name}
            </td>
            <td>
              {eventTimeStr}
            </td>
            <td>
              <UserListModal
                title="Going"
                total={evt.going || 0}
                users={usersWhoLiked}
                goToPage={(pageIndex, event) => this.goToLikesPage(evt.id, pageIndex, event)}
                numberOfPages={userLikesNumberOfPages}
                pageIndex={userLikesPageIndex} />
            </td>
            <td>
              <UserListModal
                title="Not Going"
                total={evt.not_going || 0}
                users={usersWhoDisLiked}
                goToPage={(pageIndex, event) => this.goToDislikesPage(evt.id, pageIndex, event)}
                numberOfPages={userDisLikesNumberOfPages}
                pageIndex={userDisLikesPageIndex} />
            </td>
            <td>
              <UserListModal
                title="Interested"
                total={evt.interested || 0}
                users={usersWhoHaveInterest}
                goToPage={(pageIndex, event) => this.goToInterestsPage(evt.id, pageIndex, event)}
                numberOfPages={userInterestNumberOfPages}
                pageIndex={userInterestPageIndex} />
            </td>
            <td className="text-right">
              <ul className="action-buttons">
                <li>
                  { userNeedsToPay ? null : <EditEventModal event={evt} doSaveEvent={this.doSaveEvent.bind(this)} /> }
                </li>
                <li>
                  { userNeedsToPay ? null : <DeleteEventModal event={evt} doDeleteEvent={this.doDeleteEvent.bind(this)} /> }
                </li>
              </ul>
            </td>
          </tr>
        );
      });

      eventsTable = (
        <table className="table bv-table">
          <thead>
            <tr>
              <th>Event Name</th>
              <th>Event Date</th>
              <th>Going</th>
              <th>Not Going</th>
              <th>Interested</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {eventRows}
          </tbody>
        </table>

      );

    } else {
      eventsTable = (
        <div className="col-12 col-md-6">
          <p>This bar does not have any events</p>
        </div>
      );
    }

    const upcomingButtonClasses = this.state.showUpcoming ? 'nav-item nav-link active' : 'nav-item nav-link';
    const pastButtonClasses = this.state.showUpcoming ? 'nav-item nav-link' : 'nav-item nav-link active';

    return (
      <div className="admin container-fluid">
        {payAlert}
        {alert}
        <div className="admin-sidebar">
          <div className="d-none d-lg-block">
            <Sticky enabled={true} top={100}>
              <AdminSidebar />
            </Sticky>
          </div>
          <div className="d-lg-none">
            <AdminSidebar />
          </div>
        </div>
        <div className="admin-content">
          <div className="row admin-header">
            <div className="col-12 col-md-5">
              <h2>Events</h2>
              <p>
                <span className="venue">{bar.name}</span>
                {rating}
              </p>
            </div>
            <div className="col-12 col-md-7 text-right">
              {barSelection}

              <div className="admin-add-button">
                { userNeedsToPay ? null : <AddEventModal doAddEvent={this.doAddEvent.bind(this)} /> }
              </div>
            </div>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-12">
              <nav className="nav nav-tabs" role="tablist">
                <button 
                  type="button"
                  onClick={ () => this.setState({ showUpcoming: true }) }
                  className={upcomingButtonClasses}>Upcoming Events</button>
                <button
                  type="button"
                  onClick={ () => this.setState({ showUpcoming: false }) }
                  className={pastButtonClasses}>Past Events</button>
              </nav>
              {eventsTable}
            </div>
          </div>
        </div>
      </div>
    )
  }
}