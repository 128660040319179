import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ButtonLoader from '../ButtonLoader';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../../helpers/link-state');

let errorTimeout;
class EditSpecialModal extends Component {
  state = {
    show: false,
    name: '',
    description: '',
    
    file: '',
    fileBlob: null,
    error: '',
    processing: false,
    deleted: false,
  }

  handleClose() {
    this.setState({
      show: false,
      name: '',
      description: '',
      
      file: '',
      fileBlob: null,
      error: '',
      processing: false,
      deleted: false,
    });
  }

  handleShow() {
    this.setState({
      show: true,
      name: this.props.special.name || '',
      description: this.props.special.description || '',
    });
  }

  doSaveSpecial() {

    if (!this.state.name) {
      return this.setState({
        error: 'Please set a name',
      });
    }
    
    if (this.state.deleted && !this.state.fileBlob) {
      return this.setState({
        error: 'Please add a photo',
      });
    }

    this.setState({
      processing: true,
    });

    this.props.doSaveSpecial(this.props.special.id, this.state.name, this.state.description, this.state.fileBlob, error => {
      if (error) {
        this.setState({
          processing: false,
          error,
        });
      } else {
        this.handleClose();
      }
    });
  }

  onSetFile(evt) {
    if (evt.target.files.length) {
      this.setState({
        fileBlob: evt.target.files[0],
        file: evt.currentTarget.value,
      });
    } else {
      this.setState({
        fileBlob: null,
        file: '',
      });
    }
  }

  doRemovePhoto() {
    this.setState({
      deleted: true,
    });
  }

  render() {
    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    const buttonText = this.state.processing ? (
      <ButtonLoader />
    ) : (
      <span>Save</span>
    );

    const currentPhoto = this.state.deleted || !this.props.special.url ? (
      <div className="form-group">
        <input
          type="file"
          id="file"
          name="file"
          value={this.state.file}
          onChange={this.onSetFile.bind(this)}
          className="form-control" />
      </div>
    ) : (
      <div className="row photo-gallery">
        <div className="col-lg-3 col-md-4 col-6 photo">
          <img className="img-fluid img-thumbnail mb-4" src={this.props.special.url} alt="" />

          <button
            type="button"
            onClick={this.doRemovePhoto.bind(this)}
            className="close-button">
            <i className="fa fa-times"></i>
          </button>
        </div>
      </div>
    );

    return (
      <>
        <Button className="txt-btn" onClick={this.handleShow.bind(this)}>
          Edit
        </Button>

        <Modal
          show={this.state.show}
          onHide={this.handleClose.bind(this)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit "{this.props.special.name}"</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alert}
            <div className="col-12">
              <div className="form-group">
                <label for="name">Special Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={this.state.name}
                  onChange={LinkState.bind(this)}
                  className="form-control"
                  placeholder="Special Name..." />
              </div>
              <div className="form-group mt-3">
                <label for="description">Special Description:</label>
                <textarea 
                  id="description"
                  name="description"
                  value={this.state.description}
                  onChange={LinkState.bind(this)}
                  className="form-control height-250"
                  placeholder="Enter a description..."></textarea>
              </div>
              <h4>Photo</h4>
              {currentPhoto}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="ghost" onClick={this.handleClose.bind(this)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.doSaveSpecial.bind(this)}>
              {buttonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default (EditSpecialModal);
