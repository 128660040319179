import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './MarketingHero.scss';
import AppleStore from '../img/apple-store.png';
import GooglePlay from '../img/google-play.png';

export default class MarketingHero extends Component {
  render () {
      return (
        <section className="marketing-hero">
          <div className="marketing-hero__overlay">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="vertical-center">
                    <h1>Choose Your View.</h1>
                    <p>
                      Connect to live bar and restaurant camera feeds. Virtually view environments. Receieve real-time updates from your favorite venues. Browse for events, food and drink specials all at once!
                    </p>
                    <div className="app-store-buttons">
                      <a href={'https://apps.apple.com/us/app/id1514056252'} className="btn btn--primary btn--large">
                        <div className="left">
                          <i className="fab fa-apple"></i>
                        </div>
                        <div className="right">
                          <label>Available on the</label>
                          <span>App Store</span>
                        </div>
                      </a>
                      <a href={'https://play.google.com/store/apps/details?id=com.neuralcloud.barview'} className="btn btn--primary btn--large">
                        <div className="left">
                          <i className="fab fa-google-play"></i>
                        </div>
                        <div className="right">
                          <label>Get it on</label>
                          <span>Google Play</span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="marketing-hero__scroll-indicator">
                    <i className="fa fa-chevron-down"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
   }
}
