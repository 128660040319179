import React, { Component } from 'react';
import Actions from '../actions/api';

export default class Logout extends Component {
  state = {}

  componentDidMount() {
    localStorage.clear();
    window.bvUser = null;
    window.location.href = '/';
  }

  render () {
    return (<div/>);
  }
}