import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ButtonLoader from '../ButtonLoader';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../../helpers/link-state');

let errorTimeout;
class EditCameraModal extends Component {
  state = {
    error: '',
    processing: false,

    show: false,
    label: '',
    status: 'on',
    schedule: {
      sunday_on: '18:00:00',
      sunday_off: '23:00:00',
      monday_on: '18:00:00',
      monday_off: '23:00:00',
      tuesday_on: '18:00:00',
      tuesday_off: '23:00:00',
      wednesday_on: '18:00:00',
      wednesday_off: '23:00:00',
      thursday_on: '18:00:00',
      thursday_off: '23:00:00',
      friday_on: '18:00:00',
      friday_off: '23:00:00',
      saturday_on: '18:00:00',
      saturday_off: '23:00:00',
    },
  }

  handleClose() {
    this.setState({
      error: '',
      processing: false,

      show: false,
      label: '',
      status: 'on',
      schedule: {
        sunday_on: '18:00:00',
        sunday_off: '23:00:00',
        monday_on: '18:00:00',
        monday_off: '23:00:00',
        tuesday_on: '18:00:00',
        tuesday_off: '23:00:00',
        wednesday_on: '18:00:00',
        wednesday_off: '23:00:00',
        thursday_on: '18:00:00',
        thursday_off: '23:00:00',
        friday_on: '18:00:00',
        friday_off: '23:00:00',
        saturday_on: '18:00:00',
        saturday_off: '23:00:00',
      },
    });
  }

  handleShow() {
    this.setState({
      error: '',
      processing: false,

      show: true,
      label: this.props.camera.label,
      status: this.props.camera.status,
      schedule: this.props.camera.schedule || {
        sunday_on: '18:00:00',
        sunday_off: '23:00:00',
        monday_on: '18:00:00',
        monday_off: '23:00:00',
        tuesday_on: '18:00:00',
        tuesday_off: '23:00:00',
        wednesday_on: '18:00:00',
        wednesday_off: '23:00:00',
        thursday_on: '18:00:00',
        thursday_off: '23:00:00',
        friday_on: '18:00:00',
        friday_off: '23:00:00',
        saturday_on: '18:00:00',
        saturday_off: '23:00:00',
      },
    });
  }

  doSaveCamera() {

    if (!this.state.label) {
      return this.setState({
        error: 'Please set a label',
      });
    }

    this.setState({
      processing: true,
    });

    this.props.doSaveCamera(this.props.camera.id, this.state.label, this.state.status, this.state.schedule, error => {
      if (error) {
        this.setState({
          processing: false,
          error,
        });
      } else {
        this.handleClose();
      }
    });
  }

  render() {

    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    const buttonText = this.state.processing ? (
      <ButtonLoader />
    ) : (
      <span>Save</span>
    );

    const optionsList = (
      <>
        <option value="06:00:00">06:00 AM</option>
        <option value="06:30:00">06:30 AM</option>
        <option value="07:00:00">07:00 AM</option>
        <option value="07:30:00">07:30 AM</option>
        <option value="08:00:00">08:00 AM</option>
        <option value="08:30:00">08:30 AM</option>
        <option value="09:00:00">09:00 AM</option>
        <option value="09:30:00">09:30 AM</option>
        <option value="10:00:00">10:00 AM</option>
        <option value="10:30:00">10:30 AM</option>
        <option value="11:00:00">11:00 AM</option>
        <option value="11:30:00">11:30 AM</option>
        <option value="12:00:00">Noon</option>
        <option value="12:30:00">12:30 PM</option>
        <option value="13:00:00">1:00 PM</option>
        <option value="13:30:00">1:30 PM</option>
        <option value="14:00:00">2:00 PM</option>
        <option value="14:30:00">2:30 PM</option>
        <option value="15:00:00">3:00 PM</option>
        <option value="15:30:00">3:30 PM</option>
        <option value="16:00:00">4:00 PM</option>
        <option value="16:30:00">4:30 PM</option>
        <option value="17:00:00">5:00 PM</option>
        <option value="17:30:00">5:30 PM</option>
        <option value="18:00:00">6:00 PM</option>
        <option value="18:30:00">6:30 PM</option>
        <option value="19:00:00">7:00 PM</option>
        <option value="19:30:00">7:30 PM</option>
        <option value="20:00:00">8:00 PM</option>
        <option value="20:30:00">8:30 PM</option>
        <option value="21:00:00">9:00 PM</option>
        <option value="21:30:00">9:30 PM</option>
        <option value="22:00:00">10:00 PM</option>
        <option value="22:30:00">10:30 PM</option>
        <option value="23:00:00">11:00 PM</option>
        <option value="23:30:00">11:30 PM</option>
        <option value="00:00:00">Midnight</option>
        <option value="00:30:00">12:30 AM</option>
        <option value="01:00:00">01:00 AM</option>
        <option value="01:30:00">01:30 AM</option>
        <option value="02:00:00">02:00 AM</option>
        <option value="02:30:00">02:30 AM</option>
        <option value="03:00:00">03:00 AM</option>
        <option value="03:30:00">03:30 AM</option>
        <option value="04:00:00">04:00 AM</option>
        <option value="04:30:00">04:30 AM</option>
        <option value="05:00:00">05:00 AM</option>
        <option value="05:30:00">05:30 AM</option>
      </>
    );

    const schedule = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => {

      const dayKey = day.toLowerCase();
      const onKey = `${dayKey}_on`;
      const offKey = `${dayKey}_off`;

      const onTime = this.state.schedule[onKey] || '';
      const offTime = this.state.schedule[offKey] || '';

      return (
        <div className="form-group row" key={day}>
          <label className="col-sm-2 col-form-label">{day}:</label>
          <div className="col-sm-10">
            <div className="form-row">
              <div className="col">
                <div className="bv-select inline">
                  <label className="form-label sr-only">Opening Time</label>
                  <select
                    id={onKey}
                    name={`schedule.${onKey}`}
                    value={this.state.schedule[onKey]}
                    onChange={LinkState.bind(this)}
                    className="custom-select">
                    {optionsList}
                  </select>
                  <i className="fa fa-caret-down"></i>
                </div>
              </div>
              <div className="col">
                <div className="bv-select inline">
                  <select 
                    id={offKey}
                    name={`schedule.${offKey}`}
                    value={this.state.schedule[offKey]}
                    onChange={LinkState.bind(this)}
                    className="custom-select">
                    <label className="form-label sr-only" for="State">Closing Time</label>
                    {optionsList}
                  </select>
                  <i className="fa fa-caret-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <>
        <Button className="txt-btn" onClick={this.handleShow.bind(this)}>
          Edit
        </Button>

        <Modal
          show={this.state.show}
          onHide={this.handleClose.bind(this)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit {this.props.camera.label || 'Camera'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alert}
            <form>
              <div className="form-group">
                <label><b>Camera Name:</b></label>
                <input
                  type="text"
                  id="label"
                  name="label"
                  value={this.state.label}
                  onChange={LinkState.bind(this)}
                  className="form-control"
                  placeholder="Camera Name" />
              </div>

              <div className="form-group">
                <label htmlFor="userType">Status:</label>
                <div className="bv-select inline">
                  <select
                    id="status"
                    name="status"
                    value={this.state.status}
                    onChange={LinkState.bind(this)}
                    className="custom-select">
                    <option value="on">On</option>
                    <option value="off">Off</option>
                    <option value="schedule">Use Schedule</option>
                  </select>
                  <i className="fa fa-caret-down"></i>
                </div>
              </div>

              <label className="d-block mt-4 mb-4"><b>Camera Schedule:</b></label>
              <p className="offset-explainer">Note: This schedule will only be used if you select "Use Schedule" from status dropdown above.
              If the time in the second column is earlier or the same as the time in the first, the camera feed will not show at all on this day.</p>
              {schedule}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="ghost" onClick={this.handleClose.bind(this)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.doSaveCamera.bind(this)}>
              {buttonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default (EditCameraModal);