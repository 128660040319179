import React, { Component } from 'react';
import Actions from '../actions/api';
import Alert from 'react-bootstrap/Alert';
import { Redirect, useHistory } from "react-router-dom";
import ButtonLoader from '../components/ButtonLoader';

let alert = null;
let result = null;
export default class NewsletterConfirm extends Component {
  
  state = {
    processing: true, 
    success: '',
    redirect: ''
  }
  componentDidMount() {
    const token = this.props.match.params.id;
    
    Actions.put('users/newsletter/confirm-subscription/'+token+'/').then(response => {
      alert = (
        <Alert variant="success">
          Thank you. Your email has been confirmed successfully. 
        </Alert>
      );
      
      this.setState({
        processing: false, 
        success: true, 
      });


      setTimeout(() => {
        this.setState({
          redirect: '/',
        });
      }, 3e3);
      
    }).catch(ex => {
      this.setState({
        redirect: '/',
      });
    });
  }

  render () {
    
    if (this.state.processing) {
        result = (<ButtonLoader />)
    } else {
        result = alert
    }

    return (
        <div>
          {result}
          {this.state.redirect &&
          <Redirect to={this.state.redirect} /> }
          
        </div>
    )
  }
}