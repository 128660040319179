import React, { Component } from 'react';
import Card from '../components/Card';
import {
  FacebookShareButton,
  PinterestShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

export default class SidebarContent extends Component {
  
  getTimeStrings(openTime, closeTime) {

    if (!openTime || !closeTime) {
      return 'Closed';
    }

    const open = new Date();
    const openParts = openTime.split(':');

    open.setHours(parseInt(openParts[0]));
    open.setMinutes(parseInt(openParts[1]));
    open.setSeconds(parseInt(openParts[2]));

    const close = new Date();
    const closeParts = closeTime.split(':');

    close.setHours(parseInt(closeParts[0]));
    close.setMinutes(parseInt(closeParts[1]));
    close.setSeconds(parseInt(closeParts[2]));

    const openStr = open.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});
    const closeStr = close.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'});

    return `${openStr} - ${closeStr}`;
  }


  render () {

    const shareUrl = this.props.bar.web_url;

    let map = null;
    if (this.props.bar.address) {
      // const mapAPIKey = '';
      // const mapUrl = `https://www.google.com/maps/embed/v1/view?key=${mapAPIKey}&center=${this.props.bar.location.latitude},${this.props.bar.location.longitude}&zoom=15`;

      const mapUrl = `https://maps.google.com/maps?q=${this.props.bar.name}%20${this.props.bar.address.locality}&t=&z=15&ie=UTF8&iwloc=&output=embed`;

      map = (
        <div className="map-responsive">
          <div className="gmap_canvas">
            <iframe
              width="600"
              height="500"
              id="gmap_canvas"
              src={mapUrl}
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              title="map"
              >
            </iframe>
          </div>
        </div>
      );
    }

    const businessName = this.props.bar.name;
    const addressLine1 = this.props.bar && this.props.bar.address ? this.props.bar.address.street_address : '';
    const addressLine2 = this.props.bar && this.props.bar.address ? (`${this.props.bar.address.locality}, ${this.props.bar.address.region}`) : '';

    const email = this.props.bar.email_address ? (
      <span><b>Email:</b> <a href={'mailto:'+this.props.bar.email_address}>{this.props.bar.email_address}</a><br /></span>
    ) : null;

    const phone = this.props.bar.phone_number ? (
      <span><b>Phone:</b> <a href={'tel:'+this.props.bar.phone_number}>{this.props.bar.phone_number}</a><br /></span>
    ) : null;


    const web = this.props.bar.website ? (
      <span><b>Website:</b> <a target="_blank" href={ this.props.bar.website }>{this.props.bar.website}</a><br /></span>
    ) : null;

    const onlineOrder = this.props.bar.online_ordering_url ? (
      <span><b>Order Online:</b> <a target="_blank" href={ this.props.bar.online_ordering_url }>{ this.props.bar.online_ordering_url }</a><br /></span>
    ) : null;

    let hours = null;
    if (this.props.bar.hours_of_operation) {
      const sunday = this.getTimeStrings(this.props.bar.hours_of_operation.sunday_open, this.props.bar.hours_of_operation.sunday_close);
      const monday = this.getTimeStrings(this.props.bar.hours_of_operation.monday_open, this.props.bar.hours_of_operation.monday_close);
      const tuesday = this.getTimeStrings(this.props.bar.hours_of_operation.tuesday_open, this.props.bar.hours_of_operation.tuesday_close);
      const wednesday = this.getTimeStrings(this.props.bar.hours_of_operation.wednesday_open, this.props.bar.hours_of_operation.wednesday_close);
      const thursday = this.getTimeStrings(this.props.bar.hours_of_operation.thursday_open, this.props.bar.hours_of_operation.thursday_close);
      const friday = this.getTimeStrings(this.props.bar.hours_of_operation.friday_open, this.props.bar.hours_of_operation.friday_close);
      const saturday = this.getTimeStrings(this.props.bar.hours_of_operation.saturday_open, this.props.bar.hours_of_operation.saturday_close);

      hours = (
        <Card>
          <div className="address">
            <h5><i className="fa fa-clock-o"></i> Venue Hours</h5>
            <ul className="leaders">
              <li>
                <span>
                  Sun:
                </span>
                <span>
                  {sunday}
                </span>
              </li>
              <li>
                <span>
                  Mon:
                </span>
                <span>
                  {monday}
                </span>
              </li>
              <li>
                <span>
                  Tue:
                </span>
                <span>
                  {tuesday}
                </span>
              </li>
              <li>
                <span>
                  Wed:
                </span>
                <span>
                  {wednesday}
                </span>
              </li>
              <li>
                <span>
                  Thu:
                </span>
                <span>
                  {thursday}
                </span>
              </li>
              <li>
                <span>
                  Fri:
                </span>
                <span>
                  {friday}
                </span>
              </li>
              <li>
                <span>
                  Sat:
                </span>
                <span>
                  {saturday}
                </span>
              </li>
            </ul>
          </div>
        </Card>
      );
    }

    let features = null;
    if (this.props.bar.venue_features && this.props.bar.venue_features.length) {

      let index = 0;
      const featureItems = this.props.bar.venue_features.map(feature => {
        return (
          <li key={index++}>{feature.name}</li>
        );
      });

      features = (
        <Card>
          <div className="address">
            <h5><i className="fa fa-building"></i> Venue Features</h5>
            <ul>
              {featureItems}
            </ul>
          </div>
        </Card>
      );
    }

    return (
      <div>
        {map}
        <Card>
          <div className="address">
            <h5><i className="fa fa-map-marker"></i> Location</h5>
            <p>
              <b><a>{businessName}</a></b><br />
              {addressLine1}<br />
              {addressLine2}
            </p>
            <hr />
            <h5><i className="fa fa-envelope-o"></i> Contact</h5>
            <p>
              {email}
              {phone}
              {web}
              {onlineOrder}
            </p>
          </div>
        </Card>
        {hours}
        {features}
        <Card>
          <div className="address share-buttons">
            <h5><i className="fa fa-share-alt"></i> Share This!</h5>
            <FacebookShareButton url={shareUrl}>
              <i className="fa fa-facebook"></i>
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl}>
              <i className="fa fa-twitter"></i>
            </TwitterShareButton>
            <PinterestShareButton url={shareUrl}>
              <i className="fa fa-pinterest"></i>
            </PinterestShareButton>
            <RedditShareButton url={shareUrl}>
              <i className="fa fa-reddit"></i>
            </RedditShareButton>
            <WhatsappShareButton url={shareUrl}>
              <i className="fa fa-whatsapp"></i>
            </WhatsappShareButton>
          </div>
        </Card>
      </div>
    )
  }
}
