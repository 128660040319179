import React, { Component } from "react";
import "./Specials.scss";
import Bar1 from "../img/bar-1.jpg";
import Bar2 from "../img/bar-2.jpg";
import Bar3 from "../img/bar-3.jpg";
import Bar4 from "../img/bar-4.jpg";
import Bar5 from "../img/bar-5.jpg";
import Bar6 from "../img/bar-6.jpg";
import { Link } from "react-router-dom";
import LocationFilterModal from "../components/modals/LocationFilterModal";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import GoogleMap from "../components/GoogleMap";
import { Redirect } from "react-router-dom";
import Actions from "../actions/api";
import LocationPage from "./LocationPage";
import FullPageLoader from "../components/FullPageLoader";

const LinkState = require("../helpers/link-state");

const pageSize = 21;
export default class Specials extends Component {
  state = {
    specials: null,
    processing: true,

    pageIndex: 0,
    numberOfPages: 0,

    error: "",

    latitude: 0,
    longitude: 0,
    sort: "featured",
    search: "",
    filter_state: "",
    filter_city: "",
    locationLabel: "",
    mapVisible: false,
    AllowLocation: false,
  };

  async getLocations(latitude,longitude){
    let locationData = {};
    let city = "";
    let state = "";
    locationData = await Actions.get('bars/location/', {
      latitude,
      longitude
    });
    state = locationData && locationData.state ? ` ${locationData.state}` : '';
    city = locationData && locationData.city ? ` ${locationData.city}` : '';
    try{
      this.getSpecials(0, null, state, city, null, window.bvCoords);
    }catch(error){
      this.getSpecials(0, null, null, null, null, window.bvCoords);
    }
  }

  componentDidMount() {
    if (!window.bvCoords) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position && position.coords) {
            this.setState({AllowLocation:true})
            window.bvCoords = position.coords;
          }
          this.getLocations(window.bvCoords.latitude,window.bvCoords.longitude)
        },
        (error) => {
          if(error){
            this.setState({AllowLocation:false})
          }
          this.getLocations(null,null)
        }
      );
    }else{
      this.setState({AllowLocation:true})
      this.getLocations(window.bvCoords.latitude,window.bvCoords.longitude)
    }
  }

  filterChanged(state, city) {
    this.getSpecials(0, null, state, city, null);
  }

  onSearchKeyDown(evt) {
    // trigger search on enter
    if (evt.key === "Enter") {
      this.getSpecials(0);
    }
  }

  sortBy(sort) {
    this.getSpecials(0, null, null, null, sort);
  }

  async loadPage(pageIndex, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    await this.getSpecials(pageIndex);
  }

  async getSpecials(
    pageIndex,
    search,
    filter_state,
    filter_city,
    sort,
    coords
  ) {
    const st = {
      processing: true,
      sort: this.state.sort,
      search: this.state.search,
      filter_state: this.state.filter_state,
      filter_city: this.state.filter_city,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };

    if (search) {
      st.search = search;
    }

    if (filter_state) {
      st.filter_state = filter_state;
    } else {
      st.filter_state = "";
    }

    if (filter_city) {
      st.filter_city = filter_city;
    } else {
      st.filter_city = "";
    }

    if (coords) {
      st.latitude = coords.latitude;
      st.longitude = coords.longitude;
    }

    if (sort) {
      st.sort = sort;
    }

    this.setState(st);

    const params = {
      offset: pageIndex * pageSize,
      limit: pageSize,
    };

    if (st.filter_state || st.filter_city) {
      params.filter_state = st.filter_state;
      params.filter_city = st.filter_city;
    } else if (st.latitude || st.longitude) {
      params.latitude = st.latitude;
      params.longitude = st.longitude;
    }

    if (st.search) {
      params.search = st.search;
    }

    if (st.sort) {
      params.sort = st.sort;
    }

    const locationParams = {};
    let locationData = {};

    if (st.filter_state || st.filter_city) {
      locationParams.state = params.filter_state;
      locationParams.city = params.filter_city;
      locationData.state = params.filter_state;
      locationData.city = params.filter_city;
    } else if (params.latitude) {
      locationParams.latitude = params.latitude;
      locationParams.longitude = params.longitude;
      locationData = await Actions.get("bars/location/", locationParams);
    } else {
      locationData = await Actions.get("bars/location/", null);
    }

    const state =
      locationData && locationData.state ? ` ${locationData.state}` : "";
    const locationLabel =
      ` near ` +
      (locationData && locationData.city
        ? ` ${locationData.city}, ${state}`
        : `${state} State`);

    Actions.get("bars/specials/", params)
      .then((results) => {
        const specials = results.results || [];
        const numberOfPages = Math.ceil(results.count / pageSize);
        this.setState({
          specials,
          processing: false,
          numberOfPages,
          pageIndex,
          locationLabel,
        });
      })
      .catch((ex) => {
        this.setState({
          specials: [],
          processing: false,
        });
      });
  }

  doLikeSpecial(event, special) {
    event.preventDefault();
    event.stopPropagation();

    if (special.i_liked === true) {
      return;
    }

    Actions.post(`bars/specials/${special.id}/rate/`, {
      like: true,
    })
      .then((res) => {
        const specials = this.state.specials;
        const index = specials.findIndex((i) => i.id === special.id);

        if (!specials[index].likes) {
          specials[index].likes = 0;
        }
        if (!specials[index].dislikes) {
          specials[index].dislikes = 0;
        }

        specials[index].likes++;

        if (specials[index].i_liked === false) {
          specials[index].dislikes--;
        }

        specials[index].i_liked = true;

        this.setState({
          specials,
        });
      })
      .catch((error) => {});
  }

  doDislikeSpecial(event, special) {
    event.preventDefault();
    event.stopPropagation();
    if (special.i_liked === false) {
      return;
    }
    Actions.post(`bars/specials/${special.id}/rate/`, {
      like: false,
    })
      .then((res) => {
        const specials = this.state.specials;
        const index = specials.findIndex((i) => i.id === special.id);

        if (!specials[index].likes) {
          specials[index].likes = 0;
        }
        if (!specials[index].dislikes) {
          specials[index].dislikes = 0;
        }

        specials[index].dislikes++;

        if (specials[index].i_liked === true) {
          specials[index].likes--;
        }

        specials[index].i_liked = false;

        this.setState({
          specials,
        });
      })
      .catch((error) => {});
  }

  render() {
    const loggedIn = !!window.bvUser;

    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader />;
    }

    const specialsList =
      this.state.specials && this.state.specials.length ? (
        this.state.specials.map((special) => {
          const ratingScore = special.bar.rating || 0;
          const rating = (
            <span className="rating">
              <i
                className={ratingScore > 0 ? "fa fa-star" : "fa fa-star-o"}
              ></i>
              <i
                className={ratingScore > 1 ? "fa fa-star" : "fa fa-star-o"}
              ></i>
              <i
                className={ratingScore > 2 ? "fa fa-star" : "fa fa-star-o"}
              ></i>
              <i
                className={ratingScore > 3 ? "fa fa-star" : "fa fa-star-o"}
              ></i>
              <i
                className={ratingScore > 4 ? "fa fa-star" : "fa fa-star-o"}
              ></i>
            </span>
          );

          const specialLikes = special.likes ? `+${special.likes}` : "0";
          const specialDislikes = special.dislikes
            ? `-${special.dislikes}`
            : "0";

          const userLikedSpecial = special.i_liked === true;
          const userDisLikedEvent = special.i_liked === false;

          const authButtons = loggedIn ? (
            <div className="buttons">
              <Button
                type="button"
                variant="error"
                onClick={(evt) => this.doDislikeSpecial(evt, special)}
                disabled={userDisLikedEvent}
              >
                <i className="fa fa-thumbs-down"></i>
                <span>{specialDislikes}</span>
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={(evt) => this.doLikeSpecial(evt, special)}
                disabled={userLikedSpecial}
              >
                <i className="fa fa-thumbs-up"></i>
                <span>{specialLikes}</span>
              </Button>
            </div>
          ) : null;

          return (
            <li key={special.id}>
              <Link to={`/venue/${special.bar.slug}`}>
                <div
                  className="background-image"
                  style={{
                    backgroundImage: `url(${special.bar.cover_photo_url})`,
                  }}
                ></div>
                <div className="right">
                  <h4>{special.name}</h4>
                  <p>{special.bar.name}</p>
                  {rating}
                </div>
                {authButtons}
              </Link>
            </li>
          );
        })
      ) : (
        <div className="no-results">
          <p>No specials found</p>
        </div>
      );

      let selectedSort = "Featured";

      if (this.state.sort === "alphabetical") {
        selectedSort = "Alphabetical";
      } else if (this.state.sort === "rating") {
        selectedSort = "Rating";
      } else if (this.state.sort === "distance") {
        selectedSort = "Distance";
      }else if (this.state.sort === 'featured') {
        selectedSort = 'Featured';
      }

    // pagination
    let pagination = null;
    const numberOfPages = this.state.numberOfPages || 1;

    if (numberOfPages > 1) {
      const previous =
        this.state.pageIndex > 0 ? (
          <li className="page-item">
            <a
              className="page-link"
              href="#"
              onClick={(event) =>
                this.loadPage(this.state.pageIndex - 1, event)
              }
            >
              <i className="fa fa-chevron-left"></i>
            </a>
          </li>
        ) : null;

      let pages = [];
      var hasSkipped = false;
      const curPageDelta = 2;
      for (let i = 0; i < numberOfPages; i++) {
        const isCurrentPage = i === this.state.pageIndex;
        const isCloseToCurrentPage =
          i > this.state.pageIndex - curPageDelta &&
          i < this.state.pageIndex + curPageDelta;
        const classes = isCurrentPage ? "page-link active" : "page-link";

        // if we have many pages, skip the middle few ...
        if (
          !isCurrentPage &&
          !isCloseToCurrentPage &&
          numberOfPages > 5 &&
          i > curPageDelta &&
          i < numberOfPages - curPageDelta
        ) {
          if (!hasSkipped) {
            pages.push(
              <li className="page-item gap" key={i}>
                ...
              </li>
            );
          }
          hasSkipped = true;
          continue;
        } else {
          if (this.state.pageIndex === i) {
            // allow another gap to show up
            hasSkipped = false;
          }
          pages.push(
            <li className="page-item" key={i}>
              <a
                className={classes}
                href="#"
                onClick={(event) => this.loadPage(i, event)}
              >
                {i + 1}
              </a>
            </li>
          );
        }
      }

      const next =
        this.state.pageIndex + 1 < numberOfPages ? (
          <li className="page-item">
            <a
              className="page-link"
              href="#"
              onClick={(event) =>
                this.loadPage(this.state.pageIndex + 1, event)
              }
            >
              <i className="fa fa-chevron-right"></i>
            </a>
          </li>
        ) : null;

      pagination = (
        <div className="row">
          <div className="col-12">
            <nav>
              <ul className="pagination">
                {previous}
                {pages}
                {next}
              </ul>
            </nav>
          </div>
        </div>
      );
    }

    return (
      <div className="container list">
        <div className="row">
          <div className="col-12">
            <div className="row list-header">
              <div className="col-12 col-md-6">
                <h3>All specials{this.state.locationLabel}</h3>
                <LocationFilterModal
                  filterChanged={this.filterChanged.bind(this)}
                />
              </div>
              <div className="col-12 col-md-6 search">
                <input
                  type="search"
                  id="search"
                  name="search"
                  value={this.state.search}
                  onChange={LinkState.bind(this)}
                  onKeyDown={this.onSearchKeyDown.bind(this)}
                  placeholder="Search by Name, Description"
                  className="form-control"
                />
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    Sort
                    <span className="d-none d-lg-inline">: {selectedSort}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => this.sortBy("featured")}>
                  Featured
                </Dropdown.Item>
                    {/* <Dropdown.Item onClick={(e) => this.sortBy("alphabetical")}>
                      Alphabetical
                    </Dropdown.Item> */}
                    {localStorage.getItem("userAccess") && (
                  <Dropdown.Item onClick={(e) => this.sortBy("alphabetical")}>
                    Alphabetical
                  </Dropdown.Item>
                )}
                {this.state.AllowLocation == true && (
                <Dropdown.Item onClick={(e) => this.sortBy("distance")}>
                  Distance
                </Dropdown.Item>
                )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <LocationPage
             bars={this.state.specials}
             pageIndex={this.state.pageIndex}
             search={this.state.search}
             filter_state={this.state.filter_state}
             filter_city={this.state.filter_city}
             sort={this.state.sort}
             coords={this.state.coords}
             pageSize={pageSize}
             latitude={this.state.latitude}
             longitude={this.state.longitude}
             onDataChange={this.handleDataChange}
             title={"special"}
             locations = {specialsList}
             pagination={pagination}
            />
          </div>
        </div>
      </div>
    );
  }
}
