import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import HLSPlayer from '../HLSPlayer';

class ViewCameraModal extends Component {
  state = {
    show: false,
  }

  handleClose() {
    this.setState({
      show: false,
    });
  }

  handleShow() {
    this.setState({
      show: true,
    });
  }

  render() {

    return (
      <>
        <Button className="txt-btn" onClick={this.handleShow.bind(this)}>
          View
        </Button>

        <Modal
          show={this.state.show}
          onHide={this.handleClose.bind(this)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.label || 'Camera'}</Modal.Title><br />
          </Modal.Header>
          <Modal.Body>
            <HLSPlayer autoPlay={false} url={this.props.active_live_stream_url} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose.bind(this)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default (ViewCameraModal);