import React from 'react'
import SocialLogin from 'react-social-login'
import { GoogleLoginButton } from "react-social-login-buttons";

 
class GoogleSocialButton extends React.Component {
 
    render() {
        return (
            <GoogleLoginButton
              type="button"
              className="social-login"
              onClick={this.props.triggerLogin}
              {...this.props}>
              { this.props.children }
            </GoogleLoginButton>
        );
    }
}
 
export default SocialLogin(GoogleSocialButton);