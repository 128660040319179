import React, { Component } from "react";
import "./Home.scss";
import MarketingHero from "../components/MarketingHero";
import FeaturedBars from "../views/FeaturedBars";
import Alert from "react-bootstrap/Alert";
import Actions from "../actions/api";
import ButtonLoader from "../components/ButtonLoader";
import { Link } from "react-router-dom";
import Newsletter from "./Newsletter";

export default class Home extends Component {
  state = {
    bars: null,
    processing: true,
    filter_state: "",
    filter_city: "",
    latitude: 0,
    longitude: 0,
    zip_code: localStorage.getItem("zip") || "",
    locationLabel: "Buffalo, NY",

    // initial states for messages
    bvVenueRequested: window.bvVenueRequested || false,
    bvSignedUp: window.bvSignedUp || false,
    bvConfirmFailed: window.bvConfirmFailed || false,
    bvForgot: window.bvForgot || false,
    bvReset: window.bvReset || false,
  };

  zipChanged(zip_code, city, filter_state) {
    this.getBars(
      zip_code,
      null,
      city ? city : "",
      filter_state ? filter_state : ""
    );
  }

  componentDidMount() {
    this.getBars(null, window.bvCoords);

    if (!window.bvCoords) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position && position.coords) {
            window.bvCoords = position.coords;
          }
          this.getBars(null, window.bvCoords);
        },
        (error) => {
          if (error) {
            this.getBars(null, null);
          }
        }
      );
    }
  }

  async getBars(zip_code, coords, filter_city, filter_state) {
    window.bvVenueRequested = false;
    window.bvSignedUp = false;
    window.bvConfirmFailed = false;
    window.bvForgot = false;
    window.bvReset = false;

    const st = {
      processing: true,
      bars: null,
      filter_state: this.state.filter_state,
      filter_city: this.state.filter_city,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };

    if (filter_state) {
      st.filter_state = filter_state;
    } else {
      st.filter_state = "";
    }

    if (filter_city) {
      st.filter_city = filter_city;
    } else {
      st.filter_city = "";
    }

    if (zip_code) {
      st.zip_code = zip_code;
    }

    if (coords) {
      st.latitude = coords.latitude;
      st.longitude = coords.longitude;
    }

    this.setState(st);

    const params = { limit: 9, sort: "featured" };

    if (st.filter_state || st.filter_city) {
      params.filter_state = st.filter_state;
      params.filter_city = st.filter_city;
    } else if (st.latitude || st.longitude) {
      params.latitude = st.latitude;
      params.longitude = st.longitude;
    }

    if (st.zip_code) {
      params.zip_code = st.zip_code;
    }

    const locationParams = {};
    let locationData = {};
    if (params.zip_code || st.filter_state || st.filter_city) {
      locationParams.zip_code = params.zip_code;
      locationParams.state = params.filter_state;
      locationParams.city = params.filter_city;
      locationData.state = params.filter_state;
      locationData.city = params.filter_city;
    } else if (params.latitude) {
      locationParams.latitude = params.latitude;
      locationParams.longitude = params.longitude;
      locationData = await Actions.get("bars/location/", locationParams);
    } else {
      locationData = await Actions.get("bars/location/", null);
    }

    const state =
      locationData && locationData.state ? `, ${locationData.state}` : "";
    const locationLabel =
      locationData && locationData.city
        ? ` near ${locationData.city}${state}`
        : "";

    if (!window.bvCoords && (locationData.city || locationData.state)) {
      params.filter_state = locationData.state;
      params.filter_city = locationData.city;
    }

    Actions.get("bars/", params)
      .then((bars) => {
        this.setState({
          bars: bars.results || [],
          processing: false,
          locationLabel,
        });
      })
      .catch((ex) => {
        this.setState({
          bars: [],
          processing: false,
        });
      });
  }

  render() {
    let processing = this.state.processing;

    let alert = null;

    if (this.state.bvVenueRequested) {
      alert = (
        <Alert variant="success">
          Thanks for requesting to add your venue! We will contact you soon!
        </Alert>
      );
    } else if (this.state.bvSignedUp) {
      alert = (
        <Alert variant="success">
          Thanks for signing up! Please check your email to continue.
        </Alert>
      );
    } else if (this.state.bvConfirmFailed) {
      alert = (
        <Alert variant="danger">
          Email confirmation failed! Please try again.
        </Alert>
      );
    } else if (this.state.bvForgot) {
      alert = (
        <Alert variant="success">
          Please check your email to reset your password!
        </Alert>
      );
    } else if (this.state.bvReset) {
      alert = (
        <Alert variant="success">Password reset! You may now login.</Alert>
      );
    }

    const bottom = processing ? (
      <ButtonLoader />
    ) : (
      <FeaturedBars
        bars={this.state.bars}
        locationLabel={this.state.locationLabel}
        zipChanged={this.zipChanged.bind(this)}
      />
    );
    const newsletter = <Newsletter />;
    return (
      <div>
        {alert}
        <MarketingHero />
        <div>{bottom}</div>
        {/* {newsletter} */}
      </div>
    );
  }
}
