import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import './Admin.scss';
import { Link } from "react-router-dom";
import AdminChart from '../components/AdminChart/AdminChart';
import AdminSidebar from '../components/AdminSidebar';
import { LinkContainer } from 'react-router-bootstrap';
import User from '../img/user.jpg';
import { Redirect } from "react-router-dom";
import Sticky from 'react-stickynode';
import AcceptVenueRequestModal from '../components/modals/AcceptVenueRequestModal';
import DeleteVenueRequestModal from '../components/modals/DeleteVenueRequestModal';

import FullPageLoader from '../components/FullPageLoader';
import Actions from '../actions/api';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../helpers/link-state');

// todo: this page should be super admin only
export default class AdminVenueRequests extends Component {
  state = {
    requests: null,
    redirect: '',
    error: '',
    processing: true,
  }

  componentDidMount() {
    this.getVenueRequests();
  }

  async getVenueRequests() {
    this.setState({
      processing: true,
    });
    try {
      const requests = await Actions.get(`bars/venue_owner_requests/`, {
        limit: 5000,
      });

      this.setState({
        requests: requests && requests.results ? requests.results : [],
        processing: false,
      });
    } catch (ex) {
      this.setState({
        redirect: '/',
      });
    }   
  }

  async doAcceptRequest(barRequest, existingBarId, handleClose) {
    try {

      let barId;
      if (!existingBarId) {
        // add the bar
        const newBar = await Actions.post(`bars/admin/bars/`, {
          name: barRequest.business_name,
          phone_number: barRequest.phone_number || null,
          street_address: barRequest.business_address || null,
          country: barRequest.business_country || null,
          locality: barRequest.business_city || null,
          region: barRequest.business_region || null,
          postal_code: barRequest.business_postal_code || null,
          email_address: null,
          description: null,
        });

        barId = newBar.id;
      } else {
        barId = existingBarId;
      }

      // add the user
      if (barRequest.user) {
        await Actions.post(`bars/admin/${barId}/users/`, {
          owner: true,
          email: barRequest.user.email,
          full_name: barRequest.user.full_name,
        });
      } else {
        await Actions.post(`bars/admin/${barId}/users/`, {
          owner: true,
          email: barRequest.email,
          full_name: barRequest.full_name,
        });
      }

      // mark request as done
      await Actions.patch(`bars/venue_owner_requests/${barRequest.id}/`, {
        completed: true,
      });
      const requests = this.state.requests;
      const foundRequests = requests.filter(u => u.id === barRequest.id);

      if (foundRequests) {
        foundRequests[0].completed = true;
      }

      this.setState({
        requests,
      });
      handleClose();
    } catch (ex) {
      handleClose('Failed to accept request. Please try again.');
    }
  }

  async doRejectRequest(id, handleClose) {
    try {
      await Actions.patch(`bars/venue_owner_requests/${id}/`, {
        completed: true,
      });
      const requests = this.state.requests.filter(u => u.id !== id);

      this.setState({
        requests,
      });
      handleClose();
    } catch (ex) {
      handleClose('Failed to reject request. Please try again.');
    }
  }

  render () {
    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader/>;
    }

    const authorizedUser = window.bvUser;

    const { 
      requests,
      error,
    } = this.state;

    let alert = null;

    if (error) {
      alert = (
        <Alert variant="danger">
          {error}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    let requestsTable = null;

    if (requests && requests.length) {

      const requestRows = requests.map(u => {

        const fullName = (u.user ? u.user.full_name : u.full_name) || '';
        const userContact = u.user ? (
          <a href={`mailto:${u.user.email}`}>Existing User: {u.user.email}</a>
        ) : (
          <a href={`mailto:${u.email}`}>New User: {u.email}</a>
        );

        return (
          <tr key={u.id}>
            <td>
              {fullName}
            </td>
            <td className="description">
              {userContact}
            </td>
            <td>
              { u.phone_number}
            </td>
            <td>
              { u.business_name}
            </td>
            <td>
              { u.business_address}<br/>
              { u.business_city}, { u.business_region} { u.business_postal_code}<br/>
              { u.business_country }
            </td>
            <td>
              { u.completed ? 'Completed' : 'Pending' }
            </td>
            <td className="text-right">
              <ul className="action-buttons">
                <li>
                  { !u.completed ? (<AcceptVenueRequestModal user={u} doAcceptRequest={this.doAcceptRequest.bind(this)} />) : null }
                </li>
                <li>
                  { !u.completed ? (<DeleteVenueRequestModal user={u} doRejectRequest={this.doRejectRequest.bind(this)} />) : null }
                </li>
              </ul>
            </td>
          </tr>
        );
      });

      requestsTable = (
        <table className="table bv-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email Address</th>
              <th>Phone Number</th>
              <th>Business Name</th>
              <th>Business Address</th>
              <th>Status</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {requestRows}
          </tbody>
        </table>
      );
    } else {
      requestsTable = (
        <div className="col-12 col-md-6">
          <p>No venue requests yet!</p>
        </div>
      );
    }

    return (
      <div className="admin container-fluid">
        {alert}
        <div className="admin-sidebar">
          <div className="d-none d-lg-block">
            <Sticky enabled={true} top={100}>
              <AdminSidebar />
            </Sticky>
          </div>
          <div className="d-lg-none">
            <AdminSidebar />
          </div>
        </div>
        <div className="admin-content">
          <div className="row admin-header">
            <div className="col-12 col-md-5">
              <h2>Venue Requests</h2>             
            </div>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-12">
              {requestsTable}
            </div>
          </div>
        </div>
      </div>
    )
  }
}