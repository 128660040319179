import React, { Component } from 'react';
import './Covid.scss';
import { Link } from "react-router-dom";
import Card from '../components/Card';
import Pdf1 from '../documents/General-Information.pdf';
import Pdf2 from '../documents/General-Rules.pdf';
import Pdf3 from '../documents/Maintain-Safe-Workplace.pdf';
import Pdf4 from '../documents/Opening-Procedures.pdf';
import Pdf5 from '../documents/Phase-1-Takeout-Curbside.pdf';
import Pdf6 from '../documents/Phase-2-Operations-Procedures.pdf';


export default class Privacy extends Component {
  state = {
  }


  render () {
    return (
      <div className="covid container">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <h2 class="mb-4">COVID-19 Resource Center</h2>
            <div class="card no-hover">
              <p>We know COVID-19 has created difficulties and hardship for everyone including those in the food and beverage industry.  BarView is working together with local bars and restaurants to help them get back on their feet again.  We are receiving updated information from the BDC on a regular basis and working with other officials regarding new health and safety protocols.</p>

              <p class="mb-4">Review the documnetation below for the latest information on how to operate your business safely, not only for your employees but for your customers as well.  For venue owners, we can assist in the process of effective communications with your team and customers, which we believe will drive your success.</p>

              <table class="table">
                <tbody>
                  <ul>
                    <li>
                      <a href = {Pdf1} target = "_blank">
                        <span>Gereral Information</span>
                        <i class="fa fa-download"></i>
                      </a>
                    </li>
                    <li>
                      <a href = {Pdf2} target = "_blank">
                        <span>General-Rules</span>
                        <i class="fa fa-download"></i>
                      </a>
                    </li>
                    <li>
                      <a href = {Pdf3} target = "_blank">
                        <span>Maintaining a Safe Workplace</span>
                        <i class="fa fa-download"></i>
                      </a>
                    </li>
                    <li>
                      <a href = {Pdf4} target = "_blank">
                        <span>Opening Procedures</span>
                        <i class="fa fa-download"></i>
                      </a>
                    </li>
                    <li>
                      <a href = {Pdf5} target = "_blank">
                        <span>Phase 1 - Takeout &amp; Curbside</span>
                        <i class="fa fa-download"></i>
                      </a>
                    </li>
                    <li>
                      <a href = {Pdf6} target = "_blank">
                        <span>Phase 2 - Operations &amp; Procedures</span>
                        <i class="fa fa-download"></i>
                      </a>
                    </li>
                  </ul>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}