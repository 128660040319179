import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ButtonLoader from '../ButtonLoader';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../../helpers/link-state');

let errorTimeout;
class EditUserModal extends Component {
  state = {
    show: false,
    name: '',
    userType: 'user',
  }

  handleClose() {
    this.setState({
      show: false,
      name: '',
      userType: 'user',

      error: '',
      processing: false,
    });
  }

  handleShow() {
    this.setState({
      show: true,
      name: this.props.user.full_name,
      userType: this.props.user.owner ? 'owner' : 'user',

      error: '',
      processing: false,
    });
  }

  doSaveUser() {

    if (!this.state.name) {
      return this.setState({
        error: 'Please set a name',
      });
    }

    this.setState({
      processing: true,
    });

    this.props.doSaveUser(this.props.user.id, this.state.name, this.state.userType === 'owner', error => {
      if (error) {
        this.setState({
          processing: false,
          error,
        });
      } else {
        this.handleClose();
      }
    });
  }

  render() {

    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }


    const buttonText = this.state.processing ? (
      <ButtonLoader />
    ) : (
      <span>Save Changes</span>
    );

    return (
      <>
        <Button className="txt-btn" onClick={this.handleShow.bind(this)}>
          Edit
        </Button>

        <Modal
          show={this.state.show}
          onHide={this.handleClose.bind(this)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alert}
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="userName">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={this.state.name}
                  onChange={LinkState.bind(this)}
                  className="form-control"
                  placeholder="Name..." />
              </div>

              <div className="form-group">
                <label htmlFor="userType">User Type:</label>
                <div className="bv-select inline">
                  <select 
                    id="userType"
                    name="userType"
                    value={this.state.userType}
                    onChange={LinkState.bind(this)}
                    className="custom-select">
                    <option value="user">User</option>
                    <option value="owner">Owner</option>
                  </select>
                  <i className="fa fa-caret-down"></i>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="ghost" onClick={this.handleClose.bind(this)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.doSaveUser.bind(this)}>
              {buttonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default (EditUserModal);