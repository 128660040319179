import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ButtonLoader from '../ButtonLoader';

class UserListModal extends Component {
  state = {
    show: false,
    loading: false,
  }

  handleClose() {
    this.setState({
      show: false,
      loading: false,
    });
  }

  handleShow() {
    this.setState({
      show: true,
      loading: true,
    });
    this.props.goToPage(0);
  }

  componentDidUpdate() {
    if (this.state.loading && this.props.users) {
      this.setState({
        loading: false,
      });
    }
  }

  render() {

    let usersTable = null;

    if (this.state.loading) {
      usersTable = (
        <div className="col-12">
         <ButtonLoader/>
        </div>
      );
    } else if (this.props.users && this.props.users.length) {

      const userRows = this.props.users.map(u => {
        
        const user = u && u.user ? u.user : (u || {});

        const userLink = `/user/${user.id}`;

        return (
          <tr key={user.id}>
            <td>
              {user.full_name || 'Anonymous'}
            </td>
            <td>
              <a href={`mailto:${user.email}`}>{user.email}</a>
            </td>
            <td className="text-right">
              <a href={userLink}>View Profile</a>
            </td>
          </tr>
        );
      });

      usersTable = (
        <table className="table bv-table mt-4">
          <thead>
            <tr>
              <th>
                Name:
              </th>
              <th>
                Email Address
              </th>
              <th className="text-right">
                Profile
              </th>
            </tr>
          </thead>
          <tbody>
            {userRows}
          </tbody>
        </table>
      );
    } else {
      usersTable = (
        <div className="col-12 col-md-6">
          <p>No users found</p>
        </div>
      );
    }

    let pagination = null;

    const { numberOfPages } = this.props;

    if (this.props.numberOfPages > 1) {
        
      const previous = this.props.pageIndex > 0 ? (
        <li className="page-item">
          <a className="page-link" href="#" onClick={(event) => this.props.goToPage(this.props.pageIndex - 1, event)}>
            <i className="fa fa-chevron-left"></i>
          </a>
        </li>
      ) : null;

      let pages = [];
      var hasSkipped = false;
      const curPageDelta = 2;
      for (let i = 0; i < numberOfPages; i++) {
        const isCurrentPage = i === this.state.pageIndex;
        const isCloseToCurrentPage = i > this.state.pageIndex - curPageDelta && i < this.state.pageIndex + curPageDelta;
        const classes = isCurrentPage ? 'page-link active' : 'page-link';


        // if we have many pages, skip the middle few ...
        if (!isCurrentPage && !isCloseToCurrentPage && numberOfPages > 5 && i > curPageDelta && i < numberOfPages - curPageDelta) {
          if (!hasSkipped) {
            pages.push(
              <li className="page-item gap" key={i}>
                ...
              </li>
            );
          }
          hasSkipped = true;
          continue;
        } else {
          if (this.state.pageIndex === i) {
            // allow another gap to show up
            hasSkipped = false;
          }
          pages.push(
            <li className="page-item" key={i}>
              <a className={classes} href="#" onClick={(event) => this.props.goToPage(i, event)}>
                {i+1}
              </a>
            </li>
          );
        }
      }

      const next = (this.props.pageIndex + 1) < this.props.numberOfPages ? (
        <li className="page-item">
          <a className="page-link" href="#" onClick={(event) => this.props.goToPage(this.props.pageIndex + 1, event)}>
            <i className="fa fa-chevron-right"></i>
          </a>
        </li>
      ) : null;

      pagination = (
        <nav>
          <ul className="pagination">
            {previous}
            {pages}
            {next}
          </ul>
        </nav>
      );
    }

    return (
      <>
        <span className="user-count-button" onClick={this.handleShow.bind(this)}>
          {this.props.total}
        </span>

        <Modal
          show={this.state.show}
          onHide={this.handleClose.bind(this)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12">
              {/*<div className="row bv-table-header">
                <div className="col-12 search-area">
                  <div className="search form-group">
                    <input type="search" className="form-control" placeholder="Search"></input>
                    <i class="fa fa-search"></i>
                  </div>
                  <div className="export">
                    <button type="button" className="btn">
                      <i class="fa fa-download"></i>
                      Export
                    </button>
                  </div>
                </div>
              </div>*/}
              {usersTable}
              {pagination}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose.bind(this)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default (UserListModal);
