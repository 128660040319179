import React, { useContext, useEffect } from "react";
import GoogleMap from "../components/GoogleMap";
import { MapToggleContext } from "../components/MapButton";
// import {MapToggleContext} from '../components/MapButton'

function LocationPage({
  bars,
  pageIndex,
  search,
  filter_state,
  filter_city,
  sort,
  coords,
  pageSize,
  latitude,
  longitude,
  onDataChange,
  title,
  locations,
  pagination,
}) {
    let {isOpen,setIsOpen}  = useContext(MapToggleContext);
  return (
    <>
      {isOpen ? (
        <div className="row">
          <GoogleMap
            bars={bars}
            pageIndex={pageIndex}
            search={search}
            filter_state={filter_state}
            filter_city={filter_city}
            sort={sort}
            coords={coords}
            pageSize={pageSize}
            latitude={latitude}
            longitude={longitude}
            onDataChange={onDataChange}
            title={title}
          />
        </div>
      ) : (
        <>
          {title == "special" ? (
            <>
              <div className="row">
                <div className="col-12">
                  <ul className="specials-list">{locations}</ul>
                </div>
              </div>
              {bars?.length > 0 ? pagination : ""}
            </>
          ):(
            <>
              <div className="row">{locations}</div>
              {bars?.length > 0 ? pagination : ""}
            </>
          )}
        </>
      )}
    </>
  );
}

export default LocationPage;
