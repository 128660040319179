import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import './Admin.scss';
import { Link } from "react-router-dom";
import AdminChart from '../components/AdminChart/AdminChart';
import AdminSidebar from '../components/AdminSidebar';
import { LinkContainer } from 'react-router-bootstrap';
import User from '../img/user.jpg';
import { Redirect } from "react-router-dom";
import Sticky from 'react-stickynode';
import EditSpecialModal from '../components/modals/EditSpecialModal';
import AddSpecialModal from '../components/modals/AddSpecialModal';
import DeleteSpecialModal from '../components/modals/DeleteSpecialModal';
import UserListModal from '../components/modals/UserListModal';

import FullPageLoader from '../components/FullPageLoader';
import Actions from '../actions/api';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../helpers/link-state');
const parseQueryStringToDictionary = require('../helpers/parse-query');

export default class AdminSpecials extends Component {
  state = {
    selectedBar: window.bvUser.admin_bar_id ? window.bvUser.admin_bar_id : window.bvUser && window.bvUser.bar_memberships && window.bvUser.bar_memberships.length ? window.bvUser.bar_memberships[0].bar.id : '',
    showSlugBar: false,
    userNeedsToPay: !window.bvUser || !window.bvUser.super_admin,

    redirect: '',
    error: '',
    bar: null,
    specials: null,
    likedUsers: null,
    dislikedUsers: null,
    processing: true,

    userLikesPageIndex: 0,
    userLikesNumberOfPages: 0,
    usersWhoLiked: null,

    userDisLikesPageIndex: 0,
    userDisLikesNumberOfPages: 0,
    usersWhoDisLiked: null,
  }

  componentDidMount() {
    const query = parseQueryStringToDictionary(document.location.search);

    if (query.slug) {
      this.getBar(query.slug);
    } else if (this.state.selectedBar) {
      this.getBar();
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedBar !== prevState.selectedBar) {
      window.bvUser.admin_bar_id = this.state.selectedBar;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', this.state.selectedBar);
      }
      this.getBar();
    }
  }

  async goToLikesPage(id, pageIndex, event) {
    const barId = this.state.selectedBar;
    const pageSizeForUsersTable = 10;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const usersResponse = await Actions.get(`bars/admin/specials/${id}/ratings/`, {
      like: 1,
      offset: pageIndex * pageSizeForUsersTable,
      limit: pageSizeForUsersTable,
    });

    
    const usersWhoLiked = usersResponse && usersResponse.results && usersResponse.results.length ? usersResponse.results : [];
    const userLikesNumberOfPages = Math.ceil(usersResponse.count / pageSizeForUsersTable);

    this.setState({
      userLikesPageIndex: pageIndex,
      usersWhoLiked,
      userLikesNumberOfPages,
    });
  }

  async goToDislikesPage(id, pageIndex, event) {
    const barId = this.state.selectedBar;
    const pageSizeForUsersTable = 10;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const usersResponse = await Actions.get(`bars/admin/specials/${id}/ratings/`, {
      like: 0,
      offset: pageIndex * pageSizeForUsersTable,
      limit: pageSizeForUsersTable,
    });

    const usersWhoDisLiked = usersResponse && usersResponse.results && usersResponse.results.length ? usersResponse.results : [];
    const userDisLikesNumberOfPages = Math.ceil(usersResponse.count / pageSizeForUsersTable);

    this.setState({
      userDisLikesPageIndex: pageIndex,
      usersWhoDisLiked,
      userDisLikesNumberOfPages,
    });
  }

  async getBar(slug) {
    this.setState({
      processing: true,
    });

    let barId = this.state.selectedBar;
    let bar;
    let showSlugBar = false;

    if (window.bvUser.super_admin && window.bvUser.admin_bar_id) {
      showSlugBar = true;
      barId = window.bvUser.admin_bar_id;
    }
    
    if (slug) {
      bar = await Actions.get(`bars/slug/${slug}/`);
      barId = bar.id;
      showSlugBar = true;

      window.bvUser.admin_bar_id = barId;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', barId);
      }
    }

    if (barId) {
      try {
        const bar = await Actions.get(`bars/${barId}/`);
        const specials = await Actions.get(`bars/specials/`, {
          bar: barId,
        });

        let userNeedsToPay = false;

        if (!window.bvUser.super_admin) {
          const billingInfo = await Actions.get(`bars/admin/bars/${barId}/billing/`);
          userNeedsToPay = !billingInfo || !billingInfo.subscription;
        }

        this.setState({
          bar,
          showSlugBar,
          selectedBar: barId,
          userNeedsToPay,
          specials: specials && specials.results ? specials.results : [],
          processing: false,
        });
      } catch (ex) {
        this.setState({
          redirect: '/',
        });
      }
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }

  async doAddSpecial(name, description, fileBlob, handleClose) {
    if (this.state.userNeedsToPay) {
      return;
    }

    const barId = this.state.selectedBar;
    try {

      let signedS3Meta;
      if (fileBlob) {
        signedS3Meta = await Actions.get(`bars/${barId}/signed_upload_urls/`, {
          amount: 1,
        });
        
        await Actions.saveFile(signedS3Meta[0].signed_url, fileBlob);
      }

      const data = {
        name,
        description,
        bar_id: barId,
      };

      if (signedS3Meta) {
        data.s3_bucket = signedS3Meta[0].s3_bucket;
        data.s3_path = signedS3Meta[0].s3_path;
      }

      const newSpecial = await Actions.post(`bars/admin/specials/`, data);

      if (newSpecial) {
        const specials = this.state.specials;
        specials.push(newSpecial);

        this.setState({
          specials,
        });
      }
      handleClose();
    } catch (ex) {
      console.log(ex);
      handleClose('Failed to add special. Please try again.');
    }
  }

  async doSaveSpecial(id, name, description, fileBlob, handleClose) {
    if (this.state.userNeedsToPay) {
      return;
    }

    const barId = this.state.selectedBar;
    try {
      const data = {
        name,
        description,
      };

      if (fileBlob) {
        const signedS3Meta = await Actions.get(`bars/${barId}/signed_upload_urls/`, {
          amount: 1,
        });
        
        await Actions.saveFile(signedS3Meta[0].signed_url, fileBlob);

        data.s3_bucket = signedS3Meta[0].s3_bucket;
        data.s3_path = signedS3Meta[0].s3_path;
      }

      const savedSpecial = await Actions.patch(`bars/admin/specials/${id}/`, data);

      if (savedSpecial) {
        const specials = this.state.specials;
        const foundSpecials = specials.filter(u => u.id === id);

        if (foundSpecials.length) {
          foundSpecials[0].name = savedSpecial.name;
          foundSpecials[0].description = savedSpecial.description;
          foundSpecials[0].url = savedSpecial.url;
        }

        this.setState({
          specials,
        });
      }
      handleClose();
    } catch (ex) {
      handleClose('Failed to save special. Please try again.');
    }
  }

  async doDeleteSpecial(id, handleClose) {
    if (this.state.userNeedsToPay) {
      return;
    }

    try {
      await Actions.delete(`bars/admin/specials/${id}/`);
      const specials = this.state.specials.filter(u => u.id !== id);

      this.setState({
        specials,
      });
      handleClose();
    } catch (ex) {
      handleClose('Failed to remove special. Please try again.');
    }
  }

  render () {

    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader/>;
    }

    const authorizedUser = window.bvUser;

    const numberOfBars = authorizedUser.bar_memberships.length;

    const { 
      bar,
      specials,
      error,
      userNeedsToPay,
      
      userLikesPageIndex,
      usersWhoLiked,
      userLikesNumberOfPages,

      userDisLikesPageIndex,
      usersWhoDisLiked,
      userDisLikesNumberOfPages,

      showSlugBar,
    } = this.state;

    const barOptions = showSlugBar ? (<option key={bar.id} value={bar.id}>{bar.name}</option>) : (numberOfBars > 1 ? authorizedUser.bar_memberships.map(membership => {
      return (
        <option key={membership.bar.id} value={membership.bar.id}>{membership.bar.name}</option>
      );
    }) : null);

    const billingLink = `/admin/billing?slug=${bar.slug}`;
    const payAlert = userNeedsToPay ? (
      <Alert variant="info billing-alert">
        To start your free trial, <a href={billingLink}>click here to enter your billing information</a>. You will be unable to edit venue information until you do.
      </Alert>
    ) : null;

    const barSelection = numberOfBars > 1 || showSlugBar ? (
      <div className="form-group venue-select">
        <div className="bv-select">
          <select
            id="selectedBar"
            name="selectedBar"
            value={this.state.selectedBar}
            onChange={LinkState.bind(this)}
            className="custom-select">
            {barOptions}
          </select>
          <i className="fa fa-caret-down"></i>
        </div>
      </div>
    ) : null;

    let alert = null;

    if (error) {
      alert = (
        <Alert variant="danger">
          {error}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    const ratingScore = bar.rating || 0;
    const rating = (
      <span className="rating">
        <i className={ratingScore > 0 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 1 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 2 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 3 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 4 ? 'fa fa-star' : 'fa fa-star-o'}></i>
      </span>
    );

    let specialsTable = null;

    if (specials && specials.length) {

      const specialsRows = specials.map(s => {

        return (
          <tr key={s.id}>
            <td>
              {s.name}
            </td>
            <td class="description">
              {s.description}
            </td>
            <td>
              <UserListModal
                title="Likes"
                total={s.likes}
                users={usersWhoLiked}
                goToPage={(pageIndex, event) => this.goToLikesPage(s.id, pageIndex, event)}
                numberOfPages={userLikesNumberOfPages}
                pageIndex={userLikesPageIndex} />
            </td>
            <td>
              <UserListModal
                title="Dislikes"
                total={s.dislikes}
                users={usersWhoDisLiked}
                goToPage={(pageIndex, event) => this.goToDislikesPage(s.id, pageIndex, event)}
                numberOfPages={userDisLikesNumberOfPages}
                pageIndex={userDisLikesPageIndex} />
            </td>
            <td className="text-right">
              <ul className="action-buttons">
                <li>
                  { userNeedsToPay ? null : <EditSpecialModal special={s} doSaveSpecial={this.doSaveSpecial.bind(this)} /> }
                </li>
                <li>
                  { userNeedsToPay ? null : <DeleteSpecialModal special={s} doDeleteSpecial={this.doDeleteSpecial.bind(this)} /> }
                </li>
              </ul>
            </td>
          </tr>
        );
      });

      specialsTable = (
        <table className="table bv-table">
          <thead>
            <tr>
              <th>Special Name</th>
              <th>Special Description</th>
              <th>Likes</th>
              <th>Dislikes</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {specialsRows}
          </tbody>
        </table>
      );
    } else {
      specialsTable = (
        <div className="col-12 col-md-6">
          <p>This bar does not have any specials listed.</p>
        </div>
      );
    }

    return (
      <div className="admin container-fluid">
        {payAlert}
        {alert}
        <div className="admin-sidebar">
          <div className="d-none d-lg-block">
            <Sticky enabled={true} top={100}>
              <AdminSidebar />
            </Sticky>
          </div>
          <div className="d-lg-none">
            <AdminSidebar />
          </div>
        </div>
        <div className="admin-content">
          <div className="row admin-header">
            <div className="col-12 col-md-5">
              <h2>Specials</h2>
              <p>
                <span className="venue">{bar.name}</span>
                {rating}
              </p>
            </div>
            <div className="col-12 col-md-7 text-right">
              {barSelection}

              <div className="admin-add-button">
                { userNeedsToPay ? null : <AddSpecialModal doAddSpecial={this.doAddSpecial.bind(this)}/> }
              </div>
            </div>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-12">
              {specialsTable}
            </div>
          </div>
        </div>
      </div>
    )
  }
}