import React, { Component } from 'react';
import './Contact.scss';
import { Link } from "react-router-dom";
import Card from '../components/Card';
import Alert from 'react-bootstrap/Alert';
import ButtonLoader from '../components/ButtonLoader';
import { Redirect } from "react-router-dom";
import Actions from '../actions/api';
const LinkState = require('../helpers/link-state');

let errorTimeout;
export default class Contact extends Component {
  state = {
    name: '',
    email: '',
    message: '',

    processing: false,
    alert: '',
    error: '',
  };

  async doContact(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.state.email) {
      return this.setState({
        error: 'Please enter your email',
      });
    }

    if (!this.state.name) {
      return this.setState({
        error: 'Please enter your name',
      });
    }

    if (!this.state.message) {
      return this.setState({
        error: 'Please enter your message',
      });
    }

    this.setState({
      processing: true,
      error: '',
    });

    Actions.post('users/contact_us/', {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    }).then(response => {
      this.setState({
        name: '',
        email: '',
        message: '',
        alert: 'Message Sent!',
        error: '',
        processing: false,
      });
    }).catch(error => {
      this.setState({
        error,
      });
    })
    return false;
  }


  render () {

    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    } else if (this.state.alert) {
      alert = (
        <Alert variant="success">
          {this.state.alert}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          alert: '',
        });
      }, 3e3);
    }

    const buttonText = this.state.processing ? (
      <ButtonLoader/>
    ) : (
      <span>Send Message</span>
    );

    return (
      <div className="contact container">
        {alert}
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <h2>Contact Us</h2>
            <div className="card no-hover">
              <form>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    name="name"
                    value={this.state.name}
                    onChange={LinkState.bind(this)}
                    placeholder="Name"></input>
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    className="form-control"
                    type="text"
                    id="email"
                    name="email"
                    value={this.state.email}
                    onChange={LinkState.bind(this)}
                    placeholder="Email Address"></input>
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    value={this.state.message}
                    onChange={LinkState.bind(this)}
                    placeholder="Message"></textarea>
                </div>
                <button
                  type="button"
                  onClick={this.doContact.bind(this)}
                  className="btn btn-primary btn-large">
                  {buttonText}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}