import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import Card from '../components/Card';
import ButtonLoader from '../components/ButtonLoader';
import Actions from '../actions/api';
import { Redirect } from "react-router-dom";
const LinkState = require('../helpers/link-state');

let errorTimeout;
export default class Confirm extends Component {
  state = {
    password: '',
    passwordConfirm: '',
    redirect: '',
    processing: false,
    error: '',
  }

// todo: this is not being called
  // componentDidMount() {
  //   const token = this.props.match.params.id;

  //   if (!token) {
  //     window.location.href = '/';
  //   }
  // }

  async doResetPassword(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.state.password) {
      return this.setState({
        error: 'Please enter your password',
      });
    }

    if (this.state.password !== this.state.passwordConfirm) {
      return this.setState({
        error: 'Please make sure your passwords match',
      });
    }

    const validPassword = this.state.password.length > 6 && this.state.password.match(/[a-z]/) && this.state.password.match(/[A-Z]/) && this.state.password.match(/d/);


    if (!validPassword) {
      return this.setState({
        error: 'Password must be at least 6 characters with at least one uppercase and one number',
      });
    }

    this.setState({
      processing: true,
    });

    const token = this.props.match.params.id;
    Actions.post('users/reset_password/', {
      token,
      password: this.state.password,
    }).then(response => {
      localStorage.setItem('user', response);
      window.bvReset = true;
      this.setState({
        redirect: '/',
      });
    }).catch(error => {
      this.setState({
        processing: false,
        error,
      });
    });
  }

  render () {
    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    let buttonText = (<span>Reset Password</span>)

    if (this.state.processing) {
      buttonText = (<ButtonLoader />);
    }

    return (
      <div className="register container">
        {alert}
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <Card>
              <div className="header__logo">
                <Link to="/">
                  <div className="brandmark">
                    <i className="fa fa-glass"></i>
                  </div>
                  BarView
                </Link>
              </div>
              <p>Enter your new password to continue!</p>
              <form onSubmit={this.doResetPassword.bind(this)} method="post">
                <input
                  className="form-control"
                  type="password"
                  id="password"
                  name="password"
                  value={this.state.password}
                  onChange={LinkState.bind(this)}
                  placeholder="Password"
                />

                <input
                  className="form-control"
                  type="password"
                  id="passwordConfirm"
                  name="passwordConfirm"
                  value={this.state.passwordConfirm}
                  onChange={LinkState.bind(this)}
                  placeholder="Confirm Password"
                />

                <button
                  type="submit"
                  className="btn btn--primary btn--large mt-4"
                >
                  {buttonText}
                </button>
              </form>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}