import React, { Component } from "react";
import Nav from "./Nav";
import { withRouter } from "react-router-dom";
import { Link, location } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import Actions from "../actions/api";
import "./Header.scss";
import MapToggleButton from "./MapButton";
// import User from '../img/user.jpg';
import Notifications from "../views/Notifications";
import Logo from "../img/logo2.png";
import NotificationLogo from "../img/notification.png";
import WorldMapToggleButton from "./WorldMapToggleButton";

var softDismissTimeout = null;
var handleSoftDismiss = null;
class Header extends Component {
  constructor(props) {
    super(props);

    handleSoftDismiss = this.handleSoftDismiss.bind(this);
  }

  state = {
    isTop: true,
    showNotifications: false,
    unreadCount:
      window.bvUser && window.bvUser.unread_count
        ? window.bvUser.unread_count
        : 0,
  };

  componentWillMount() {}

  componentWilUnmount() {}

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 1;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }

  showNotifications() {
    setTimeout(() => {
      this.setState({
        showNotifications: true,
      });

      document.addEventListener("mousedown", handleSoftDismiss, false);
    }, 300);
  }

  closeNotifications() {
    setTimeout(() => {
      this.setState({
        showNotifications: false,
      });
    }, 200);
  }

  clickedNotifications() {
    if (softDismissTimeout) {
      clearTimeout(softDismissTimeout);
      softDismissTimeout = null;
    }
  }

  handleSoftDismiss(evt) {
    if (
      !evt ||
      (evt.srcElement &&
        evt.srcElement.className.indexOf("notification") === -1)
    ) {
      const that = this;
      softDismissTimeout = setTimeout(() => {
        that.setState({
          showNotifications: false,
        });
        document.removeEventListener("mousedown", handleSoftDismiss, false);
      }, 150);
    } else {
      console.log("clicked inside notifications box");
    }
  }
  
  render() {
    const authorizedUser = window.bvUser;

    let authUserName = "";
    let userImage = "";

    if (authorizedUser) {
      authUserName = authorizedUser.full_name || authorizedUser.email || "";
      userImage = authorizedUser.logo || "";
    }

    // todo: default logo or initials ??

    const logo = userImage ? (
      <img className="img-fluid" src={userImage} alt="Username" />
    ) : null;

    const unreadCount = this.state.unreadCount ? (
      <span className="count">
        {this.state.unreadCount > 9 ? "9+" : this.state.unreadCount}
      </span>
    ) : null;

    const adminLink =
      authorizedUser &&
      (authorizedUser.super_admin ||
        (authorizedUser.bar_memberships &&
          authorizedUser.bar_memberships.length)) ? (
        <li>
          <Link to="/admin">Manage Venues</Link>
        </li>
      ) : null;

    const loggedIn = !!window.bvUser;

    const headerActions = loggedIn ? (
      <Link to="/notifications" className="notifications-mobile-link">
        <i className="fa fa-globe"></i>
        {unreadCount}
      </Link>
    ) : (
      <ul className="header-buttons">
        <li>
          <WorldMapToggleButton />
        </li>
        <li>
          <LinkContainer to="/login">
            <Button variant="link">Log In</Button>
          </LinkContainer>
        </li>
        <li>
          <LinkContainer to="/register">
            <Button variant="primary">Sign Up</Button>
          </LinkContainer>
        </li>
      </ul>
    );

    const notifications = this.state.showNotifications ? (
      <div
        onClick={this.clickedNotifications.bind(this)}
        className="dropdown-menu notification-dropdown"
      >
        <Notifications />
      </div>
    ) : null;

    return (
      <div className={!!window.bvUser ? "authorized" : ""}>
        <header
          className={
            "header-wrapper header-wrapper--" +
            this.props.location.pathname.replace(/\//g, "")
          }
        >
          <div className={`header ${this.state.isTop ? "" : "active"}`}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-5 col-sm-7 col-lg-8 logo-container">
                  <div className="header__logo">
                    <Link to="/">
                      <img src={Logo} alt="BarView" />
                    </Link>
                  </div>
                  <nav className="header__nav d-none d-lg-inline-block">
                    <Nav />
                  </nav>
                </div>
                <div className="col-7 col-sm-5 col-lg-4 text-right action-buttons">
                  {headerActions}
                </div>
                <div className="col-12 col-sm-5 col-lg-4 d-none d-md-block text-right user-menu">
                  <ul className="user-menu" d-none>
                    <li className="notification-button">
                      <div className="notification-icon">
                        <i
                          onClick={this.showNotifications.bind(this)}
                          onMouseEnter={this.showNotifications.bind(this)}
                          onMouseOut={this.closeNotifications.bind(this)}
                          class="fa fa-bell fa-lg"
                          style={{ fontSize: "27px" }}
                          aria-hidden="true"
                        ></i>
                        {/* <img style={{width:"30px"}} src={NotificationLogo} alt="NotificationLogo"/> */}
                        {unreadCount}
                        {notifications}
                      </div>
                    </li>
                    <li>
                      <WorldMapToggleButton />
                    </li>
                    <li className="user-button">
                      {logo}
                      <span>
                        {authUserName} <i className="fa fa-caret-down"></i>
                      </span>
                      <div className="dropdown-menu user-dropdown">
                        <ul>
                          <li>
                            <Link to="/account">My Account</Link>
                          </li>
                          {adminLink}
                          <li>
                            <Link to="/logout">Log Out</Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-3 col-sm-5 d-md-none text-right"></div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default withRouter(Header);
