import React, { createContext, useContext, useState } from "react";

export const MapToggleContext = createContext();

export default function MapTogalProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <MapToggleContext.Provider value={{ isOpen, setIsOpen }}>
      { children }
    </MapToggleContext.Provider>
  );
}
