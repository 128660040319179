import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Footer.scss';
import Logo from '../img/logo2.png';

export default class Footer extends Component {

  render () {
    const loggedIn = !!window.bvUser;


    const accountOptions = loggedIn ? (
      <ul>
        <li>
          <Link to="/account">
            My Account
          </Link>
        </li>
        <li>
          <Link to="/logout">
            Log Out
          </Link>
        </li>
        <li>
          <Link to="/register-venue">
            Register a Venue
          </Link>
        </li>
      </ul>
    ) : (
      <ul>
        <li>
          <Link to="/register">
            Sign Up
          </Link>
        </li>
        <li>
          <Link to="/login">
            Log In
          </Link>
        </li>
        <li>
          <Link to="/register-venue">
            Register a Venue
          </Link>
        </li>
      </ul>
    );

    const favoriteOptions = loggedIn ? (
      <Link to="/favorites">
        <i className="fa fa-star"></i>
        <span>Favorites</span>
      </Link>
    ) : (
      <Link to="/login">
        <i className="fa fa-star"></i>
        <span>Favorites</span>
      </Link>
    );
    
      return (
        <div>
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-2 d-none d-lg-inline">
                  <div className="logo">
                    <Link to="/">
                      <img className="img-fluid" src={Logo} alt="BarView"/>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                  <label>BarView</label>
                  <ul>
                    <li>
                      <Link to="/venues">
                        All Venues
                      </Link>
                    </li>
                    <li>
                      <Link to="/events">
                        Events
                      </Link>
                    </li>
                    <li>
                      <Link to="/specials">
                        Specials
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                  <label>Account</label>
                  {accountOptions}
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                  <label>About</label>
                  <ul>
                    <li>
                      <Link to="/about">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy">
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                  <label>Resources</label>
                  <ul>
                    <li>
                      <Link to="/covid">
                        Covid-19 Materials
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 col-lg-4 social">
                  <ul>
                    <li>
                      <a href={'https://www.facebook.com/BarViewApp'}>
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={'https://twitter.com/barview_app'}>
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={'https://www.instagram.com/barview.app/'}>
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>

          <div className="footer-nav">
            <ul>
              <li>
                <Link to="/venues">
                  <i className="fa fa-list"></i>
                  <span>Venues</span>
                </Link>
              </li>
              <li>
                <Link to="/events">
                  <i className="fa fa-album-collection"></i>
                  <span>Events</span>
                </Link>
              </li>
              <li>
                <Link to="/specials">
                  <i className="fa fa-glass"></i>
                  <span>Specials</span>
                </Link>
              </li>
              <li>
                {favoriteOptions}
              </li>
              <li>
                <Link to="/account">
                  <i className="fa fa-user"></i>
                  <span>Account</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )
   }
}