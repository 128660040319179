import React, { Component } from 'react';
import './Privacy.scss';
import { Link } from "react-router-dom";
import Card from '../components/Card';

export default class PaymentSuccess extends Component {
  state = {
  }


  render () {
    return (
      <div className="privacy container">
        <div className="row">
          <div className="col-12">
            <h2>Payment Succeeded!</h2>
            <div className="card no-hover">
              <p>
                Thanks for signing up!
              </p>
              <Link to={'/admin/venue'}>
                Back to Manage Venues
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}