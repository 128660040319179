import React, { useEffect, useState, useContext } from "react";
import GoogleMapReact from "google-map-react";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import Actions from "../actions/api";
import { debounce } from "lodash";
import { MapToggleContext } from "../components/MapButton";

import "./GoogleMap.scss";

const MarkerComponent = ({ key, text, setShowModal, index }) => {
  return (
    <div
      className="marker-icon grow"
      onClick={() => {
        setShowModal(index);
      }}
    >
      <i className="fa fa-map-marker" />
    </div>
  );
};

var currentPage = 1;
var barsCollection = [];

export default function GoogleMap({
  bars,
  pageIndex,
  search,
  filter_state,
  filter_city,
  sort,
  pageSize,
  onDataChange,
  title
}) {
  let {isOpen,setIsOpen}  = useContext(MapToggleContext);
  const [zoom, setZoom] = useState(11);
  const [centerPoint, setCenterPoint] = useState(null);
  const [showModal, setShowModal] = useState();
  const [details, setDetails] = useState({});

  const [currentBars, setCurrentBars] = useState([]);
  const [pageNumber, setPageNumber] = useState(-1)

  useEffect(() => {
    let locations = []
    currentPage = 1;
    if(bars && bars.length > 0){
      const coords = {
        lat: bars[0].location?.latitude,
        lng: bars[0].location?.longitude,
      }
      setCenterPoint(coords);
      if (title == "venues") {
        setCurrentBars(bars);
      } else if (title == "special") {
        bars.map((items)=>{
          if(items?.bar){
            locations.push({...items.bar,description: items?.description})
          }
        })
        setCurrentBars(locations)
      }  else if(title == "favorites"){
        setCurrentBars(bars);
      } else if(title == "events"){
        bars.map((items)=>{
          if(items?.bar){
            locations.push({...items.bar,description: items?.description})
          }
        })
        setCurrentBars(locations)
      }else{
        setCurrentBars([])
      }
    }else{
      setIsOpen(false)
    }
  }, [bars]);

  useEffect(() => {
    setDetails(currentBars[showModal]);
  }, [showModal]);

    useEffect(()=>{
if(pageNumber > 0){
  getBars(
    search,
    filter_state,
    filter_city,
  )
}
    },[pageNumber])

  const getBars = async (
    search,
    filter_state,
    filter_city,
    // sort,
    coords
    // pageSize
  ) => {
    const st = {
      sort: sort,
      search: search,
      filter_state: filter_state,
      filter_city: filter_city,
      // latitude: coords.latitude,
      // longitude: coords.longitude,
    };

    if (search) {
      st.search = search;
    }

    if (filter_state) {
      st.filter_state = filter_state;
    } else {
      st.filter_state = "";
    }

    if (filter_city) {
      st.filter_city = filter_city;
    } else {
      st.filter_city = "";
    }

    // if (coords) {
    //   st.latitude = coords.latitude;
    //   st.longitude = coords.longitude;
    // }

    if (sort) {
      st.sort = sort;
    }

    const params = {
      offset: pageNumber * pageSize,
      limit: pageSize,
    };

    if (st.filter_state || st.filter_city) {
      params.filter_state = st.filter_state;
      params.filter_city = st.filter_city;
    }

    // if (st.latitude || st.longitude) {
    //   params.latitude = st.latitude;
    //   params.longitude = st.longitude;
    // }

    if (st.search) {
      params.search = st.search;
    }

    if (st.sort) {
      params.sort = st.sort;
    }

    const locationParams = {};
    let locationData = {};

    if (st.filter_state || st.filter_city) {
      locationParams.state = params.filter_state;
      locationParams.city = params.filter_city;
      locationData.state = params.filter_state;
      locationData.city = params.filter_city;
    } else if (coords) {
      locationParams.latitude = coords.latitude;
      locationParams.longitude = coords.longitude;
      locationData = await Actions.get("bars/location/", locationParams);
    } else {
      locationData = await Actions.get("bars/location/", null);
    }

    const state =
      locationData && locationData.state ? ` ${locationData.state}` : "";
    const locationLabel =
      ` near ` +
      (locationData && locationData.city
        ? ` ${locationData.city}, ${state}`
        : `${state} State`);

    await Actions.get("bars/", params)
      .then((results) => {
        const barsResult = results.results || [];
        const numberOfPages = Math.ceil(results.count / pageSize);

        setCurrentBars([...currentBars,...barsResult])

        onDataChange({
          processing: false,
          locationLabel,
        });
      })
      .catch((ex) => {
        console.log(ex, "ex1");
      });
  };

  return (
    <div className="map-container">
      {centerPoint ? (
        <>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            }}
            defaultCenter={centerPoint}
            defaultZoom={zoom}
            onChange={({ zoom, bounds }) => {
              const getBarsNextPageDeb = setTimeout(()=>{
                setPageNumber(pageNumber+1)
                  }, 500);
              return () => clearTimeout(getBarsNextPageDeb)
            }}
            onGoogleApiLoaded={(map) => {}}
          >
            {currentBars &&
              currentBars.length > 0 &&
              currentBars.map(({ location, name, rating }, index) => {
                return location ? (
                  <MarkerComponent
                    key={`marker-${index}`}
                    lat={location.latitude}
                    lng={location.longitude}
                    text={name}
                    index={index}
                    setShowModal={setShowModal}
                  />
                ) : null;
              })}
          </GoogleMapReact>
          {details && details.name && (
            <div className="modal-inner">
              <div
                className="close-btn"
                onClick={() => {
                  setDetails({});
                }}
              >
                X
              </div>
              <div style={{ width: "100%" }}>
                <Link to={"/venue/" + details.slug}>
                  <img
                    className="venue-img"
                    src={details?.cover_photo_url}
                    alt=""
                  />
                </Link>
              </div>
              <div className="inner-card" style={{ marginTop: "10px" }}>
                <div style={{ width: "30%" }}>
                  <p>Name:</p>
                </div>
                <div style={{ width: "70%", whiteSpace: "nowrap" }}>
                  <Link to={"/venue/" + details.slug}>
                    <p className="white-color">
                      {details?.name ? details?.name : "N/A"}
                    </p>
                  </Link>
                </div>
              </div>
              <div className="inner-card">
                <div style={{ width: "30%" }}>
                  <p>Description:</p>
                </div>
                <div style={{ width: "70%", whiteSpace: "nowrap" }}>
                  <p>{details?.description ? details?.description : "N/A"}</p>
                </div>
              </div>
              <div className="inner-card">
                <div style={{ width: "30%" }}>
                  <p>Cuisine List:</p>
                </div>
                <div style={{ width: "70%", whiteSpace: "nowrap" }}>
                  <p>{details?.cuisine ? details?.cuisine : "N/A"}</p>
                </div>
              </div>
              <div className="inner-card">
                <div style={{ width: "30%" }}>
                  <p>Online Order Available:</p>
                </div>
                <div style={{ width: "70%", whiteSpace: "nowrap" }}>
                  <p>{details?.online_ordering_url ? "Yes" : "No"}</p>
                </div>
              </div>
              <div className="inner-card">
                <div style={{ width: "30%" }}>
                  <p>Website:</p>
                </div>
                <div style={{ width: "70%", whiteSpace: "nowrap" }}>
                  <p>
                    {details?.online_ordering_url
                      ? details?.online_ordering_url
                      : "N/A"}
                  </p>
                </div>
              </div>
              <div className="inner-card">
                <p style={{ width: "30%" }}>Rating:</p>
                <div style={{ width: "70%", whiteSpace: "nowrap" }}>
                  <span className="rating">
                    <i
                      className={
                        details?.rating > 0 ? "fa fa-star" : "fa fa-star-o"
                      }
                    ></i>
                    <i
                      className={
                        details?.rating > 1 ? "fa fa-star" : "fa fa-star-o"
                      }
                    ></i>
                    <i
                      className={
                        details?.rating > 2 ? "fa fa-star" : "fa fa-star-o"
                      }
                    ></i>
                    <i
                      className={
                        details?.rating > 3 ? "fa fa-star" : "fa fa-star-o"
                      }
                    ></i>
                    <i
                      className={
                        details?.rating > 4 ? "fa fa-star" : "fa fa-star-o"
                      }
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="map-loader">
          <ReactLoading type="spin" color="#8439dd" height={50} width={50} />
        </div>
      )}
    </div>
  );
}
