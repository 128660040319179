import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ButtonLoader from '../ButtonLoader';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../../helpers/link-state');

let errorTimeout;
class AddSpecialModal extends Component {
  state = {
    show: false,
    name: '',
    description: '',
    
    error: '',
    file: '',
    fileBlob: null,
    processing: false,
  }

  handleClose() {
    this.setState({
      show: false,
      name: '',
      description: '',
      
      error: '',
      file: '',
      fileBlob: null,
      processing: false,
    });
  }

  handleShow() {
    this.setState({
      show: true,
    });
  }

  doAddSpecial() {

    if (!this.state.name) {
      return this.setState({
        error: 'Please set a name',
      });
    }
    
    // if (!this.state.fileBlob) {
    //   return this.setState({
    //     error: 'Please add a photo',
    //   });
    // }

    this.setState({
      processing: true,
    });
    this.props.doAddSpecial(this.state.name, this.state.description, this.state.fileBlob, error => {
      if (error) {
        this.setState({
          processing: false,
          error,
        });
      } else {
        this.handleClose();
      }
    });
  }

  onSetFile(evt) {
    if (evt.target.files.length) {
      this.setState({
        fileBlob: evt.target.files[0],
        file: evt.currentTarget.value,
      });
    } else {
      this.setState({
        fileBlob: null,
        file: '',
      });
    }
  }

  render() {

    let alert = null;

    if (this.state.error) {

      if (errorTimeout) {
        clearTimeout(errorTimeout);
      }

      alert = (
        <Alert variant="danger">
          {this.state.error}
        </Alert>
      );
      errorTimeout = setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }


    const buttonText = this.state.processing ? (
      <ButtonLoader />
    ) : (
      <span>Save</span>
    );

    return (
      <>
        <button className="btn btn-primary" onClick={this.handleShow.bind(this)}>
          <i className="fa fa-plus"></i>
          Add New
        </button>

        <Modal
          show={this.state.show}
          onHide={this.handleClose.bind(this)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Special</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alert}
            <div className="col-12">
              <div className="form-group">
                <label for="name">Special Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={this.state.name}
                  onChange={LinkState.bind(this)}
                  className="form-control"
                  placeholder="Special Name..." />
              </div>
              <div className="form-group mt-3">
                <label for="description">Special Description:</label>
                <textarea 
                  id="description"
                  name="description"
                  value={this.state.description}
                  onChange={LinkState.bind(this)}
                  className="form-control height-250"
                  placeholder="Enter a description..."></textarea>
              </div>
              <h4>Photo</h4>
              <div className="form-group">
                <input
                  type="file"
                  id="file"
                  name="file"
                  value={this.state.file}
                  onChange={this.onSetFile.bind(this)}
                  className="form-control" />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="ghost" onClick={this.handleClose.bind(this)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.doAddSpecial.bind(this)}>
              {buttonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default (AddSpecialModal);
