import React, { Component } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import './Admin.scss';

import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Actions from '../actions/api';
import Alert from 'react-bootstrap/Alert';
import FullPageLoader from '../components/FullPageLoader';
import AdminChart from '../components/AdminChart/AdminChart';
import AdminSidebar from '../components/AdminSidebar';
import { LinkContainer } from 'react-router-bootstrap';
import User from '../img/user.jpg';
import Sticky from 'react-stickynode';
const LinkState = require('../helpers/link-state');
const parseQueryStringToDictionary = require('../helpers/parse-query');

export default class Admin extends Component {
  state = {
    selectedBar: window.bvUser.admin_bar_id ? window.bvUser.admin_bar_id : window.bvUser && window.bvUser.bar_memberships && window.bvUser.bar_memberships.length ? window.bvUser.bar_memberships[0].bar.id : '',
    showSlugBar: false,
    userNeedsToPay: !window.bvUser || !window.bvUser.super_admin,

    redirect: '',
    error: '',
    bar: null,
    
    pageIndex: 0,
    numberOfPages: 0,
    usersWhoLiked: null,
    barPopularity: null,
    barLifetimePopularity: null,

    focusedInput: null,
    startDate: null,
    endDate: null,
    showChart: true, // set to false for a tick to re-load chart on data change

    processing: true,
  };

  componentDidMount() {

    if (!window.bvUser || (!window.bvUser.super_admin && (!window.bvUser.bar_memberships || !window.bvUser.bar_memberships.length))) {
      window.location.href = '/';
      return;
    }

    const query = parseQueryStringToDictionary(document.location.search);

    if (query.slug) {
      this.getBar(query.slug);
    } else if (this.state.selectedBar) {
      this.getBar();
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedBar !== prevState.selectedBar) {
      window.bvUser.admin_bar_id = this.state.selectedBar;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', this.state.selectedBar);
      }
      this.getBar();
    }
  }

  async getBar(slug) {
    this.setState({
      processing: true,
    });

    let barId = this.state.selectedBar;
    let bar
    let showSlugBar = false;

    if (window.bvUser.super_admin && window.bvUser.admin_bar_id) {
      showSlugBar = true;
      barId = window.bvUser.admin_bar_id;
    }
    
    if (slug) {
      bar = await Actions.get(`bars/slug/${slug}/`);
      barId = bar.id;
      showSlugBar = true;

      window.bvUser.admin_bar_id = barId;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', barId);
      }
    }

    if (barId) {
      try {
        const bar = await Actions.get(`bars/${barId}/`);
        const barPopularity = await Actions.get(`bars/${barId}/popularity/`);
        const barLifetimePopularity = await Actions.get(`bars/${barId}/lifetime_popularity/`);
        await this.refreshUsersTable(0);

        let userNeedsToPay = false;

        if (!window.bvUser.super_admin) {
          const billingInfo = await Actions.get(`bars/admin/bars/${barId}/billing/`);
          userNeedsToPay = !billingInfo || !billingInfo.subscription;
        }

        this.setState({
          bar,
          showSlugBar,
          selectedBar: barId,
          barPopularity,
          barLifetimePopularity,
          userNeedsToPay,
          processing: false,
        });
      } catch (ex) {
        this.setState({
          redirect: '/',
        });
      }
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }

  async refreshUsersTable(pageIndex, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const pageSizeForUsersTable = 50;
    const barId = this.state.selectedBar;

    const usersResponse = await Actions.get(`bars/${barId}/ratings/`, {
      like: 1,
      offset: pageIndex * pageSizeForUsersTable,
      limit: pageSizeForUsersTable,
    });

    const usersWhoLiked = usersResponse && usersResponse.results && usersResponse.results.length ? usersResponse.results.map(u => u.user) : [];
    const numberOfPages = Math.ceil(usersResponse.count / pageSizeForUsersTable);

    this.setState({
      usersWhoLiked,
      numberOfPages,
      pageIndex,
    });
  }

  async dateChanged({ startDate, endDate }) {
    this.setState({ 
      startDate, 
      endDate,
      showChart: false,
    });

    const barId = this.state.selectedBar;
    const start_date = startDate ? startDate.toISOString().split('T')[0] : '';
    const end_date = endDate ? endDate.toISOString().split('T')[0] : '';
    
    const props = {};
    
    if (start_date) {
      props.start_date = start_date;
    }

    if (end_date) {
      props.end_date = end_date;
    }

    const barPopularity = await Actions.get(`bars/${barId}/popularity/`, props);

    this.setState({
      barPopularity,
      showChart: true,
    });
  }

  render () {
    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader/>;
    }

    const authorizedUser = window.bvUser;

    const numberOfBars = authorizedUser.bar_memberships.length;

    const { 
      bar, 
      error, 
      showChart, 
      usersWhoLiked, 
      numberOfPages,
      showSlugBar,
      userNeedsToPay,
    } = this.state;

    const barOptions = showSlugBar ? (<option key={bar.id} value={bar.id}>{bar.name}</option>) : (numberOfBars > 1 ? authorizedUser.bar_memberships.map(membership => {
      return (
        <option key={membership.bar.id} value={membership.bar.id}>{membership.bar.name}</option>
      );
    }) : null);

    const billingLink = `/admin/billing?slug=${bar.slug}`;
    const payAlert = userNeedsToPay ? (
      <Alert variant="info billing-alert">
        To start your free trial, <a href={billingLink}>click here to enter your billing information</a>. You will be unable to edit venue information until you do.
      </Alert>
    ) : null;

    const dataPoints = this.state.barPopularity.data_points || [];
    const { likes, dislikes, pageviews } = this.state.barLifetimePopularity;

    const barSelection = numberOfBars > 1 || showSlugBar ? (
      <div className="form-group venue-select">
        <div className="bv-select">
          <select
            id="selectedBar"
            name="selectedBar"
            value={this.state.selectedBar}
            onChange={LinkState.bind(this)}
            className="custom-select">
            {barOptions}
          </select>
          <i className="fa fa-caret-down"></i>
        </div>
      </div>
    ) : null;

    let alert = null;

    if (error) {
      alert = (
        <Alert variant="danger">
          {error}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    const ratingScore = bar.rating || 0;
    const rating = (
      <span className="rating">
        <i className={ratingScore > 0 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 1 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 2 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 3 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 4 ? 'fa fa-star' : 'fa fa-star-o'}></i>
      </span>
    );

    const chart = showChart ? (<AdminChart dataPoints={dataPoints} />) : (<FullPageLoader/>);

    let usersTable = null;

    if (usersWhoLiked && usersWhoLiked.length) {

      const userRows = usersWhoLiked.map(u => {
        const userLink = `/user/${u.id}`;

        return (
          <tr key={u.id}>
            <td>
              {u.full_name || 'Anonymous'}
            </td>
            <td>
              <a href={`mailto:${u.email}`}>{u.email}</a>
            </td>
            <td className="text-right">
              <a href={userLink}>View Profile</a>
            </td>
          </tr>
        );
      });

      usersTable = (
        <table className="table bv-table mt-4">
          <thead>
            <tr>
              <th>
                Name:
              </th>
              <th>
                Email Address
              </th>
              <th className="text-right">
                Profile
              </th>
            </tr>
          </thead>
          <tbody>
            {userRows}
          </tbody>
        </table>
      );
    } else {
      usersTable = (
        <div className="col-12 col-md-6">
          <p>No users have liked this bar yet.</p>
        </div>
      );
    }

    let pagination = null;

    if (numberOfPages > 1) {
        
      const previous = this.state.pageIndex > 0 ? (
        <li className="page-item">
          <a className="page-link" href="#" onClick={(event) => this.refreshUsersTable(this.state.pageIndex - 1, event)}>
            <i className="fa fa-chevron-left"></i>
          </a>
        </li>
      ) : null;

      let pages = [];
      var hasSkipped = false;
      const curPageDelta = 2;
      for (let i = 0; i < numberOfPages; i++) {
        const isCurrentPage = i === this.state.pageIndex;
        const isCloseToCurrentPage = i > this.state.pageIndex - curPageDelta && i < this.state.pageIndex + curPageDelta;
        const classes = isCurrentPage ? 'page-link active' : 'page-link';


        // if we have many pages, skip the middle few ...
        if (!isCurrentPage && !isCloseToCurrentPage && numberOfPages > 5 && i > curPageDelta && i < numberOfPages - curPageDelta) {
          if (!hasSkipped) {
            pages.push(
              <li className="page-item gap" key={i}>
                ...
              </li>
            );
          }
          hasSkipped = true;
          continue;
        } else {
          if (this.state.pageIndex === i) {
            // allow another gap to show up
            hasSkipped = false;
          }
          pages.push(
            <li className="page-item" key={i}>
              <a className={classes} href="#" onClick={(event) => this.refreshUsersTable(i, event)}>
                {i+1}
              </a>
            </li>
          );
        }
      }

      const next = (this.state.pageIndex + 1) < numberOfPages ? (
        <li className="page-item">
          <a className="page-link" href="#" onClick={(event) => this.refreshUsersTable(this.state.pageIndex + 1, event)}>
            <i className="fa fa-chevron-right"></i>
          </a>
        </li>
      ) : null;

      pagination = (
        <nav>
          <ul className="pagination">
            {previous}
            {pages}
            {next}
          </ul>
        </nav>
      );
    }
    

    return (
      <div className="admin container-fluid">
        {payAlert}
        {alert}
        <div className="admin-sidebar">
          <div className="d-none d-lg-block">
            <Sticky enabled={true} top={100}>
              <AdminSidebar />
            </Sticky>
          </div>
          <div className="d-lg-none">
            <AdminSidebar />
          </div>
        </div>
        <div className="admin-content">
          <div className="row admin-header">
            <div className="col-12 col-md-5">
              <h2>Dashboard</h2>
              <p>
                <span className="venue">{bar.name}</span>
                {rating}
              </p>
            </div>
            <div className="col-12 col-md-7 text-right">
              {barSelection}

              <div className="form-group date-select">
                <label className="form-label sr-only">Venue</label>
                <DateRangePicker
                  startDate={this.state.startDate}
                  startDateId="your_unique_start_date_id"
                  endDate={this.state.endDate}
                  endDateId="your_unique_end_date_id"
                  onDatesChange={this.dateChanged.bind(this)}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={focusedInput => this.setState({ focusedInput })}
                  showDefaultInputIcon={true}
                  isOutsideRange={(dt) => dt > moment()}
                  initialVisibleMonth={() => moment().subtract(1, "M")}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="card no-hover chart-card">
                {chart}
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card no-hover stat-card">
                <label>Lifetime Pageviews</label>
                <span className="purple">{pageviews}</span>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card no-hover stat-card">
                <label>Lifetime Likes</label>
                <span className="green">{likes}</span>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="card no-hover stat-card">
                <label>Lifetime Dislikes</label>
                <span className="red">{dislikes}</span>
              </div>
            </div>
            <div className="col-12">
              <div className="row bv-table-header">
                <div className="col-12 col-md-6">
                  <h2 className="mt-5">Likes</h2>
                </div>
                {/*<div className="col-12 col-md-6 search-area">
                  <div className="search form-group">
                    <input type="search" className="form-control" placeholder="Search"></input>
                    <i class="fa fa-search"></i>
                  </div>
                  <div className="export">
                    <button type="button" className="btn">
                      <i class="fa fa-download"></i>
                      Export
                    </button>
                  </div>
                </div>*/}
              </div>

              {usersTable}
              
              {pagination}
            </div>
          </div>
        </div>
      </div>
    )
  }
}