import React,{useContext} from 'react';
import { NavLink } from 'react-router-dom';
import { MapToggleContext } from './MapButton'

const routes = [
  { to: '/venues', label: 'Venues' },
  { to: '/events', label: 'Events' },
  { to: '/specials', label: 'Specials' },
];

const userAccess = localStorage.getItem('userAccess');

if (userAccess) {
  routes.push({ to: '/favorites', label: 'Favorites' });
}

const Nav = () => {
  const {isOpen, setIsOpen} = useContext(MapToggleContext)
  const links = routes.map(({ to, label }) => {
    return <NavLink strict exact to={to} key={to} onClick={()=>{setIsOpen(false)}}>{label}</NavLink>}
  )

  return <nav>{ links }</nav>;
}

export default Nav