import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import './Admin.scss';
import { Link } from "react-router-dom";
import AdminChart from '../components/AdminChart/AdminChart';
import AdminSidebar from '../components/AdminSidebar';
import { LinkContainer } from 'react-router-bootstrap';
import User from '../img/user.jpg';
import { Redirect } from "react-router-dom";
import Sticky from 'react-stickynode';
import ViewCameraModal from '../components/modals/ViewCameraModal';
import EditCameraModal from '../components/modals/EditCameraModal';

import FullPageLoader from '../components/FullPageLoader';
import Actions from '../actions/api';
import Alert from 'react-bootstrap/Alert';
const LinkState = require('../helpers/link-state');
const parseQueryStringToDictionary = require('../helpers/parse-query');

export default class AdminCameras extends Component {
  state = {
    selectedBar: window.bvUser.admin_bar_id ? window.bvUser.admin_bar_id : window.bvUser && window.bvUser.bar_memberships && window.bvUser.bar_memberships.length ? window.bvUser.bar_memberships[0].bar.id : '',
    showSlugBar: false,
    userNeedsToPay: !window.bvUser || !window.bvUser.super_admin,

    cameras: null,
    redirect: '',
    error: '',
    bar: null,
    processing: true,
  }

  componentDidMount() {
    const query = parseQueryStringToDictionary(document.location.search);

    if (query.slug) {
      this.getBar(query.slug);
    } else if (this.state.selectedBar) {
      this.getBar();
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedBar !== prevState.selectedBar) {
      window.bvUser.admin_bar_id = this.state.selectedBar;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', this.state.selectedBar);
      }
      this.getBar();
    }
  }

  async getBar(slug) {
    this.setState({
      processing: true,
    });

    let barId = this.state.selectedBar;
    let bar;
    let showSlugBar = false;

    if (window.bvUser.super_admin && window.bvUser.admin_bar_id) {
      showSlugBar = true;
      barId = window.bvUser.admin_bar_id;
    }
    
    if (slug) {
      bar = await Actions.get(`bars/slug/${slug}/`);
      barId = bar.id;
      showSlugBar = true;

      window.bvUser.admin_bar_id = barId;
      if (localStorage) {
        localStorage.setItem('admin_bar_id', barId);
      }
    }

    if (barId) {
      try {
        const bar = await Actions.get(`bars/${barId}/`);
        const cameras = await Actions.get(`bars/admin/${barId}/cameras/`, {
          limit: 5000,
        });

        let userNeedsToPay = false;

        if (!window.bvUser.super_admin) {
          const billingInfo = await Actions.get(`bars/admin/bars/${barId}/billing/`);
          userNeedsToPay = !billingInfo || !billingInfo.subscription;
        }

        this.setState({
          bar,
          showSlugBar,
          selectedBar: barId,
          userNeedsToPay,
          cameras: cameras && cameras.results ? cameras.results : [],
          processing: false,
        });
      } catch (ex) {
        this.setState({
          redirect: '/',
        });
      }
    } else {
      this.setState({
        redirect: '/',
      });
    }
  }

  async doSaveCamera(id, label, status, schedule, handleClose) {
    if (this.state.userNeedsToPay) {
      return;
    }

    const barId = this.state.selectedBar;
    try {
      const savedCamera = await Actions.patch(`bars/admin/${barId}/cameras/${id}/`, {
        status,
        label,
        ...schedule,
      });

      if (savedCamera) {
        const cameras = this.state.cameras;
        const cams = cameras.filter(u => u.id === id);

        if (cams.length) {
          cams[0].label = label;
          cams[0].status = status;
          cams[0].schedule = schedule;
        }

        this.setState({
          cameras,
        });
      }
      handleClose();
    } catch (ex) {
      handleClose('Failed to save user. Please try again.');
    }
  }

  render () {

    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let processing = this.state.processing;

    if (processing) {
      return <FullPageLoader/>;
    }

    const authorizedUser = window.bvUser;

    const numberOfBars = authorizedUser.bar_memberships.length;

    const { 
      bar,
      cameras,
      error,
      showSlugBar,
      userNeedsToPay,
    } = this.state;

    const barOptions = showSlugBar ? (<option key={bar.id} value={bar.id}>{bar.name}</option>) : (numberOfBars > 1 ? authorizedUser.bar_memberships.map(membership => {
      return (
        <option key={membership.bar.id} value={membership.bar.id}>{membership.bar.name}</option>
      );
    }) : null);

    const billingLink = `/admin/billing?slug=${bar.slug}`;
    const payAlert = userNeedsToPay ? (
      <Alert variant="info billing-alert">
        To start your free trial, <a href={billingLink}>click here to enter your billing information</a>. You will be unable to edit venue information until you do.
      </Alert>
    ) : null;

    const barSelection = numberOfBars > 1 || showSlugBar ? (
      <div className="form-group venue-select">
        <div className="bv-select">
          <select
            id="selectedBar"
            name="selectedBar"
            value={this.state.selectedBar}
            onChange={LinkState.bind(this)}
            className="custom-select">
            {barOptions}
          </select>
          <i className="fa fa-caret-down"></i>
        </div>
      </div>
    ) : null;

    let alert = null;

    if (error) {
      alert = (
        <Alert variant="danger">
          {error}
        </Alert>
      );
      setTimeout(() => {
        this.setState({
          error: '',
        });
      }, 3e3);
    }

    const ratingScore = bar.rating || 0;
    const rating = (
      <span className="rating">
        <i className={ratingScore > 0 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 1 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 2 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 3 ? 'fa fa-star' : 'fa fa-star-o'}></i>
        <i className={ratingScore > 4 ? 'fa fa-star' : 'fa fa-star-o'}></i>
      </span>
    );

    let camerasTable = null;

    if (cameras && cameras.length) {

      const cameraRows = cameras.map(c => {
        return (
          <tr key={c.id}>
            <td>
              {c.serial_number}
            </td>
            <td>
              {c.label}
            </td>
            <td>
              { c.status !== 'off' ? 'Active' : 'Disabled' }
            </td>
            <td>
              { c.live_stream_url ? 'Yes' : 'No' }
            </td>
            <td className="text-right">
              <ul className="action-buttons">
                <li>
                  { userNeedsToPay ? null : <ViewCameraModal label={c.label} active_live_stream_url={c.live_stream_url} /> }
                </li>
                <li>
                  { userNeedsToPay ? null : <EditCameraModal camera={c} doSaveCamera={this.doSaveCamera.bind(this)} /> }
                </li>
              </ul>
            </td>
          </tr>
        );
      });

      camerasTable = (
        <table className="table bv-table">
          <thead>
            <tr>
              <th>Camera Id</th>
              <th>Camera Name</th>
              <th>Status</th>
              <th>Currently Live?</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {cameraRows}
          </tbody>
        </table>
      );
    } else {
      camerasTable = (
        <div className="col-12 col-md-6">
          <p>No cameras have been linked to this bar yet.</p>
        </div>
      );
    }


    return (
      <div className="admin container-fluid">
        {payAlert}
        {alert}
        <div className="admin-sidebar">
          <div className="d-none d-lg-block">
            <Sticky enabled={true} top={100}>
              <AdminSidebar />
            </Sticky>
          </div>
          <div className="d-lg-none">
            <AdminSidebar />
          </div>
        </div>
        <div className="admin-content">
          <div className="row admin-header">
            <div className="col-12 col-md-5">
              <h2>Cameras</h2>
              <p>
                <span className="venue">{bar.name}</span>
                {rating}
              </p>
            </div>
            <div className="col-12 col-md-7 text-right">
              {barSelection}
            </div>
            <div className="col-12">
              <hr />
            </div>
            <div className="col-12">
              {camerasTable}
            </div>
          </div>
        </div>
      </div>
    )
  }
}