import React from 'react';
import { NavLink } from 'react-router-dom';

const routes = [
  { to: '/admin', label: 'Dashboard' },
  { to: '/admin/venue', label: 'Venue Info' },
  { to: '/admin/camera', label: 'Cameras' },
  { to: '/admin/events', label: 'Events' },
  { to: '/admin/specials', label: 'Specials' },
  { to: '/admin/users', label: 'Users' },
  { to: '/admin/billing', label: 'Payments & Billing', },
  { to: '/admin/venues', label: 'Admin - All Venues', admin: true, },
  { to: '/admin/requests', label: 'Admin - Venue Requests', admin: true, },
];

const Nav = () => {

  const isAdmin = window.bvUser.super_admin || false;

  const links = routes.filter(r => !r.admin || isAdmin).map(({ to, label }) => {
    return <NavLink strict exact to={to} key={to}>{label}<i className="fa fa-chevron-right"></i></NavLink>}
  )

  return <nav>{ links }</nav>;
}

export default Nav