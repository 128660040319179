import React, { Component } from 'react';
import './Login.scss';
import { Link } from "react-router-dom";
import Card from '../components/Card';
import ButtonLoader from '../components/ButtonLoader';
import Actions from '../actions/api';
import { Redirect } from "react-router-dom";
import Logo from '../img/logo2.png';

const LinkState = require('../helpers/link-state');

export default class Login extends Component {
  state = {
    email: '',
    redirect: '',
    processing: false,
  }

  // todo: missing text email validation ...

  async doForgot(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      processing: true,
    });

    Actions.post('users/forgot_password/', {
      email: this.state.email,
    }).then(response => {
      window.bvForgot = true;
      this.setState({
        redirect: '/',
      });
    }).catch(error => {
      window.bvForgot = true;
      this.setState({
        redirect: '/',
      });
    })
    return false;
  }


  render () {
    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect} />
      );
    }

    let buttonText = (<span>Reset Password</span>)

    if (this.state.processing) {
      buttonText = (<ButtonLoader />);
    }

    return (
      <div className="login container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <Card>
              <div className="header__logo remove-pad mb-force">
                <Link to="/">
                  <img src={Logo} alt="BarView"/>
                </Link>
              </div>
              <form onSubmit={this.doForgot.bind(this)} method="post">
                <p>Enter your email address below in order to reset your password.</p>
                <input
                  className="form-control"
                  type="text"
                  id="email"
                  name="email"
                  value={this.state.email}
                  onChange={LinkState.bind(this)}
                  placeholder="Email Address"
                />

                <button
                  type="submit"
                  disabled={this.state.processing || !this.state.email.length}
                  className="btn btn--primary btn--large mt-4"
                >
                  {buttonText}
                </button>
              </form>
              <Link
                to="/login"
                className="btn btn-link"
              >
                Return to Login
              </Link>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}